import React from 'react';
import './login.scss'
import logo from '../../img/MetaCampusLogo.png';
import { Navbar, Container, Nav, NavDropdown, Button, Dropdown, Col, Row, Table, Form, Modal } from 'react-bootstrap';
import { InfoSquareFill } from 'react-bootstrap-icons';
import { Link, withRouter } from 'react-router-dom';
import { history } from '../../history';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReCAPTCHA from "react-google-recaptcha";



class Register extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      // showPage: 1
      userAgree: false,
      username: '',
      password: '',
      password2: '',
      email: '',
      fullname: '',
      userType: '4',
      gender: 'Male',
      recaptcha: false,
      legalEntityCode: '',
      userTypeList: [
        "Visitor",
        "Learner",
        "Tutor",
        "Speaker"
      ],
      genderList: [
        "Male",
        "Female"
        // "Other"
      ],
      show: false,
      data: {
        status: false,
        userID: 0,
        userPass: 0
      }
    };
  }

  handleClose = () => {
    this.setState({ show: false })
  }

  handleOpen = () => {
    this.setState({ show: true })
  }

  sendRegisterRequest = () => {
    if (this.state.password.match(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/)) {
      if (this.state.password2 === this.state.password) {
        const data = new FormData();
        data.append("username", this.state.username);
        data.append("pass", this.state.password);
        data.append("email", this.state.email);
        data.append("name", this.state.fullname);
        data.append("EntityCode", this.state.legalEntityCode);
        data.append("gender", this.state.gender == 'Male' ? '1' : '2');
        data.append("occupation", this.state.userType);
        fetch("https://languagevr.io/test/signup.php", {
          method: "POST",
          body: data
        }).then((response) => {
          response.json().then(data => ({
            data: data,
            status: response.status
          })).then((res) => {
            if (res.data.Request === 'Success') {
              toast.success('Register successfull', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                limit: 3,
                progress: undefined,
              });
              this.props.history.push("/login")
            } else {
              toast.error('Error name: ' + res.data.Error, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                limit: 3,
                progress: undefined,
              });
            }
          })
        }).catch((err) => {
          console.log("ERROR")
        });
      } else {
        toast.error('Passwords must be same', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          limit: 3,
          progress: undefined,
        });
      }
    } else {
      toast.warn('Your password must be minimum eight characters, at least one letter and one number.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        limit: 3,
        progress: undefined,
      })
    }
  }

  handleChange = (e) => {
    if (e.target.name == 'userType') {
      let data = ''
      if (e.target.value == 'Tutor') data = '1'
      else if (e.target.value == 'Learner') data = '2'
      else if (e.target.value == 'Speaker') data = '3'
      else if (e.target.value == 'Visitor') data = '4'
      this.setState({ userType: data })
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  }

  sendError = () => {
    if (!this.state.recaptcha) {
      toast.warn('Please check I am not robot box.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        limit: 3,
        progress: undefined,
      })
    } else {
      toast.warn('Please fill in all the input fields.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        limit: 3,
        progress: undefined,
      })
    }
  }

  render(Props) {
    const { username, email, password, password2, legalEntityCode, fullname, userAgree, recaptcha } = this.state;
    const enabled =
      email.length > 0 &&
      password.length > 0 &&
      password2.length > 0 &&
      legalEntityCode.length > 0 &&
      fullname.length > 0 &&
      username.length > 0 &&
      userAgree && recaptcha;
    return (
      <Container>
        <Row className="custom justify-content-md-center align-items-center">
          <Col className="loginBoxMain" xs={11} xl={4}>
            <Row>
              <Col className="align-items-center" xl={12}>
                <Link to="/index">
                  <img style={{ width: '200px', height: '120px', }} src={logo} />
                </Link>
              </Col>
              <Col className="loginText" xl={12}>
                SIGN UP
              </Col>
              <Col xl={12}>
                <Col className="loginInputs" xl={12}>
                  <input type="text" name="legalEntityCode" placeholder="Legal Entity Code" value={this.state.legalEntityCode} onChange={this.handleChange} />
                </Col>
                <Col className="loginInputs" xl={12}>
                  <Form.Control as="select" name="userType" className="dropdownActions" onChange={(e) => this.handleChange(e)} placeholder="chose Lecture type" aria-label="Default select example">
                    {this.state.userTypeList && this.state.userTypeList.map(Element =>
                      <option key={Element} value={Element}>{Element}</option>
                    )}
                  </Form.Control>
                </Col>
                <Col className="loginInputs" xl={12}>
                  <Form.Control as="select" name="gender" className="dropdownActions" onChange={(e) => this.handleChange(e)} placeholder="chose Lecture type" aria-label="Default select example">
                    {this.state.genderList && this.state.genderList.map(Element =>
                      <option key={Element} value={Element}>{Element}</option>
                    )}
                  </Form.Control>
                </Col>
                <Col className="loginInputs" xl={12}>
                  <input type="text" name="fullname" placeholder="Full Name" value={this.state.fullname} onChange={this.handleChange} />
                </Col>
                <Col className="loginInputs" xl={12}>
                  <input type="text" placeholder="E-mail" name="email" value={this.state.email} onChange={this.handleChange} />
                </Col>
                <Col className="loginInputs" xl={12}>
                  <input type="text" name="username" placeholder="Username" value={this.state.username} onChange={this.handleChange} />
                </Col>
                <Col className="loginInputs" xl={12}>
                  <input type="password" placeholder="Password" name="password" value={this.state.password} onChange={this.handleChange} />
                </Col>
                <Col className="loginInputs" xl={12}>
                  <input type="password" placeholder="Re-write Password" name="password2" value={this.state.password2} onChange={this.handleChange} />
                </Col>
                <Col style={{ display: 'flex', justifyContent: 'center' }} xl={12}>
                  <ReCAPTCHA
                    sitekey="6LekidAdAAAAAJHPCIEqvoZqbtkZ0kUuXI_VVi5Y"
                    onChange={() => { this.setState({ recaptcha: true }) }}
                  />
                </Col>
              </Col>
              <Col xl={12}>
                <Row style={{ padding: "10px" }}>
                  <Col className="checkboxDiv" xl={12}>
                    <Form.Check
                      type={"checkbox"}
                      onChange={() => this.setState({ userAgree: !this.state.userAgree })}
                      id={"1"}
                    />
                    <span onClick={this.handleOpen}>I Accept User Agreement</span>
                  </Col>
                </Row>
              </Col>
              <Col xl={12}>
                <Row className="loginSignupButtonDiv" style={{ padding: "20px" }}>
                  <Col className={enabled ? "loginButton" : "loginButton Disabled"} xl={12} onClick={enabled ? this.sendRegisterRequest : this.sendError}>
                    Sign up
                  </Col>
                  <Link to="/login">
                    <Col className="signupButton" xl={12}>
                      Back to login
                    </Col>
                  </Link>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          keyboard={false}
        >
          <Modal.Header>
            <Modal.Title><InfoSquareFill color="#577df7" size={25} style={{ marginRight: "10px" }} />User Agreement</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
            {/* <Button variant="primary">Continue</Button> */}
          </Modal.Footer>
        </Modal>
      </Container>
    );
  }
}
export default withRouter(Register);
