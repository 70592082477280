import React from 'react';
import './questions.scss'
import { Container, Col, Row, Form} from 'react-bootstrap';
import { postActions, postFormHandling } from '../../helpers/request'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Reading extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         data: '',
         numCount: 2,
         Title: '',
         Paragraph: '',
         imageList: [],
         selectedImage: ''
      };
    }

    componentDidMount () {
      this.getImageFiles().then(() => {
         if (this.props.location.state && this.props.location.state.edit) {
            this.getData(this.props.location.state.id)
         }
      })
    }

    getData = (Qid) => {
      let data = {
         Type: "getReading",
         FileID: Qid
      }
      postFormHandling(data).then((res) => {
         if (res.Data.Image) {
            this.setState({selectedImage: res.Data.Image})
         }
         this.setState({Paragraph: res.Data.Paragraph})
         this.setState({Title: res.Data.Name})
      })
   }

    getImageFiles = () =>{
      return new Promise((resolve, rejcet) => {
         let data = {
            Action:"getUserLibraries",
            UserID: localStorage.getItem('userID').replace(/['"]+/g, '')
         }
         postActions(data).then((response) => {
            let fileList = []
            // for (let x = 0; x < response.Libraries.length;x++) {
               let data = {
                  Action:"getLibraryFileList",
                  UserID: localStorage.getItem('userID').replace(/['"]+/g, ''),
                  LibraryID: response.Libraries[0].ID
               }
               postActions(data).then((response2) => {
                  if (response2.Files) {
                     fileList = response2.Files
                     fileList = fileList.filter(item => item.Type == "Image")
                     this.setState({imageList: fileList})
                     resolve()
                  }
                  resolve()
               })
            // }
         })
      })
   }

   selectImage = (e) => {
      this.setState({selectedImage: e.target.value})
   }

    CrateNewReadingRequest = () => {
      let data = {
         Type:"createReading",
         ID: localStorage.getItem('userID').replace(/['"]+/g, ''),
         LibraryID: localStorage.getItem('FList'),
         Form: {
            Title: this.state.Title,
            // ImageID: this.state.selectedImage,
            Paragraph: this.state.Paragraph
         }
      }
      postFormHandling(data).then(() => {
         toast.success('Reading successfuly created', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            limit: 3,
            progress: undefined,
         });
         this.props.history.push("/files")
      })
   }

    handleTitle = (e) => {
      this.setState({Title: e.target.value})
    }

    handleP = (e) => {
       this.setState({Paragraph: e.target.value})
    }

    sendError = () => {
      toast.warn('Please Fill Reading Text', {
         position: "top-right",
         autoClose: 5000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         limit: 3,
         progress: undefined,
      })
   }
   render () {
      const { Paragraph, Title } = this.state
      const valid =  Paragraph && Title
      return (
         <Container>
            <Row className="labelBar justify-content-md-center">
               <Col className="label" xl={12}>
                  <Row style={{width: '100%'}}>
                     <Col xl={3} className="labelMain">Reading</Col>
                     <Col xl={4} className="titleBox">
                        <span className="title">Title:</span>
                        <input type="text" name="name" value={this.state.Title} onChange={this.handleTitle} placeholder="Reading Title"/>
                     </Col>
                     {/* <Col xl={4} className="titleBox">
                        <span className="title">Time:</span>
                        <input type="text" name="name" placeholder="Question Name"/>
                     </Col> */}
                  </Row>
               </Col>
            </Row>
            <Row className="pageRow">
               <Col xl={12}>
                  <div class="settingBox">
                     <Col xl={12} className="questionLabel">
                        <Row>
                           <Col xl={9}>Reading</Col>
                           <Col xl={3}>  
                              <Form.Control as="select" value={this.state.selectedImage} onChange={(e) => this.selectImage(e)} className="selectBox" size="sm">
                                    <option>Select Image</option>
                                    {this.state.imageList && this.state.imageList.map(item => 
                                       <option value={item.ID}>{item.Name}</option>
                                    )}
                              </Form.Control>
                           </Col>
                        </Row>
                     </Col>
                     <Col xl={12} className="questionBox">
                        <Row>
                           <Col className="inputBox textArea" xl={12}><Form.Control name="Paragraph" value={this.state.Paragraph} onChange={(e) => this.handleP(e)} as="textarea" className="textAreaClass" rows={3} /></Col>
                        </Row>
                     </Col>
                     <Col xl={12} className="addQueButton" onClick={valid ? this.CrateNewReadingRequest : this.sendError}>Save Reading</Col>
                  </div>
               </Col>
            </Row>
         </Container>
      )
   }
}

export default Reading;