import React from 'react';
import { Container, Col, Row, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import './createAssignment.scss'
import 'react-toastify/dist/ReactToastify.css';
import { postActions } from '../../helpers/request'
import moment from 'moment'


class CreateNewClass extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         Type: "1",
         Action:"newClass",
         UserID: localStorage.getItem('userID').replace(/['"]+/g, ''),
         ClassName: '',
         Description: '',
         StartDateDate: '2020-12-01',
         StartDateTime: '',
         EndDateDate: '',
         EndDateTime: ''
      };
    }

    createClass = () => {
      let startDate = moment(this.state.StartDateDate + ' ' + this.state.StartDateTime).utc().format('YYYY-MM-DD HH:mm')
      let endDate = moment(this.state.EndDateDate + ' ' + this.state.EndDateTime).utc().format('YYYY-MM-DD HH:mm')
      if (moment(startDate).isSameOrAfter(endDate)) {
         toast.warn('End date can not be earlier than today date', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            limit: 3,
            progress: undefined,
         })
         return ''
      }
      let data = {
         Action: this.state.Action,
         UserID: this.state.UserID,
         ClassName: this.state.ClassName,
         Type: this.state.Type,
         Description: this.state.Description,
         StartDate: startDate,
         EndDate: endDate
      }
      postActions(data).then((response) => {
         toast.success('Class successfuly created', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            limit: 3,
            progress: undefined,
         });
         this.props.history.push("/classes")
      })
    }

    handleChange = (e) => {
      this.setState({[e.target.name]: e.target.value})
      console.log(this.state.StartDateDate)
    }

    handleChangeTime = (e) => {
      let data = e.target.value
    }

   sendError = () => {
      toast.warn('Please fill in all the input fields.', {
         position: "top-right",
         autoClose: 5000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         limit: 3,
         progress: undefined,
      })
   }

   componentDidMount () {
      let MyDate = new Date()
      let today = moment(MyDate).format('YYYY-MM-DD')
      let endMonth = moment(MyDate).add(1, 'month').format('YYYY-MM-DD')
      let todayTime = moment(MyDate).format('HH:mm')
      let endTime = moment(endMonth).format('HH:mm')
      this.setState({StartDateDate: today})
      this.setState({EndDateDate: endMonth})
      this.setState({StartDateTime: todayTime})
      this.setState({EndDateTime: endTime})
   }

   render () {
      const { ClassName, Description, StartDateDate, StartDateTime, EndDateDate, EndDateTime } = this.state
      const valid =
      ClassName.length > 0 &&
         Description.length > 0 &&
         StartDateDate.length > 0 &&
         StartDateTime.length > 0 &&
         EndDateDate.length > 0 &&
         EndDateTime.length > 0;
      return (
         <Container>
            <Col xl={6} className="mainBox">
               <Col xl={12} className="lectureLabel">{localStorage.getItem('type').replace(/['"]+/g, '') !== '3' ? this.props.langs.createClass : this.props.langs.createEvent}</Col>
               <Row className="newAssignmentRow">
                  <Col className="inputLabel" xl={4}>{localStorage.getItem('type').replace(/['"]+/g, '') !== '3' ? this.props.langs.className : this.props.langs.eventName}:</Col>
                  <Col xl={8} className="inputBox noP">
                     <input name="ClassName" onChange={(e) => this.handleChange(e)} type="text" maxlength="50"/>
                  </Col>
               </Row>
               <Row className="newAssignmentRow">
                  <Col className="inputLabel" xl={4}>{this.props.langs.startDate}</Col>
                  <Col className="inputBox" xl={8}>
                     <Row>
                        <Col xl={7} className="noP">
                           <Form.Control className="customDatepicker" name="StartDateDate" type="date" value={this.state.StartDateDate} onChange={(e) => this.handleChange(e)}></Form.Control>
                        </Col>
                        <Col xl={5} className="noP">
                           <Form.Control className="customDatepicker" name="StartDateTime" type="time" value={this.state.StartDateTime} onChange={(e) => this.handleChange(e)}></Form.Control>
                        </Col>
                     </Row>
                  </Col>
               </Row>
               <Row className="newAssignmentRow">
                  <Col className="inputLabel" xl={4}>{this.props.langs.endDate}</Col>
                  <Col className="inputBox" xl={8}>
                     <Row>
                        <Col xl={7} className="noP">
                           <Form.Control className="customDatepicker" name="EndDateDate" type="date" value={this.state.EndDateDate} onChange={(e) => this.handleChange(e)}></Form.Control>
                        </Col>
                        <Col xl={5} className="noP">
                           <Form.Control className="customDatepicker" name="EndDateTime" type="time" value={this.state.EndDateTime} onChange={(e) => this.handleChange(e)}></Form.Control>
                        </Col>
                     </Row>
                  </Col>
               </Row>
               <Row className="newAssignmentRow">
                  <Col className="inputLabel" xl={4}>{this.props.langs.description}</Col>
                  <Col className="inputBox textArea" xl={8}><Form.Control name="Description" onChange={(e) => this.handleChange(e)} as="textarea" className="textAreaClass" rows={3} maxlength="500"/></Col>
               </Row>
               <Col xl={12}>
                  <div class="createButton" onClick={valid ? this.createClass : this.sendError}><span class="buttonText">{this.props.langs.createClass}</span></div>
               </Col>
            </Col>
         </Container>
      )
   }
}

export default CreateNewClass;