import React from 'react';
import userBlankIcon from '../../img/ProfilePH.png';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './adminClasses.scss'
import { Container, Button, Col, Row, Modal, Spinner, Dropdown } from 'react-bootstrap';
import AdminModal from './adminModal';
import { Check, Folder, Plus, PlusSquare, ThreeDotsVertical, InfoSquareFill, Trash, FileText, Check2, Clock, Eye, EyeSlash, CameraVideo, Soundwave, FilePdf, FileSlides, Badge3d, Pencil, HandIndex, PencilSquare } from 'react-bootstrap-icons';
// import CreateNewAssignment from './CreateNewAssignment';
import { Link } from 'react-router-dom';
import { postActions } from '../../helpers/request'

class Classes extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         data: '',
         classesList: '',
         show: false,
         setShow: false,
         showDelete: false,
         isLoading: true,
         userType: null,
         selectedBanner: null,
         showCompleteClassState: false,
         pureDate: []
      };
   }

   handleCloseDelete = () => {
      this.setState({ showDelete: false })
   }

   handleOpenDelete = (item) => {
      this.setState({ readyToDeleteId: item.ID })
      this.setState({ showDelete: true })
   }

   deleteFile = () => {
      let data = {
         Action: "deleteFile",
         UserID: localStorage.getItem('userID').replace(/['"]+/g, ''),
         CurrentLibID: localStorage.getItem('FList'),
         FileID: this.state.readyToDeleteId
      }
      postActions(data).then((response) => {
         this.getLibraryFilelist(this.state.selectedLibrary)
         toast.success('File deleted', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            limit: 3,
            progress: undefined,
         });
         this.handleCloseDelete()
      })
   }

   getClasses = () => {
      let data = {
         Action: "getUserClasses",
         UserID: localStorage.getItem('userID').replace(/['"]+/g, '')
      }
      postActions(data).then((response) => {
         this.setState({ classesList: response.Classes })
         this.setState({ isLoading: false })
      }).catch(() => {
         this.setState({ isLoading: false })
      })
   }

   showCompleteClass = () => {
      this.setState({ showCompleteClassState: !this.state.showCompleteClassState })
   }
   // getUncheckLenght = (item) => {
   //    let data = {
   //       Action:"getUncheckedAssignments",
   //       UserID: localStorage.getItem('userID').replace(/['"]+/g, ''),//User ID
   //       ClassID: item.ID
   //    }
   //    let count = ''
   //    postActions(data).then((response) => {
   //       // count = response.Assignments.length
   //    })
   // }

   componentDidMount() {
      this.getClasses()
      let type = localStorage.getItem('type').replace(/['"]+/g, '')
      if (type) {
         this.setState({ userType: type })
      }
   }

   handleClose = () => {
      this.setState({ show: false })
   }
   handleOpen = () => {
      this.setState({ show: true })
   }

   goAdminFileList = (item) => {
      this.props.history.push({
         pathname: "/adminfiles",
         state: item
      })
   }

   completeClass = (item) => {
      let data = {
         Action: "completeClass",
         UserID: localStorage.getItem('userID').replace(/['"]+/g, ''),
         ClassID: item.ID
      }
      postActions(data).then(() => {
         toast.success('Class completed', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            limit: 3,
            progress: undefined,
         });
      })
   }

   inCompleteClass = (item) => {
      let data = {
         Action: "inCompleteClass",
         UserID: localStorage.getItem('userID').replace(/['"]+/g, ''),
         ClassID: item.ID
      }
      postActions(data).then(() => {
         toast.success('Class completed', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            limit: 3,
            progress: undefined,
         });
      })
   }

   openEdit = (params) => {
      this.setState({ selectedBanner: params })
      this.setState({ show: true })
   }

   deleteClass = () => {
      let data = {
         Action: "deleteClass",
         UserID: localStorage.getItem('userID').replace(/['"]+/g, ''),
         ClassID: this.state.readyToDeleteId
      }
      postActions(data).then(() => {
         this.getClasses()
         this.handleCloseDelete()
         toast.success('Class deleted', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            limit: 3,
            progress: undefined,
         });
      })
   }

   render() {
      return (
         <Container>
            <Row className="labelBar justify-content-md-center">
               <Col className="label" xl={12}>
                  <div class="labelMain">Admin Classes</div>
                  {/* <Link to="/classes/createNewClass">
                     <div class="newButtonMain"><PlusSquare color="#577df7" size={25} /><span class="buttonText">{this.props.langs.new}</span></div>
                  </Link>
                  <div class="newButtonMain" onClick={this.showCompleteClass}>{this.state.showCompleteClassState ? <EyeSlash color="#577df7" size={25} /> :  <Eye color="#577df7" size={25} />}<span class="buttonText">{this.state.showCompleteClassState ? this.props.langs.hideCompleted : this.props.langs.showCompleted}</span></div> */}
               </Col>
            </Row>
            {this.state.isLoading ?
               <div class="animCenter">
                  <Spinner animation="border" role="status">
                     <span className="visually-hidden">Loading...</span>
                  </Spinner>
               </div>
               :
               <Row className="pageRow">
                  <Col xl={4}>
                     <div class="settingBox">
                        <div class="topBox">
                           <div class="topLabel">Theater</div>
                        </div>
                        <div class="tableRow" onClick={() => this.goAdminFileList({ mainName: "Theater", name: "Theater", type: "Video" })}>
                           <div class="icon"><CameraVideo color="#577df7" size={30} /></div>
                           <div class="text">Edit Videos</div>
                        </div>
                        <div class="tableRow" onClick={() => this.goAdminFileList({ mainName: "Theater", name: "Theater", type: "Audio" })}>
                           <div class="icon"><Soundwave color="#577df7" size={30} /></div>
                           <div class="text">Edit Audio</div>
                        </div>
                        <div class="tableRow" onClick={() => this.goAdminFileList({ mainName: "Theater", name: "Theater", type: "PDF" })}>
                           <div class="icon"><FileSlides color="#577df7" size={30} /></div>
                           <div class="text">Edit Slides</div>
                        </div>
                        <div class="tableRow" onClick={() => this.goAdminFileList({ mainName: "Theater", name: "Theater", type: "3Dmodel" })}>
                           <div class="icon"><Badge3d color="#577df7" size={30} /></div>
                           <div class="text">Edit 3D models</div>
                        </div>
                        <div class="tableRow" onClick={() => this.openEdit({ name: "Theater", type: "TheaterExibitionBanners", typeName: "Edit Event Banner" })}>
                           <div class="icon"><PencilSquare color="#577df7" size={30} /></div>
                           <div class="text">Edit Event Banner</div>
                        </div>
                        <div class="tableRow" onClick={() => this.openEdit({ name: "Theater", type: "TheaterIdiomBoards", typeName: "Edit Gallery" })}>
                           <div class="icon"><PencilSquare color="#577df7" size={30} /></div>
                           <div class="text">Edit Event Gallery</div>
                        </div>
                        <div class="tableRow" onClick={() => this.openEdit({ name: "Theater", type: "TheaterPoster", typeName: "Edit Gallery Poster" })}>
                           <div class="icon"><PencilSquare color="#577df7" size={30} /></div>
                           <div class="text">Edit Event Gallery Poster</div>
                        </div>
                        <div class="tableRow" onClick={() => this.openEdit({ name: "Theater", type: "speech_logo_C.glb", typeName: "Theater Logo", ext: "3d" })}>
                           <div class="icon"><Badge3d color="#577df7" size={30} /></div>
                           <div class="text">Edit Theater Logo</div>
                        </div>
                     </div>
                  </Col>
                  <Col xl={4}>
                     <div class="settingBox">
                        <div class="topBox">
                           <div class="topLabel">Buisness</div>
                        </div>
                        <div class="tableRow" onClick={() => this.goAdminFileList({ mainName: "Buisness", name: "BusinessStudio", type: "Video" })}>
                           <div class="icon"><CameraVideo color="#577df7" size={30} /></div>
                           <div class="text">Edit Videos</div>
                        </div>
                        <div class="tableRow" onClick={() => this.goAdminFileList({ mainName: "Buisness", name: "BusinessStudio", type: "Audio" })}>
                           <div class="icon"><Soundwave color="#577df7" size={30} /></div>
                           <div class="text">Edit Audio</div>
                        </div>
                        <div class="tableRow" onClick={() => this.goAdminFileList({ mainName: "Buisness", name: "BusinessStudio", type: "PDF" })}>
                           <div class="icon"><FileSlides color="#577df7" size={30} /></div>
                           <div class="text">Edit Slides</div>
                        </div>
                        <div class="tableRow" onClick={() => this.goAdminFileList({ mainName: "Buisness", name: "BusinessStudio", type: "3Dmodel" })}>
                           <div class="icon"><Badge3d color="#577df7" size={30} /></div>
                           <div class="text">Edit 3D models</div>
                        </div>
                     </div>
                  </Col>
                  <Col xl={4}>
                     <div class="settingBox">
                        <div class="topBox">
                           <div class="topLabel">Classroom</div>
                        </div>
                        <div class="tableRow" onClick={() => this.goAdminFileList({ mainName: "Classroom", name: "Trueclass", type: "Video" })}>
                           <div class="icon"><CameraVideo color="#577df7" size={30} /></div>
                           <div class="text">Edit Videos</div>
                        </div>
                        <div class="tableRow" onClick={() => this.goAdminFileList({ mainName: "Classroom", name: "Trueclass", type: "Audio" })}>
                           <div class="icon"><Soundwave color="#577df7" size={30} /></div>
                           <div class="text">Edit Audio</div>
                        </div>
                        <div class="tableRow" onClick={() => this.goAdminFileList({ mainName: "Classroom", name: "Trueclass", type: "PDF" })}>
                           <div class="icon"><FileSlides color="#577df7" size={30} /></div>
                           <div class="text">Edit Slides</div>
                        </div>
                        <div class="tableRow" onClick={() => this.goAdminFileList({ mainName: "Classroom", name: "Trueclass", type: "3Dmodel" })}>
                           <div class="icon"><Badge3d color="#577df7" size={30} /></div>
                           <div class="text">Edit 3D models</div>
                        </div>
                        <div class="tableRow" onClick={() => this.openEdit({ name: "Trueclass", type: "BaloonClassroom01", typeName: "Edit Baloon Classroom 1" })}>
                           <div class="icon"><PencilSquare color="#577df7" size={30} /></div>
                           <div class="text">Edit Baloon Classroom 1</div>
                        </div>
                        <div class="tableRow" onClick={() => this.openEdit({ name: "Trueclass", type: "BaloonClassroom02", typeName: "Edit Baloon Classroom 2" })}>
                           <div class="icon"><PencilSquare color="#577df7" size={30} /></div>
                           <div class="text">Edit Baloon Clasroom 2</div>
                        </div>
                     </div>
                  </Col>
                  <Col xl={4}>
                     <div class="settingBox">
                        <div class="topBox">
                           <div class="topLabel">Campus</div>
                        </div>
                        <div class="tableRow" onClick={() => this.openEdit({ name: "Campus", type: "BaloonGarden01", typeName: "Edit Baloon banner 1" })}>
                           <div class="icon"><PencilSquare color="#577df7" size={30} /></div>
                           <div class="text">Edit Baloon banner 1</div>
                        </div>
                        <div class="tableRow" onClick={() => this.openEdit({ name: "Campus", type: "BaloonGarden02", typeName: "Edit Baloon banner 2" })}>
                           <div class="icon"><PencilSquare color="#577df7" size={30} /></div>
                           <div class="text">Edit Baloon banner 2</div>
                        </div>
                        <div class="tableRow" onClick={() => this.openEdit({ name: "Campus", type: "CampusBannerLeft1", type2: "CampusBannerRight1", typeName: "Edit 3D banner 1" })}>
                           <div class="icon"><PencilSquare color="#577df7" size={30} /></div>
                           <div class="text">Edit 3D Banner 1</div>
                        </div>
                        <div class="tableRow" onClick={() => this.openEdit({ name: "Campus", type: "CampusBannerLeft2", type2: "CampusBannerRight2", typeName: "Edit 3D banner 2" })}>
                           <div class="icon"><PencilSquare color="#577df7" size={30} /></div>
                           <div class="text">Edit 3D Banner 2</div>
                        </div>
                        <div class="tableRow" onClick={() => this.openEdit({ name: "Campus", type: "PosterExplanation1", typeName: "Edit 3D banner sound 1", video: true })}>
                           <div class="icon"><PencilSquare color="#577df7" size={30} /></div>
                           <div class="text">Edit Banner Sound 1</div>
                        </div>
                        <div class="tableRow" onClick={() => this.openEdit({ name: "Campus", type: "PosterExplanation2", typeName: "Edit 3D banner sound 1", video: true })}>
                           <div class="icon"><PencilSquare color="#577df7" size={30} /></div>
                           <div class="text">Edit Banner Sound 2</div>
                        </div>
                     </div>
                  </Col>
                  <Col xl={4}>
                     <div class="settingBox">
                        <div class="topBox">
                           <div class="topLabel">Karaoke</div>
                        </div>
                        <div class="tableRow" onClick={() => this.goAdminFileList({ mainName: "Karaoke", name: "Music", type: "Video" })}>
                           <div class="icon"><CameraVideo color="#577df7" size={30} /></div>
                           <div class="text">Edit Karaoke Videos</div>
                        </div>
                        <div class="tableRow" onClick={() => this.goAdminFileList({ mainName: "Karaoke", name: "Music", type: "3Dmodel" })}>
                           <div class="icon"><Badge3d color="#577df7" size={30} /></div>
                           <div class="text">Edit Karaoke 3D models</div>
                        </div>
                        <div class="topBox second">
                           <div class="topLabel">Soccer</div>
                        </div>
                        <div class="tableRow" onClick={() => this.goAdminFileList({ mainName: "Soccer", name: "Soccer", type: "Video" })}>
                           <div class="icon"><CameraVideo color="#577df7" size={30} /></div>
                           <div class="text">Edit Soccer Videos</div>
                        </div>
                        <div class="topBox second">
                           <div class="topLabel">Library</div>
                        </div>
                        <div class="tableRow" onClick={() => this.goAdminFileList({ mainName: "Library", name: "Workshop", type: "3Dmodel" })}>
                           <div class="icon"><Badge3d color="#577df7" size={30} /></div>
                           <div class="text">Edit Library 3D models</div>
                        </div>
                     </div>
                  </Col>
                  <Col xl={4}>
                     <div class="settingBox">
                        <div class="topBox">
                           <div class="topLabel">Dune</div>
                        </div>
                        <div class="tableRow" onClick={() => this.goAdminFileList({ mainName: "Dune", name: "Dune", type: "Video" })}>
                           <div class="icon"><CameraVideo color="#577df7" size={30} /></div>
                           <div class="text">Edit Videos</div>
                        </div>
                        <div class="tableRow" onClick={() => this.goAdminFileList({ mainName: "Dune", name: "Dune", type: "Audio" })}>
                           <div class="icon"><Soundwave color="#577df7" size={30} /></div>
                           <div class="text">Edit Audio</div>
                        </div>
                        <div class="tableRow" onClick={() => this.goAdminFileList({ mainName: "Dune", name: "Dune", type: "PDF" })}>
                           <div class="icon"><FileSlides color="#577df7" size={30} /></div>
                           <div class="text">Edit Slides</div>
                        </div>
                        <div class="tableRow" onClick={() => this.goAdminFileList({ mainName: "Dune", name: "Dune", type: "3Dmodel" })}>
                           <div class="icon"><Badge3d color="#577df7" size={30} /></div>
                           <div class="text">Edit 3D models</div>
                        </div>
                        <div class="tableRow" onClick={() => this.openEdit({ name: "Dune", type: "EXTERNAL_event_Poster.png", typeName: "Edit External Event Poster" })}>
                           <div class="icon"><PencilSquare color="#577df7" size={30} /></div>
                           <div class="text">Edit Event Poster</div>
                        </div>
                        <div class="tableRow" onClick={() => this.openEdit({ name: "Dune", type: "EXTERNAL_exhibition.png", typeName: "Edit External Exhibition" })}>
                           <div class="icon"><PencilSquare color="#577df7" size={30} /></div>
                           <div class="text">Edit External Exhibition</div>
                        </div>
                        <div class="tableRow" onClick={() => this.openEdit({ name: "Dune", type: "EXTERNAL_exhibition_Poster.png", typeName: "Edit External Exhibition Poster" })}>
                           <div class="icon"><PencilSquare color="#577df7" size={30} /></div>
                           <div class="text">Edit External Exhibition Poster</div>
                        </div>
                        <div class="tableRow" onClick={() => this.openEdit({ name: "Dune", type: "EXTERNAL_fly_banner.png", typeName: "Edit External Fly Banner" })}>
                           <div class="icon"><PencilSquare color="#577df7" size={30} /></div>
                           <div class="text">Edit External Fly Banner</div>
                        </div>
                        <div class="tableRow" onClick={() => this.openEdit({ name: "Dune", type: "EXTERNAL_moodboard_A.png", typeName: "Edit Moodboard A" })}>
                           <div class="icon"><PencilSquare color="#577df7" size={30} /></div>
                           <div class="text">Edit Moodboard A</div>
                        </div>
                        <div class="tableRow" onClick={() => this.openEdit({ name: "Dune", type: "EXTERNAL_moodboard_B.png", typeName: "Edit Moodboard B" })}>
                           <div class="icon"><PencilSquare color="#577df7" size={30} /></div>
                           <div class="text">Edit Moodboard B</div>
                        </div>
                        <div class="tableRow" onClick={() => this.openEdit({ name: "Dune", type: "EXTERNAL_sponsored_A.png", typeName: "Edit Sponsored A" })}>
                           <div class="icon"><PencilSquare color="#577df7" size={30} /></div>
                           <div class="text">Edit Sponsored A</div>
                        </div>
                        <div class="tableRow" onClick={() => this.openEdit({ name: "Dune", type: "EXTERNAL_sponsored_B.png", typeName: "Edit Sponsored B" })}>
                           <div class="icon"><PencilSquare color="#577df7" size={30} /></div>
                           <div class="text">Edit Sponsored B</div>
                        </div>
                        <div class="tableRow" onClick={() => this.openEdit({ name: "Dune", type: "speech_logo_D.glb", typeName: "Dune Logo", ext: "3d" })}>
                           <div class="icon"><Badge3d color="#577df7" size={30} /></div>
                           <div class="text">Edit Dune Logo</div>
                        </div>
                     </div>
                  </Col>
               </Row>
            }
            <Modal
               show={this.state.show}
               onHide={this.handleClose}
               backdrop="static"
               aria-labelledby="contained-modal-title-vcenter"
               centered
               keyboard={false}
            >
               <Modal.Header>
                  <Modal.Title><PlusSquare color="#577df7" size={25} style={{ marginRight: "10px" }} />{this.state.selectedBanner && this.state.selectedBanner.typeName}</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  <AdminModal isFilesUploaded={this.handleClose} data={this.state.selectedBanner} img={this.state.selectedImage} langs={this.props.langs} />
               </Modal.Body>
               <Modal.Footer>
                  <Button variant="secondary" onClick={this.handleClose}>
                     {this.props.langs.cancel}
                  </Button>
                  {/* <Button variant="primary">Continue</Button>  */}
               </Modal.Footer>
            </Modal>
            <Modal
               show={this.state.showDelete}
               onHide={this.handleCloseDelete}
               backdrop="static"
               aria-labelledby="contained-modal-title-vcenter"
               centered
               keyboard={false}
            >
               <Modal.Header>
                  <Modal.Title><InfoSquareFill color="#577df7" size={25} style={{ marginRight: "10px" }} />{this.props.langs.delete}</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  {this.props.langs.areYouSureDeleteClass}
               </Modal.Body>
               <Modal.Footer>
                  <Button variant="secondary" onClick={this.handleCloseDelete}>
                     {this.props.langs.cancel}
                  </Button>
                  <Button variant="danger" onClick={this.deleteClass}>{this.props.langs.delete}</Button>
               </Modal.Footer>
            </Modal>
         </Container>
      )
   }
}

export default Classes;