import React from 'react';
import './message.scss'
import { Container, Col, Row, Dropdown } from 'react-bootstrap';
import { postSocial, postChat, postActions } from '../../helpers/request'
import { ArrowRightSquare, EmojiLaughing } from 'react-bootstrap-icons';
import Picker from 'emoji-picker-react';
import moment from 'moment'

class Messages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: '',
      myUserId: '',
      lastMessage: [{ ID: 'a' }],
      selectedChat: '',
      classList: '',
      messageList: [],
      message: '',
      friends: [],
      intervalItem: null
    };
  }

  sendMessage = (e) => {
    if (e.charCode == 13 && e.target.value) {
      const data = new FormData();
      data.append("UserID", localStorage.getItem('userID').replace(/['"]+/g, ''));
      data.append("UserName", localStorage.getItem('username'));
      data.append("ChatID", this.state.selectedChat.chats)
      data.append("Action", "send")
      data.append("Message", this.state.message)
      postChat(data).then((response) => {
        this.setState({ message: '' })
        let lastMsgData = this.state.lastMessage
        lastMsgData.push(response.message[0])
        this.setState({ lastMessage: lastMsgData })
        let data = this.state.messageList
        data.push(response.message[0])
        this.setState({ messageList: data })
      })
    }
  }

  onEmojiClick = (event, emojiObject) => {
    this.setState({ message: this.state.message + emojiObject.emoji })
  }

  sendMessageWith = () => {
    if (this.state.message && this.state.selectedChat) {
      const data = new FormData();
      data.append("UserID", localStorage.getItem('userID').replace(/['"]+/g, ''));
      data.append("UserName", localStorage.getItem('username'));
      data.append("ChatID", this.state.selectedChat.chats)
      data.append("Action", "send")
      data.append("Message", this.state.message)
      postChat(data).then((response) => {
        this.setState({ message: '' })
        let lastMsgData = this.state.lastMessage
        lastMsgData.push(response.message[0])
        this.setState({ lastMessage: lastMsgData })
        let data = this.state.messageList
        data.push(response.message[0])
        this.setState({ messageList: data })
      })
    }
  }

  getLastMessages = () => {
    const data = new FormData();
    data.append("userID", localStorage.getItem('userID').replace(/['"]+/g, ''));
    data.append("userName", localStorage.getItem('username').replace(/['"]+/g, ''));
    data.append("ChatID", this.state.selectedChat.chats)
    data.append("Action", "getInit")
    postChat(data).then((response) => {
      this.setState({ messageList: response.message })
    })
  }

  handleChange = (e) => {
    this.setState({ message: e.target.value })
  }


  handleSelectedFriend = (friend) => {
    let list = this.state.friends
    let selected = list.find(item => item.friendID == friend.friendID)
    if (selected) {
      this.setState({ selectedChat: selected })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.selectedChat) {
      if (prevState.selectedChat.friendID !== this.state.selectedChat.friendID) {
        this.getLastMessages()
      }
      this.scrollToBottom()
    }
  }

  handleSelectedClass = (ClassObj) => {
    let list = this.state.classList
    let selected = list.find(item => item.ID == ClassObj.ID)
    let data = {
      name: selected.Name,
      friendID: selected.ID,
      chats: selected.chatID
    }
    this.setState({ selectedChat: data })
  }

  getFriends = () => {
    const data = new FormData();
    data.append("UserID", localStorage.getItem('userID').replace(/['"]+/g, ''));
    // data.append("FriendID", "1");
    data.append("Action", "getFriends")
    postSocial(data).then((response) => {
      this.createFriendsObject(response)
    })
  }

  createFriendsObject = (data) => {
    let obj = []
    let n = data.friends.length
    for (let x = 0; x < n; x++) {
      let createObj = {
        name: data.names[x],
        friendID: data.friends[x],
        chats: data.chats[x]
      }
      obj.push(createObj)
    }
    this.setState({ friends: obj })
    if (!this.state.selectedChat && obj.length) {
      this.setState({ selectedChat: obj[0] })
      this.getLastMessages()
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalItem)
  }

  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
    // this.messagesEnd.scrollIntoView({ behavior: "smooth" });
  }

  getMessagesBySec = () => {
    this.state.intervalItem = setInterval(() => {
      const data = new FormData();
      data.append("userID", localStorage.getItem('userID').replace(/['"]+/g, ''));
      data.append("userName", "Mike");
      data.append("ChatID", this.state.selectedChat.chats)
      data.append("Action", "getLastMsg")
      postChat(data).then((response) => {
        if (response && !response.empty) {
          for (let x = 0; x < response.message.length; x++) {
            let isMessage = this.state.lastMessage.find(item => item.ID == response.message[x].ID)
            if (!isMessage) {
              let lastMsgData = this.state.lastMessage
              lastMsgData.push(response.message[x])
              this.setState({ lastMessage: lastMsgData })
              let data = this.state.messageList
              data.push(response.message[x])
              this.setState({ messageList: data })
            }
          }
        }
      })
    }, 2000);
  }

  getClasses = () => {
    let data = {
      Action: "getUserClasses",
      UserID: localStorage.getItem('userID').replace(/['"]+/g, '')
    }
    postActions(data).then((response) => {
      console.log(response)
      let filteredList = response.Classes.filter(item => item.Status === '1')
      this.setState({ classList: filteredList })
      //  this.setState({isLoading: false})
    })
  }


  componentDidMount() {
    this.getFriends()
    this.getMessagesBySec()
    if (localStorage.getItem('type').replace(/['"]+/g, '') != '3') {
      this.getClasses()
    }
    let user = localStorage.getItem('userID')
    user = user.replace(/['"]+/g, '')
    this.setState({ myUserId: user })
    if (this.props.location.state && this.props.location.state.ID) {
      let data = {}
      data.friendID = this.props.location.state.ID
      this.handleSelectedFriend(data)
    }
  }

  returnGTM = (time) => {
    let date = moment.utc(time).local().format('HH:mm')
    return date
  }
  render() {
    return (
      <Container>
        <Row className="messageMain">
          <Col xl={4} className="leftBox">
            <Col className="label" xl={12}>
              <div class="labelMain">Messages</div>
              {/* <div class="newButtonMain"><PlusSquare color="#577df7" size={25} /><span class="buttonText">New</span></div> */}
            </Col>
            {/* <div class="messageLabel">Your Messages</div> */}
            {localStorage.getItem('type').replace(/['"]+/g, '') != '3' ?
              <div class="messageList">
                <div Class="messageListLabel">Classes</div>
                {this.state.classList ? this.state.classList && this.state.classList.map(item =>
                  <div class="messageUserButton" onClick={() => this.handleSelectedClass(item)}>{item.Name}</div>
                ) :
                  <div class="messageUserButton">No Class Chat</div>
                }
              </div>
              :
              ''
            }
            <div class="messageList">
              <div Class="messageListLabel">Friends</div>
              {this.state.friends && this.state.friends.map(item =>
                <div class="messageUserButton" onClick={() => this.handleSelectedFriend(item)}>{item.name}</div>
              )}
            </div>
          </Col>
          <Col xl={8} className="rightBox">
            <div className="userName">{this.state.selectedChat && this.state.selectedChat.name}</div>
            <div className="messageBox">
              <div className="messageArea">
                {this.state.messageList && this.state.messageList.map(message =>
                  <div key={message.ID}>
                    {message.UserID == this.state.myUserId ? (
                      <div className="userMessage">
                        <div className="userMessageInside" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <span>{message.Message}</span>
                          <span class="messageTime">{this.returnGTM(message.Time)}</span>
                        </div>
                      </div>
                    ) : (
                      <div className="otherMessage">
                        <div class="OtherMessageInside">
                          <div class="userName">{message.UserName.replace(/['"]+/g, '')}</div>
                          <div style={{ display: 'flex' }}>
                            <span>{message.Message}</span>
                            <span class="messageTime">{this.returnGTM(message.Time)}</span>
                          </div>
                        </div>
                      </div>
                    )
                    }
                  </div>
                )}
                <div style={{ float: "left", clear: "both" }}
                  ref={(el) => { this.messagesEnd = el; }}>
                </div>
              </div>
              <div className="messageWriteArea">
                <input type="text" name="name" value={this.state.message} onChange={(e) => this.handleChange(e)} onKeyPress={this.sendMessage} />
                <div style={{ position: 'relative', display: 'flex' }}>
                  <div style={{ margin: 'auto', marginRight: '5px' }}>
                    <Dropdown>
                      <Dropdown.Toggle variant="success" className="customDrop" id="dropdown-basic">
                        <EmojiLaughing color="#577df7" size={25} onClick={this.openEmoji} />
                      </Dropdown.Toggle>
                      <Dropdown.Menu align="end">
                        <Picker onEmojiClick={this.onEmojiClick} />
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <ArrowRightSquare color="#577df7" size={35} onClick={this.sendMessageWith} />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}


export default Messages;
