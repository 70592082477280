import React from 'react';
import './questions.scss'
import { Container, Col, Row, Form} from 'react-bootstrap';
import { postFormHandling, postActions } from '../../helpers/request'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class TrueFalse extends React.Component {
   constructor(props) {
      super(props);
     this.state = {
         data: '',
         QuestionId: 2,
         testTitle: '',
         time: '60',
         imageList: [],
         selectedImage: '',
         questionData: [
            {
               Question: '',
               Num: "1",
               Answer: false
            },
         ]
      };
    }
    
    sendError = () => {
      toast.warn('Please Add True/False', {
         position: "top-right",
         autoClose: 5000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         limit: 3,
         progress: undefined,
      })
   }

   componentDidMount () {
      this.getImageFiles().then(() => {
         if (this.props.location.state && this.props.location.state.edit) {
            this.getData(this.props.location.state.id)
         }
      })
   }

   getData = (Qid) => {
      let data = {
         Type: "getTrueFalse",
         FileID: Qid
      }
      postFormHandling(data).then((res) => {
         console.log(res)
         let que = res.Data.Questions
         let arr = []
         for (let x = 0; x < que.length; x++) {
            let obj = {
               Question: que[x].Question,
               Num: que[x].Num,
               Answer: que[x].Answer
            }
            arr.push(obj)
         }
         if (res.Data.Image) {
            this.setState({selectedImage: res.Data.Image})
         }
         this.setState({testTitle: res.Data.Name})
         this.setState({time: res.Data.Time})
         this.setState({questionData: arr})
      })
   }

   getImageFiles = () =>{
      return new Promise((resolve, rejcet) => {
         let data = {
            Action:"getUserLibraries",
            UserID: localStorage.getItem('userID').replace(/['"]+/g, '')
         }
         postActions(data).then((response) => {
            let fileList = []
            // for (let x = 0; x < response.Libraries.length;x++) {
               let data = {
                  Action:"getLibraryFileList",
                  UserID: localStorage.getItem('userID').replace(/['"]+/g, ''),
                  LibraryID: response.Libraries[0].ID
               }
               postActions(data).then((response2) => {
                  if (response2.Files) {
                     fileList = response2.Files
                     fileList = fileList.filter(item => item.Type == "Image")
                     this.setState({imageList: fileList})
                     resolve()
                  }
                  resolve()
               })
            // }
         })   
      })
   }

   selectImage = (e) => {
      this.setState({selectedImage: e.target.value})
   }

    createTrueFalseRequest = () => {
       let data = {}
       if (this.props.location.state && this.props.location.state.edit) {
         let obj = {
            Type: "updateTrueFalse",
            ID: localStorage.getItem('userID').replace(/['"]+/g, ''),
            FileID: this.props.location.state.id,
            Form:{
               Time: this.state.time,
               Title:this.state.testTitle,
               ImageID: this.state.selectedImage,
               Questions:this.state.questionData
            }
         }
         data = obj
      } else {
         let obj = {
           Type:"createTrueFalse",
           ID: localStorage.getItem('userID').replace(/['"]+/g, ''),
           LibraryID: localStorage.getItem('FList'),
           Form:{
               Time: this.state.time,
               Title:this.state.testTitle,
               ImageID: this.state.selectedImage,
               Questions:this.state.questionData
           }
        }
        data = obj
      }
      postFormHandling(data).then(() => {
         if (this.props.location.state && this.props.location.state.edit) {
            toast.success('True/False successfuly Updated', {
               position: "top-right",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               limit: 3,
               progress: undefined,
            });
         } else {  
            toast.success('True/False successfuly created', {
               position: "top-right",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               limit: 3,
               progress: undefined,
            });
         }
         this.props.history.push("/files")
      })
    }

    creteNewQuestion = () => {
      let questionId = this.state.QuestionId
      let newQueData = {
         Question: 'Example name',
         Num: questionId,
         Answer: false
      }
      questionId++
      this.setState({questionData: [...this.state.questionData, newQueData], QuestionId: questionId})
    }

    deleteQue = (item) => {
       let newList = this.state.questionData.filter(que => que.Num !== item.Num)
       this.setState({questionData:newList})
    }
    handleTrueFalse = (condition, item) => {
       let ques = this.state.questionData
       let index = ques.findIndex(que => que.Num === item.Num)
       ques[index].Answer = condition ? 'True' : 'False'
       this.setState({questionData: ques})
    }
    handleChange = (e, item) => {
      if (item) {
         let array = [...this.state.questionData]
         let index = this.state.questionData.findIndex(que => que.Num === item.Num)
         array[index].Question = e.target.value
         this.setState({questionData: array})
      } else {
         this.setState({[e.target.name]: e.target.value})
      }
    }
   render () {
      const { questionData, testTitle, time} = this.state
      const valid = questionData && questionData.length && questionData[0] && testTitle && time
      return (
         <Container>
            <Row className="labelBar justify-content-md-center">
               <Col className="label" xl={12}>
                  <Row style={{width: '100%'}}>
                     <Col xl={3} className="labelMain">True/False</Col>
                     <Col xl={4} className="titleBox">
                        <span className="title">Title:</span>
                        <input type="text" name="testTitle" value={this.state.testTitle} onChange={this.handleChange} placeholder="Title"/>
                     </Col>
                     <Col xl={4} className="titleBox">
                        <span className="title">Time:</span>
                        <input min="0" type="number" value={this.state.time} name="time" onChange={this.handleChange} placeholder="Time"/>
                     </Col>
                  </Row>
               </Col>
            </Row>
            <Row className="pageRow">
               <Col xl={12}>
                  <div class="settingBox">
                     <Col xl={12} className="questionLabel">
                        <Row>
                           <Col xl={9}>Question</Col>
                           <Col xl={3}>  
                              <Form.Control as="select" value={this.state.selectedImage} onChange={(e) => this.selectImage(e)} className="selectBox" size="sm">
                                    <option>Select Image</option>
                                 {this.state.imageList && this.state.imageList.map(item => 
                                    <option value={item.ID}>{item.Name}</option>
                                 )}
                              </Form.Control>
                           </Col>
                        </Row>
                     </Col>
                     {this.state.questionData && this.state.questionData.map((item, index) => 
                     <Col key={item.Num} xl={12} className="questionBox">
                        <Row className="NoMargin">
                           <Col xl={8} className="questionText">
                              <div class="number">{index + 1}</div>
                              <input type="text" name={item.Num} value={item.Question} onChange={(e) => this.handleChange(e, item)} placeholder="Question Name" maxlength="500"/>
                           </Col>
                           <Col xl={3} className="trueFalseBox">
                              <Row>
                                 <Col xl={6} className="radioBox">
                                    <div class="radioBoxInside">
                                       True
                                       <input type="radio" name={item.Num} key={item.Num} checked={item.Answer} onChange={() => this.handleTrueFalse(true, item)}/>
                                    </div>
                                 </Col>
                                 <Col xl={6} className="radioBox">
                                    <div class="radioBoxInside">
                                       False
                                       <input type="radio" name={item.Num} key={item.Num} checked={!item.Answer} onChange={() => this.handleTrueFalse(false, item)}/>
                                    </div>
                                 </Col>
                              </Row>
                           </Col>
                           <Col xl={1} className="selectAudio">
                                 <div class="deleteButton" onClick={() => this.deleteQue(item)}>Delete</div>
                           </Col>
                        </Row>
                        <Row>
                           <Col xl={3} className="optionBox"></Col>
                        </Row>
                     </Col>
                     )}
                     <Col xl={12} className="addQueButton" onClick={this.creteNewQuestion}>Add</Col>
                     <Col xl={12} className="addQueButton" onClick={valid ? this.createTrueFalseRequest : this.sendError}>Save</Col>
                  </div>
               </Col>
            </Row>
         </Container>
      )
   }
}

export default TrueFalse;