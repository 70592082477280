import React from 'react';
import './questions.scss'
import { Container, Col, Row } from 'react-bootstrap';
import { postFormHandling } from '../../helpers/request'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class UserSettings extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         data: '',
         optionId: 2,
         QuestionId: 2,
         testTitle: '',
         time: '',
         questionData: [
            {
               Word1: '',
               Word2: '',
               Num: 1
            },
         ]
      };
    }

    sendError = () => {
      toast.warn('Please Add Words', {
         position: "top-right",
         autoClose: 5000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         limit: 3,
         progress: undefined,
      })
   }

    createWordMatchRequest = () => {
      let ques = []
      for (let x = 0; x < this.state.questionData.length; x++) {
         ques.push(this.state.questionData[x].options)
      }
      let data = []
      if (this.props.location.state && this.props.location.state.edit) {
         let obj = {
            Type:"updateWordMatch",
            FileID: this.props.location.state.id,
            ID: localStorage.getItem('userID').replace(/['"]+/g, ''),
            Form: {
               Title: this.state.testTitle,
               Time: this.state.time,
               Words: this.state.questionData
            }
         }
         data = obj
      } else {
         let obj = {
            Type:"createWordMatch",
            ID: localStorage.getItem('userID').replace(/['"]+/g, ''),
            LibraryID: localStorage.getItem('FList'),
            Form: {
               Title: this.state.testTitle,
               Time: this.state.time,
               Words: this.state.questionData
            }
         }
         data = obj
      }
      postFormHandling(data).then(() => {
         if (this.props.location.state && this.props.location.state.edit) {
            toast.success('WordMatch successfuly Updated', {
               position: "top-right",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               limit: 3,
               progress: undefined,
            });
         } else {  
            toast.success('WordMatch successfuly created', {
               position: "top-right",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               limit: 3,
               progress: undefined,
            });
         }
         this.props.history.push("/files")
      })
    }
    creteNewQuestion = () => {
      let count = this.state.optionId
      let newQueData = {
         Word1: '',
         Word2: '',
         Num: count
      }
      count++
      this.setState({optionId: count})
      this.setState({questionData: [...this.state.questionData, newQueData]})
    }

    deleteQue = (item) => {
       let newList = this.state.questionData.filter(que => que.Num !== item.Num)
       this.setState({questionData:newList})
    }
    
    componentDidMount () {
      if (this.props.location.state && this.props.location.state.edit) {
         this.getData(this.props.location.state.id)
      }
    }

   getData = (Qid) => {
      let data = {
         Type: "getWordMatch",
         FileID: Qid
      }
      postFormHandling(data).then((res) => {
         console.log(res)
         let que = res.Data.Words
         let arr = []
         for (let x = 0; x < que.length; x++) {
            let obj = {
               Word1: que[x].Word1,
               Word2: que[x].Word2,
               Num: que[x].Num
            }
            arr.push(obj)
         }
         this.setState({testTitle: res.Data.Name})
         this.setState({time: res.Data.Time})
         this.setState({questionData: arr})
      })
   }

    handleChange = (e, item) => {
      if (item) {
         let array = [...this.state.questionData]
         let index = this.state.questionData.findIndex(que => que.Num === item.Num)
         if (e.target.name == 'Word1') {
            array[index].Word1 = e.target.value
         } else {
            array[index].Word2 = e.target.value
         }
         this.setState({questionData: array})
      } else {
         this.setState({[e.target.name]: e.target.value})
      }
    }
   render () {
      const { questionData, testTitle, time } = this.state
      const valid = questionData && questionData.length && questionData[0] && testTitle && time
      return (
         <Container>
            <Row className="labelBar justify-content-md-center">
               <Col className="label" xl={12}>
                  <Row style={{width: '100%'}}>
                     <Col xl={3} className="labelMain">Word Match</Col>
                     <Col xl={4} className="titleBox">
                        <span className="title">Title:</span>
                        <input type="text" name="testTitle" placeholder="Title" value={this.state.testTitle} onChange={this.handleChange}/>
                     </Col>
                     <Col xl={4} className="titleBox">
                        <span className="title">Time:</span>
                        <input min="0" type="number" name="time" value={this.state.time} placeholder="Time" onChange={this.handleChange}/>
                     </Col>
                  </Row>
               </Col>
            </Row>
            <Row className="pageRow">
               <Col xl={12}>
                  <div class="settingBox">
                     <Col xl={12} className="questionLabel">Words</Col>
                     {this.state.questionData && this.state.questionData.map((item, index) =>
                        <Col xl={12} className="questionBox">
                           <Row className="justify-content-md-center">
                              <Col xl={11} className="wordMatchBox">
                                 <Row>
                                    <Col xl={6} className="inputBox">
                                       <div class="inputBoxLeft">
                                          <div class="number">{index + 1}</div>
                                          <input type="text" name="Word1" placeholder="Example" onChange={(e) => this.handleChange(e, item)} value={item.Word1}/>
                                       </div>
                                    </Col>
                                    <Col xl={6} className="inputBox">
                                       <div class="inputBoxRight">
                                          <input type="text" name="Word2" placeholder="Example" onChange={(e) => this.handleChange(e, item)} value={item.Word2}/>
                                       </div>
                                    </Col>
                                 </Row>
                              </Col>
                              <Col xl={1} className="selectAudio">
                                 <div class="deleteButton" onClick={() => this.deleteQue(item)}>Delete</div>
                              </Col>
                           </Row>
                        </Col>
                     )}
                     <Col xl={12} className="addQueButton"  onClick={this.creteNewQuestion}>Add</Col>
                     <Col xl={12} className="addQueButton" onClick={valid ? this.createWordMatchRequest : this.sendError}>Save</Col>
                  </div>
               </Col>
            </Row>
         </Container>
      )
   }
}

export default UserSettings;