import React from 'react';
import { Container, Col, Row, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './createAssignment.scss'
import { postFormHandling } from '../../helpers/request'
import moment from 'moment'


class CreateMewLecture extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         ClassID:"1",
         Name:"",
         Description:"",
         StartDateDate: '',
         StartDateTime: '',
         EndDateDate: '',
         EndDateTime: '',
         MaxGrade:""
      };
   }

   handleChange = (e) => {
      this.setState({[e.target.name]: e.target.value})  
   }

   creatNewLec = () => {
      let startDate = moment(this.state.StartDateDate + ' ' + this.state.StartDateTime).utc().format('YYYY-MM-DD HH:mm')
      let endDate = moment(this.state.EndDateDate + ' ' + this.state.EndDateTime).utc().format('YYYY-MM-DD HH:mm')
      if (moment(startDate).isSameOrAfter(endDate)) {
         toast.warn('End date can not be earlier than start date', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            limit: 3,
            progress: undefined,
         })
         return ''
      }
      let data = {}
      if (this.props.location.state && this.props.location.state.assign) {
         let obj = {
            Action:"updateAssignment",
            ID: localStorage.getItem('userID').replace(/['"]+/g, ''),
            AssignmentID: this.props.location.state.assign.ID,
            Form:{
               ClassID: this.state.ClassID,
               Name: this.state.Name,
               Description: this.state.Description,
               StartDate: startDate,
               EndDate: endDate,
               FileID: "0",
               MaxGrade: this.state.MaxGrade,
               Time:"0"
            }
         }
         data = obj
      } else {
         let obj = {
            Type: "newLecture",
            ID: localStorage.getItem('userID').replace(/['"]+/g, ''),//User ID
            Form: {
               ClassID: this.props.location.state,
               Name: this.state.Name,
               Description: this.state.Description,
               StartDate: startDate,
               EndDate: endDate,
               MaxGrade: this.state.MaxGrade
            }
         }
         data = obj
      }
      postFormHandling(data).then(() => {
         toast.success('Lecture successfuly created', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            limit: 3,
            progress: undefined,
         });
         this.props.history.push("/classes")
      })
   }

   sendError = () => {
      toast.warn('Please fill in all the input fields.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        limit: 3,
        progress: undefined,
     })
    }

    fillState = () => {
      let data = this.props.location.state.assign
      this.setState({Name: data.Name})
      this.setState({Description: data.Description})
      if (data.EndDate) {
         let date = moment.utc(data.EndDate).local().format('YYYY-MM-DD')
         let time = moment.utc(data.EndDate).local().format('HH:mm')
         this.setState({EndDateDate: date})
         this.setState({EndDateTime: time})
      }
      if (data.StartDate) {
         let date = moment.utc(data.StartDate).local().format('YYYY-MM-DD')
         let time = moment.utc(data.StartDate).local().format('HH:mm')
         this.setState({StartDateDate: date})
         this.setState({StartDateTime: time})
      }
      this.setState({MaxGrade: data.MaxGrade})  
    }

    componentDidMount () {
      if (this.props.location.state && this.props.location.state.assign) {
         this.setState({classID: this.props.location.state.ClassID})
         this.fillState()
      } else {
         let MyDate = new Date()
         let today = moment(MyDate).format('YYYY-MM-DD')
         let endMonth = moment(MyDate).format('YYYY-MM-DD')
         let todayTime = moment(MyDate).format('HH:mm')
         let endTime = moment(MyDate).add(1, 'hour').format('HH:mm')
         this.setState({StartDateDate: today})
         this.setState({EndDateDate: endMonth})
         this.setState({StartDateTime: todayTime})
         this.setState({EndDateTime: endTime})
      }
    }
    
   render () {
      const { Name, Description, StartDateDate, StartDateTime, EndDateDate, EndDateTime, MaxGrade } = this.state
      const valid =
         Name.length > 0 &&
         Description.length > 0 &&
         StartDateDate.length > 0 &&
         StartDateTime.length > 0 &&
         EndDateDate.length > 0 &&
         EndDateTime.length > 0 &&
         MaxGrade.length > 0; 
      return (
         <Container>
            <Col xl={6} className="mainBox">
               <Col xl={12} className="lectureLabel">{this.props.langs.newLecture}</Col>
               <Row className="newAssignmentRow">
                  <Col className="inputLabel" xl={4}>{this.props.langs.lectureName}</Col>
                  <Col className="inputBox" xl={8}><input name="Name" value={this.state.Name} onChange={(e) => this.handleChange(e)} type="text" maxlength="50"/></Col>
               </Row>
               <Row className="newAssignmentRow">
                  <Col className="inputLabel" xl={4}>{this.props.langs.startDate}</Col>
                  <Col className="inputBox" xl={8}>
                     <Row>
                        <Col xl={7} className="noP">
                           <Form.Control className="customDatepicker" name="StartDateDate" type="date" value={this.state.StartDateDate} onChange={(e) => this.handleChange(e)}></Form.Control>
                        </Col>
                        <Col xl={5} className="noP">
                           <Form.Control className="customDatepicker" name="StartDateTime" type="time" value={this.state.StartDateTime} onChange={(e) => this.handleChange(e)}></Form.Control>
                        </Col>
                     </Row>
                  </Col>
               </Row>
               <Row className="newAssignmentRow">
                  <Col className="inputLabel" xl={4}>{this.props.langs.endDate}</Col>
                  <Col className="inputBox" xl={8}>
                     <Row>
                        <Col xl={7} className="noP">
                           <Form.Control className="customDatepicker" name="EndDateDate" type="date" value={this.state.EndDateDate} onChange={(e) => this.handleChange(e)}></Form.Control>
                        </Col>
                        <Col xl={5} className="noP">
                           <Form.Control className="customDatepicker" name="EndDateTime" type="time" value={this.state.EndDateTime} onChange={(e) => this.handleChange(e)}></Form.Control>
                        </Col>
                     </Row>
                  </Col>
               </Row>
               <Row className="newAssignmentRow">
                  <Col className="inputLabel" xl={4}>{this.props.langs.attendencePoint}</Col>
                  <Col className="inputBox" xl={8}><input name="MaxGrade" value={this.state.MaxGrade} onChange={(e) => this.handleChange(e)} min="0" type="number"/></Col>
               </Row>
               {/* <Row className="newAssignmentRow">
                  <Col className="inputLabel" xl={4}>Mandotry:</Col>
                  <Col className="inputBox checkbox" xl={8}><input type="checkbox"/></Col>
               </Row> */}
               <Row className="newAssignmentRow">
                  <Col className="inputLabel" xl={4}>{this.props.langs.notes}</Col>
                  <Col className="inputBox textArea" xl={8}><Form.Control name="Description" value={this.state.Description} onChange={(e) => this.handleChange(e)} as="textarea" className="textAreaClass" rows={3} /></Col>
               </Row>
               <Col xl={12}>
                  <div class="createButton" onClick={valid ? this.creatNewLec : this.sendError}><span class="buttonText">{this.props.langs.createLecture}</span></div>
               </Col>
            </Col>
         </Container>
      )
   }
}

export default CreateMewLecture;