import React from 'react';
import userBlankIcon from '../../img/ProfilePH.png';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './classes.scss'
import { Container, Button, Col, Row, Modal, Spinner, Dropdown } from 'react-bootstrap';
import { Check, Folder, Plus, PlusSquare, ThreeDotsVertical, InfoSquareFill, Trash, FileText, Check2, Clock, Eye, EyeSlash } from 'react-bootstrap-icons';
import CreateNewAssignment from './CreateNewAssignment';
import { Link } from 'react-router-dom';
import { postActions } from '../../helpers/request'

class Classes extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         data: '',
         classesList: '',
         show: false,
         setShow: false,
         showDelete: false,
         isLoading: true,
         userType: null,
         showCompleteClassState: false,
         pureDate: []
      };
   }

   handleCloseDelete = () => {
      this.setState({ showDelete: false })
   }

   handleOpenDelete = (item) => {
      this.setState({ readyToDeleteId: item.ID })
      this.setState({ showDelete: true })
   }

   deleteFile = () => {
      let data = {
         Action: "deleteFile",
         UserID: localStorage.getItem('userID').replace(/['"]+/g, ''),
         CurrentLibID: localStorage.getItem('FList'),
         FileID: this.state.readyToDeleteId
      }
      postActions(data).then((response) => {
         this.getLibraryFilelist(this.state.selectedLibrary)
         toast.success('File deleted', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            limit: 3,
            progress: undefined,
         });
         this.handleCloseDelete()
      })
   }

   getClasses = () => {
      let data = {
         Action: "getUserClasses",
         UserID: localStorage.getItem('userID').replace(/['"]+/g, '')
      }
      postActions(data).then((response) => {
         this.setState({ classesList: response.Classes })
         this.setState({ isLoading: false })
      }).catch(() => {
         this.setState({ isLoading: false })
      })
   }

   showCompleteClass = () => {
      this.setState({ showCompleteClassState: !this.state.showCompleteClassState })
   }
   // getUncheckLenght = (item) => {
   //    let data = {
   //       Action:"getUncheckedAssignments",
   //       UserID: localStorage.getItem('userID').replace(/['"]+/g, ''),//User ID
   //       ClassID: item.ID
   //    }
   //    let count = ''
   //    postActions(data).then((response) => {
   //       // count = response.Assignments.length
   //    })
   // }

   componentDidMount() {
      this.getClasses()
      let type = localStorage.getItem('type').replace(/['"]+/g, '')
      if (type) {
         this.setState({ userType: type })
      }
   }

   handleClose = () => {
      this.setState({ show: false })
   }
   handleOpen = () => {
      this.setState({ show: true })
   }

   createNewAssignmentFunc = (item) => {
      this.props.history.push({
         pathname: "/classes/createNewAssignment",
         state: item.ID
      })
   }

   createNewLectureFunc = (item) => {
      this.props.history.push({
         pathname: "/classes/createNewLecture",
         state: item.ID
      })
   }

   activeAssignments = (item) => {
      this.props.history.push({
         pathname: "/activeAssignments",
         state: item
      })
   }

   classDetail = (item) => {
      this.props.history.push({
         pathname: "/classes/classDetail",
         state: item
      })
   }

   classFileList = (item) => {
      this.props.history.push({
         pathname: "/classes/classFileList",
         state: item
      })
   }

   classGrade = (item) => {
      this.props.history.push({
         pathname: "/checkAssignment",
         state: item.ID
      })
   }

   completeClass = (item) => {
      let data = {
         Action: "completeClass",
         UserID: localStorage.getItem('userID').replace(/['"]+/g, ''),
         ClassID: item.ID
      }
      postActions(data).then(() => {
         toast.success('Class completed', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            limit: 3,
            progress: undefined,
         });
      })
   }

   inCompleteClass = (item) => {
      let data = {
         Action: "inCompleteClass",
         UserID: localStorage.getItem('userID').replace(/['"]+/g, ''),
         ClassID: item.ID
      }
      postActions(data).then(() => {
         toast.success('Class completed', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            limit: 3,
            progress: undefined,
         });
      })
   }

   deleteClass = () => {
      let data = {
         Action: "deleteClass",
         UserID: localStorage.getItem('userID').replace(/['"]+/g, ''),
         ClassID: this.state.readyToDeleteId
      }
      postActions(data).then(() => {
         this.getClasses()
         this.handleCloseDelete()
         toast.success('Class deleted', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            limit: 3,
            progress: undefined,
         });
      })
   }

   render() {
      return (
         <Container>
            <Row className="labelBar justify-content-md-center">
               <Col className="label" xl={12}>
                  <div class="labelMain">{this.state.userType == 3 ? this.props.langs.event : this.props.langs.classes}</div>
                  <Link to="/classes/createNewClass">
                     <div class="newButtonMain"><PlusSquare color="#577df7" size={25} /><span class="buttonText">{this.props.langs.new}</span></div>
                  </Link>
                  <div class="newButtonMain" onClick={this.showCompleteClass}>{this.state.showCompleteClassState ? <EyeSlash color="#577df7" size={25} /> : <Eye color="#577df7" size={25} />}<span class="buttonText">{this.state.showCompleteClassState ? this.props.langs.hideCompleted : this.props.langs.showCompleted}</span></div>
               </Col>
            </Row>
            {this.state.isLoading ?
               <div class="animCenter">
                  <Spinner animation="border" role="status">
                     <span className="visually-hidden">Loading...</span>
                  </Spinner>
               </div>
               :
               <Row className="pageRow">
                  {this.state.classesList && this.state.classesList.length ? this.state.classesList.map(item =>
                     item.Status != '4' && item.Status != 3 || (item.Status == 3 && this.state.showCompleteClassState) ?
                        <Col xl={3} key={item.ID}>
                           <div class="settingBox">
                              <div class="topBox">
                                 <div class="topLabel">{item.Name}</div>
                                 <div class="topLabelButton">
                                    <Dropdown>
                                       <Dropdown.Toggle variant="success" className="customDrop" id="dropdown-basic">
                                          <ThreeDotsVertical color="#577df7" size={25} />
                                       </Dropdown.Toggle>
                                       <Dropdown.Menu align="end">
                                          {item.Status == 3 ? <Dropdown.Item onClick={() => this.handleOpenDelete(item)}><Trash color="#577df7" size={20} /><span class="buttonText">{this.props.langs.deleteClass}</span></Dropdown.Item> : ''}
                                          {item.Status == 1 ? <Dropdown.Item onClick={() => this.completeClass(item)}><Check2 color="#577df7" size={20} /><span class="buttonText">{this.props.langs.completeClass}</span></Dropdown.Item> : ''}
                                          {item.Status == 3 ? <Dropdown.Item onClick={() => this.completeClass(item)}><Check2 color="#577df7" size={20} /><span class="buttonText">{this.props.langs.inCompleteClass}</span></Dropdown.Item> : ''}
                                       </Dropdown.Menu>
                                    </Dropdown>
                                 </div>
                              </div>
                              {/* <div class="last_nextClasss">Next Class: 07-20-2021</div> */}
                              {this.state.userType == 3 ?
                                 <div class="tableRow noHover">
                                    <div class="text">{this.props.langs.startDate + ': ' + item.StartDate.slice(0, 16)}</div>
                                 </div>
                                 :
                                 ''
                              }
                              {item.Status == 3 ?
                                 <div class="tableRow noHover color">
                                    <div class="icon"><Check color="#fff" size={30} /></div>
                                    <div class="text colored">{this.props.langs.completed}</div>
                                 </div>
                                 :
                                 ''
                              }
                              {this.state.userType != 3 ?
                                 <div class="tableRow" onClick={() => this.createNewLectureFunc(item)}>
                                    <div class="icon"><Plus color="#577df7" size={30} /></div>
                                    <div class="text">{this.props.langs.createLecture}</div>
                                 </div>
                                 :
                                 ''
                              }
                              {this.state.userType != 3 ?
                                 <div class="tableRow" onClick={() => this.createNewAssignmentFunc(item)}>
                                    <div class="icon"><Plus color="#577df7" size={30} /></div>
                                    <div class="text">{this.props.langs.addAssignment}</div>
                                 </div>
                                 :
                                 ''
                              }
                              {this.state.userType != 3 ?
                                 <div class="tableRow" onClick={() => this.classGrade(item)}>
                                    <div class="icon"><Check color="#577df7" size={30} /></div>
                                    <div class="text">{this.props.langs.checkAssignments}</div>
                                 </div>
                                 :
                                 ''
                              }
                              {this.state.userType != 3 ?
                                 <div class="tableRow" onClick={() => this.activeAssignments(item)}>
                                    <div class="icon"><FileText color="#577df7" size={30} /></div>
                                    <div class="text">{this.props.langs.activeAssignments}</div>
                                 </div>
                                 :
                                 ''
                              }
                              <div class="tableRow" onClick={() => this.classFileList(item)}>
                                 <div class="icon"><Folder color="#577df7" size={30} /></div>
                                 <div class="text">{this.props.langs.manageFolder}</div>
                              </div>
                              <div class="tableRow" onClick={() => this.classDetail(item)}>
                                 <div class="icon"><Folder color="#577df7" size={30} /></div>
                                 <div class="text">{this.state.userType != 3 ? this.props.langs.manageStudents : this.props.langs.invatedUserList}</div>
                              </div>
                           </div>
                        </Col>
                        :
                        ''
                  )
                     :
                     <Col xl={12} className="noClass">{this.props.langs.noClassFound}</Col>
                  }
               </Row>
            }
            <Modal
               show={this.state.showDelete}
               onHide={this.handleCloseDelete}
               backdrop="static"
               aria-labelledby="contained-modal-title-vcenter"
               centered
               keyboard={false}
            >
               <Modal.Header>
                  <Modal.Title><InfoSquareFill color="#577df7" size={25} style={{ marginRight: "10px" }} />{this.props.langs.delete}</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  {this.props.langs.areYouSureDeleteClass}
               </Modal.Body>
               <Modal.Footer>
                  <Button variant="secondary" onClick={this.handleCloseDelete}>
                     {this.props.langs.cancel}
                  </Button>
                  <Button variant="danger" onClick={this.deleteClass}>{this.props.langs.delete}</Button>
               </Modal.Footer>
            </Modal>
         </Container>
      )
   }
}

export default Classes;