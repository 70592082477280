import React from 'react';
import './questions.scss'
import { Container, Col, Row, Form} from 'react-bootstrap';
import { postFormHandling } from '../../helpers/request'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Paragraph } from 'react-bootstrap-icons';

class Reading extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         data: '',
         numCount: 2,
         Title: '',
         ParagraphCopy: '',
         Paragraph: '',
         WordsData: [],
         WordsData2: [],
         isBlankMode: false
      };
    }

    componentDidMount () {
      // this.getImageFiles()
      if (this.props.location.state && this.props.location.state.edit) {
         this.getData(this.props.location.state.id)
      }
    }

    getData = (Qid) => {
      let data = {
         Type: "getFillInTheBlank",
         FileID: Qid
      }
      postFormHandling(data).then((res) => {
         console.log(res)
         this.fillData(res)
      })
   }

   fillData = (data) => {
      this.setState({Title: data.Data.Name})
      let str = data.Data.Paragraph.split(" ")
      let newStr = ''
      let count = 0
      for (let x = 0; x < str.length; x++) {
         if (str[x] === '[blank/]') {
            let word = data.Data.Words[count]
            newStr = newStr + word + ' '
            count++
         } else {
            newStr = newStr + str[x] + ' '
         }
      }
      this.setState({Paragraph: newStr})
      this.turnMode()
      for (let y = 0; y < data.Data.Words.length; y++) {
         let find = this.state.WordsData.find(item => item.text === data.Data.Words[y])
         if (find) {
            this.selectItem(find)
         }
      }
   }

   CreateCustomDataArray = () => {
       let splited = this.state.Paragraph.split(" ")
       let newArr = []
       if (splited && splited.length) {
          for (let x = 0;x < splited.length;x++) {
            let filtered = splited[x];
            // filtered = filtered.split(/[,.!]/)[0]
            if (filtered) {
               newArr.push({text: filtered, id: x, value: false})
            }
          }
       }
       this.setState({WordsData: newArr})
       let splited2 = this.state.Paragraph.split(" ")
       let newArr2 = []
       if (splited2 && splited2.length) {
          for (let x = 0;x < splited2.length;x++) {
            let filtered = splited2[x];
            // filtered = filtered.split(/[,.!]/)[0]
            if (filtered) {
               newArr2.push({text: filtered, id: x, value: false})
            }
          }
       }
       this.setState({WordsData2: newArr2})
   }

   selectItem = (item) => {
      let words = [...this.state.WordsData]
      let index = words.findIndex(word => word.id == item.id)
      let nowValue = words[index].value
      words[index].value = !nowValue
      this.setState({WordsData: words})
      let words2 = [...this.state.WordsData2]
      let index2 = words2.findIndex(word => word.id == item.id)
      let nowValue2 = words2[index2].value
      words2[index2].value = !nowValue2
      words2[index2].text = "[blank/]"
      let customText = ''
      for (let x = 0;x < words2.length; x++) {
        customText = customText + ' ' + words2[x].text
      }
      this.setState({ParagraphCopy: customText})
   }

    CrateNewFillBlankRequest = () => {
       let words = []
       if (this.state.WordsData && this.state.WordsData.length) {
         for (let x = 0;x < this.state.WordsData.length;x++) {
            if (this.state.WordsData[x].value == true) {
               words.push(this.state.WordsData[x].text)
            } 
         }
       }
       if (words && words.length) {
         let data = {}
         if (this.props.location.state && this.props.location.state.edit) {
            let obj = {
               Type:"updateFillInTheBlank",
               ID: localStorage.getItem('userID').replace(/['"]+/g, ''),
               FileID: this.props.location.state.id,
               Form: {
                  Title: this.state.Title,
                  Paragraph: this.state.ParagraphCopy,
                  Words: words
               }
            }
            data = obj
         } else {
            let obj = {
               Type:"createFillInTheBlank",
               ID: localStorage.getItem('userID').replace(/['"]+/g, ''),
               LibraryID: localStorage.getItem('FList'),
               Form: {
                  Title: this.state.Title,
                  Paragraph: this.state.ParagraphCopy,
                  Words: words
               }
            }
            data = obj
         }
         postFormHandling(data).then(() => {
            toast.success('Fill In The Blanks successfuly created', {
               position: "top-right",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               limit: 3,
               progress: undefined,
            });
            this.props.history.push("/files")
         })
      } else {
         this.sendError('Please Add Blank Area')
      }
   }

    handleTitle = (e) => {
      this.setState({Title: e.target.value})
    }

    handleP = (e) => {
       this.setState({Paragraph: e.target.value})
    }

    turnMode = () => {
      this.CreateCustomDataArray()
      this.setState({isBlankMode: !this.state.isBlankMode})
    }

    sendError = (msg) => {
      if (msg) {
         toast.warn(msg, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            limit: 3,
            progress: undefined,
         })
      } else {
         toast.warn('Please Fill Reading Text', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            limit: 3,
            progress: undefined,
         })
      }
   }
   render () {
      const { Paragraph, Title } = this.state
      const valid =  Paragraph && Paragraph.length && Title && Title.length
      return (
         <Container>
            <Row className="labelBar justify-content-md-center">
               <Col className="label" xl={12}>
                  <Row style={{width: '100%'}}>
                     <Col xl={3} className="labelMain">Fill In The Blank </Col>
                     <Col xl={4} className="titleBox">
                        <span className="title">Title:</span>
                        <input type="text" name="name" value={this.state.Title} onChange={this.handleTitle} placeholder="Reading Title"/>
                     </Col>
                     {/* <Col xl={4} className="titleBox">
                        <span className="title">Time:</span>
                        <input type="text" name="name" placeholder="Question Name"/>
                     </Col> */}
                  </Row>
               </Col>
            </Row>
            <Row className="pageRow">
               <Col xl={12}>
                  <div class="settingBox">
                     <Col xl={12} className="questionLabel">
                        <Row style={{paddingRight: '10px'}}>
                           <Col xl={10}>   
                              <Col xl={10}>Fill In The Blank Text</Col>
                              <Col className="desc" xl={10}>Please choose the blank words by clicking the "Choose blank words" button and clicking on the words.</Col>
                           </Col>
                           <Col xl={2} className="addBlank" onClick={this.turnMode}>{this.state.isBlankMode ? 'edit paragraph' : 'Choose blank words'}</Col>
                        </Row>
                     </Col>
                     <Col xl={12} className="questionBox">
                        <Row>
                           {this.state.isBlankMode ? 
                              <Col className="addBlankBox" xl={12}><p>{this.state.WordsData && this.state.WordsData.map(item => <span class={item.value ? 'selectedSpan' : 'blankSpan'} onClick={() => this.selectItem(item)}>{item.text}</span>)}</p></Col>
                              :
                              <Col className="inputBox textArea" xl={12}><Form.Control name="Paragraph" value={this.state.Paragraph} onChange={(e) => this.handleP(e)} as="textarea" className="textAreaClass" rows={3} /></Col>
                           }
                        </Row>
                     </Col>
                     <Col xl={12} className="addQueButton" onClick={valid ? this.CrateNewFillBlankRequest : this.sendError}>Save</Col>
                  </div>
               </Col>
            </Row>
         </Container>
      )
   }
}

export default Reading;