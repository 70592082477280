import React from 'react';
import './mainPage.scss'
import { Container, Col, Row } from 'react-bootstrap';
import vrChatİllu from '../../svg/vrChatİllu.svg'
import friends from '../../svg/friends.svg'
import breaking from '../../svg/breakingWalls.svg'
import { Link } from 'react-router-dom'
import teacher from '../../svg/teacherTeaching.svg'
import { Joystick, LightningFill, PeopleFill, Person } from 'react-bootstrap-icons';

class Terms extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         data: '',
      };
    }

    componentDidMount = () => {
    }

   render () {
      return (
         <Container fluid className="containerFull" >
            <Col xl={12} className="header">TERMS & CONDITIONS</Col>
            <Col xl={12} className="description">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</Col>
            <Col xl={12} className="bottomCopyright">Copyright &copy; 2021 LANGUAGE VR Corporation, All Rights Reserved</Col>
         </Container>
      )
   }
}

export default Terms;