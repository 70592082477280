import React from 'react';
import userBlankIcon from '../../img/ProfilePH.png';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './classes.scss'
import { Modal, Container, Button, Dropdown, Col, Row, Table, Form, FormControl } from 'react-bootstrap';
import { PlusSquare, Hourglass } from 'react-bootstrap-icons';
import { postActions } from '../../helpers/request'

class ClassDetail extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         data: '',
         show: false,
         setShow: false,
         results: [],
         userList: []
      };
   }

   handleClose = () => {
      this.setState({ show: false })
   }

   handleOpen = () => {
      this.setState({ show: true })
   }

   removeUserFromClass = (item) => {
      let data = {
         Action: "removeStudentFromClass",
         UserID: localStorage.getItem('userID').replace(/['"]+/g, ''),
         ClassID: this.props.location.state.ID,
         StudentID: item.StudentID
      }
      postActions(data).then((response) => {
         toast.success('Student successfuly removed from class', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            limit: 3,
            progress: undefined,
         });
         this.getStudentsForClass()
      })
   }

   searchUser = (e) => {
      if (e.target.value && e.target.value.length > 2) {
         let data = {
            Action: "searchForStudent",
            UserID: localStorage.getItem('userID').replace(/['"]+/g, ''),
            SearchText: e.target.value
         }
         postActions(data).then((response) => {
            this.setState({ result: response.Data })
         })
      } else {
         this.setState({ result: '' })
      }
   }

   goToProfile = (user) => {
      this.props.history.push({
         pathname: "/profile",
         state: user.StudentID
      })
   }

   goToChat = (user) => {
      this.props.history.push({
         pathname: "/messages",
         state: user
      })
   }

   sendAddClassReq = (item) => {
      let data = {
         Action: "addStudentToClass",
         UserID: localStorage.getItem('userID').replace(/['"]+/g, ''),
         ClassID: this.props.location.state.ID,
         StudentID: item.ID
      }
      postActions(data).then(() => {
         toast.success(localStorage.getItem('type').replace(/['"]+/g, '') === '3' ? 'User Invated' : 'Student Class Join Request Sent', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            limit: 3,
            progress: undefined,
         });
         let newData = {
            FullName: item.Name,
            ID: item.ID,
            Status: "1"
         }
         let list = []
         if (this.state.userList) {
            list = this.state.userList
         }
         list.push(newData)
         this.setState({ userList: list })
      })
   }

   componentDidMount() {
      this.getStudentsForClass()
   }

   goToGrades = (item) => {
      let data = {
         user: item,
         classId: this.props.location.state.ID
      }
      this.props.history.push({
         pathname: "/learnerClasses",
         state: data
      })
   }

   getStudentsForClass = () => {
      let data = {
         Action: "getStudentsFromClass",
         ClassID: this.props.location.state.ID
      }
      postActions(data).then((response) => {
         this.setState({ userList: response.Students })
      })
   }

   render() {
      return (
         <Container>
            <Row className="labelBar justify-content-md-center">
               <Col className="label" xl={12}>
                  <div class="labelMain">{localStorage.getItem('type').replace(/['"]+/g, '') === '3' ? this.props.langs.invatedUserList : this.props.langs.classDetail}</div>
                  {/* <div class="newButtonMain" onClick={this.handleOpen}><PlusSquare color="#577df7" size={25}/><span class="buttonText">Add Student</span></div> */}
               </Col>
            </Row>
            <Row className="pageRow">
               <Col xl={12}>
                  <div class="settingBox">
                     <Row className="topBarDiv">
                        <Col xl={9} className="filesLabel">{this.props.history.location.state && this.props.history.location.state.Name}</Col>
                        <Col xl={3} className="searchBarDiv">
                           <Form className="d-flex">
                              <Dropdown className="dropdownSearchOutside">
                                 <Dropdown.Toggle id="dropdown-button-dark-example1" className="dropdownSearch" variant="secondary">
                                    {localStorage.getItem('type').replace(/['"]+/g, '') === '3' ? this.props.langs.userList : this.props.langs.addStudent}
                                 </Dropdown.Toggle>
                                 <Dropdown.Menu variant="dark">
                                    <Form onSubmit={e => { e.preventDefault(); }}>
                                       <FormControl
                                          type="text"
                                          placeholder="search..."
                                          className="searchBar"
                                          aria-label="text"
                                          onChange={this.searchUser}
                                          onKeyDown={e => { if (e.key === 'Enter') e.preventDefault(); }}
                                          onKeyDownCapture={e => { if (e.key === 'Enter') e.preventDefault(); }}
                                          onKeyPress={e => { if (e.key === 'Enter') e.preventDefault(); }}
                                       />
                                    </Form>
                                    {this.state.result && this.state.result.map(item =>
                                       <Dropdown.Item onClick={() => this.sendAddClassReq(item)} key={item.ID}>{item.Name}</Dropdown.Item>
                                    )}
                                 </Dropdown.Menu>
                              </Dropdown>
                           </Form>
                        </Col>
                     </Row>
                     <Table striped bordered hover>
                        <thead>
                           <tr class="customTableRowLabel">
                              {/* <th>User Image</th> */}
                              <th>{this.props.langs.name}</th>
                              <th class="actions">{this.props.langs.actions}</th>
                           </tr>
                        </thead>
                        <tbody>
                           {this.state.userList && this.state.userList.map(item =>
                              item.Status != '4' ? (
                                 <tr class="customTableRow" key={item.ID}>
                                    {/* <td class="userImageClass"><img width="40px" src={userBlankIcon} /></td> */}
                                    <td>{item.FullName}</td>
                                    {item.Status == '2' ?
                                       <td class="actions">
                                          <Dropdown>
                                             <Dropdown.Toggle id="dropdown-button-dark-example1" className="dropdownActions" variant="secondary">
                                             </Dropdown.Toggle>
                                             <Dropdown.Menu variant="dark">
                                                <Dropdown.Item onClick={() => this.removeUserFromClass(item)}>{this.props.langs.remove}</Dropdown.Item>
                                                {localStorage.getItem('type').replace(/['"]+/g, '') === '3' ? '' : <Dropdown.Item onClick={() => this.goToGrades(item)}>{this.props.langs.viewGrades}</Dropdown.Item>}
                                                {/* <Dropdown.Item>{this.props.langs.checkAssignments}</Dropdown.Item> */}
                                                <Dropdown.Item onClick={() => this.goToProfile(item)}>{this.props.langs.viewProfile}</Dropdown.Item>
                                                {/* <Dropdown.Item>View Files</Dropdown.Item> */}
                                                {/* <Dropdown.Item onClick={() => this.goToChat(item)}>{this.props.langs.openChat}</Dropdown.Item> */}
                                                {/* <Dropdown.Item>Edit</Dropdown.Item>
                                       <Dropdown.Item>Create Assignment</Dropdown.Item>
                                       <Dropdown.Item>Add to Class Folder</Dropdown.Item>
                                       <Dropdown.Item>Copy to Shared Lib</Dropdown.Item>
                                       <Dropdown.Item>Donwload / View</Dropdown.Item>
                                    <Dropdown.Item>Delete</Dropdown.Item> */}
                                             </Dropdown.Menu>
                                          </Dropdown>
                                       </td>
                                       :
                                       <td class="actions">
                                          <Button disabled className="pendingUser"><Hourglass color="#fff" size={18} />{this.props.langs.pending}</Button>
                                       </td>
                                    }
                                 </tr>
                              ) : '')}
                        </tbody>
                     </Table>
                  </div>
               </Col>
            </Row>
            <Modal
               show={this.state.show}
               onHide={this.handleClose}
               backdrop="static"
               aria-labelledby="contained-modal-title-vcenter"
               centered
               keyboard={false}
            >
               <Modal.Header>
                  <Modal.Title><PlusSquare color="#577df7" size={25} style={{ marginRight: "10px" }} />{this.props.new}</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  test
               </Modal.Body>
               <Modal.Footer>
                  <Button variant="secondary" onClick={this.handleClose}>
                     Close
                  </Button>
                  <Button variant="primary">Continue</Button>
               </Modal.Footer>
            </Modal>
         </Container>
      )
   }
}

export default ClassDetail;