import React from 'react';
import userBlankIcon from '../../img/ProfilePH.png';
import './grades.scss'
import {Navbar,Container,Nav,NavDropdown, Button, Dropdown, Col, Row} from 'react-bootstrap';
import { Check, Folder, Plus } from 'react-bootstrap-icons';
import { postActions } from '../../helpers/request';

class Grades extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         userGrades: '',
         userScores: []
      };
    }
    componentDidMount () {
       this.getGrades()
    }

    getGrades = () => {
      let data = {
         Action:"getScores",
         UserID:localStorage.getItem('userID').replace(/['"]+/g, ''),
         LearnerID: this.props.location.state && this.props.location.state.UserID ? this.props.location.state.UserID : localStorage.getItem('userID').replace(/['"]+/g, ''),
         // ClassID: this.props.location.state.classId
      }
      postActions(data).then((response) => {
         this.setState({userScores: response})
      })
    }
   render () {
      const { userGrades, userScores } = this.state
      return (
         <Container>
            {userScores && userScores.Classes && userScores.Classes.map(item =>
            item.ClassID === this.props.location.state.ClassID ?
               <div style={{marginTop: '20px'}}>
                  <Row className="labelBar">
                     <Col className="lectureLabel" xl={6}>{item.ClassName}</Col>
                     <Col className="lectureAverage" xl={6}>Current Average {item.Overall.Score}</Col>
                  </Row>
                  <Row className="pageRow">
                     <Col xl={3}>
                        <div class="gradesBox">
                           <div class="topLabel">Attendance</div>
                           <div class="last_nextClasss">Next Class: 07-20-2021</div>
                           {item.Attendance.map(item => 
                              <div class="tableRow">
                                 <div class="lectureName">{item.Name}</div>
                                 <div class="grade">{item.Grade}</div>
                              </div>
                              )}
                        </div>
                     </Col>
                     <Col xl={3}>
                        <div class="gradesBox">
                           <div class="topLabel">Drama</div>
                           <div class="last_nextClasss">Next Class: 07-20-2021</div>
                           {item.Drama.map(item => 
                              <div class="tableRow">
                                 <div class="lectureName">{item.Name}</div>
                                 <div class="grade">{item.Grade}</div>
                              </div>
                           )}
                        </div>
                     </Col>
                     <Col xl={3}>
                        <div class="gradesBox">
                           <div class="topLabel">Quizzes</div>
                           <div class="last_nextClasss">Next Class: 07-20-2021</div>
                           {item.Quizzes.map(item => 
                              <div class="tableRow">
                                 <div class="lectureName">{item.Name}</div>
                                 <div class="grade">{item.Grade}</div>
                              </div>
                              )}
                        </div>
                     </Col>
                     <Col xl={3}>
                        <div class="gradesBox">
                           <div class="topLabel">Tutor Points</div>
                           <div class="last_nextClasss">Next Class: 07-20-2021</div>
                           {item.TutorPoints.map(item => 
                              <div class="tableRow">
                                 <div class="lectureName">{item.Name}</div>
                                 <div class="grade">{item.Grade}</div>
                              </div>
                              )}
                        </div>
                     </Col>
                  </Row>
               </div>
               : 
               ''
            )}
         </Container>
      )
   }
}

export default Grades;