import React from 'react';
import './profile.scss'
import { Container, Col, Row, Form, Modal, Button } from 'react-bootstrap';
import { HeadsetVr, Key } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom'
import { postActions, postSocial } from '../../helpers/request'
import Multiselect from 'multiselect-react-dropdown';


class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: null,
      time: 60,
      isSelf: false,
      isFriend: false,
      editMode: false,
      show: false,
      showPassChange: false,
      pairingCode: '',
      NativeLanguage: "",
      Program: "",
      Country: "",
      Bio: "",
      Organization: "",
      Patreon: "",
      file: null,
      selectedCountry: '',
      selectedGender: '',
      selectedLangs: [],
      selectedUiLang: '',
      newPassword: '',
      reNewPassword: '',
      oldPassword: '',
      genderList: [
        { name: 'Male', num: '1' },
        { name: 'Female', num: '2' }
      ],
      langList: [
        { name: 'English', code: 'en' },
        { name: 'Turkish', code: 'tr' }
      ],
      languageList: [
        { name: 'Abkhazian' },
        { name: 'Afar' },
        { name: 'Afrikaans' },
        { name: 'Akan' },
        { name: 'Albanian' },
        { name: 'Amharic' },
        { name: 'Arabic' },
        { name: 'Aragonese' },
        { name: 'Armenian' },
        { name: 'Assamese' },
        { name: 'Avaric' },
        { name: 'Avestan' },
        { name: 'Aymara' },
        { name: 'Azerbaijani' },
        { name: 'Bambara' },
        { name: 'Bashkir' },
        { name: 'Basque' },
        { name: 'Belarusian' },
        { name: 'Bengali' },
        { name: 'Bihari languages' },
        { name: 'Bislama' },
        { name: 'Bosnian' },
        { name: 'Breton' },
        { name: 'Bulgarian' },
        { name: 'Burmese' },
        { name: 'Catalan, Valencian' },
        { name: 'Central Khmer' },
        { name: 'Chamorro' },
        { name: 'Chechen' },
        { name: 'Chichewa, Chewa, Nyanja' },
        { name: 'Chinese' },
        { name: 'Church Slavonic, Old Bulgarian, Old Church Slavonic' },
        { name: 'Chuvash' },
        { name: 'Cornish' },
        { name: 'Corsican' },
        { name: 'Cree' },
        { name: 'Croatian' },
        { name: 'Czech' },
        { name: 'Danish' },
        { name: 'Divehi, Dhivehi, Maldivian' },
        { name: 'Dutch, Flemish' },
        { name: 'Dzongkha' },
        { name: 'English' },
        { name: 'Esperanto' },
        { name: 'Estonian' },
        { name: 'Ewe' },
        { name: 'Faroese' },
        { name: 'Fijian' },
        { name: 'Finnish' },
        { name: 'French' },
        { name: 'Fulah' },
        { name: 'Gaelic, Scottish Gaelic' },
        { name: 'Galician' },
        { name: 'Ganda' },
        { name: 'Georgian' },
        { name: 'German' },
        { name: 'Gikuyu, Kikuyu' },
        { name: 'Greek (Modern)' },
        { name: 'Greenlandic, Kalaallisut' },
        { name: 'Guarani' },
        { name: 'Gujarati' },
        { name: 'Haitian, Haitian Creole' },
        { name: 'Hausa' },
        { name: 'Hebrew' },
        { name: 'Herero' },
        { name: 'Hindi' },
        { name: 'Hiri Motu' },
        { name: 'Hungarian' },
        { name: 'Icelandic' },
        { name: 'Ido' },
        { name: 'Igbo' },
        { name: 'Indonesian' },
        { name: 'Interlingua (International Auxiliary Language Association)' },
        { name: 'Interlingue' },
        { name: 'Inuktitut' },
        { name: 'Inupiaq' },
        { name: 'Irish' },
        { name: 'Italian' },
        { name: 'Japanese' },
        { name: 'Javanese' },
        { name: 'Kannada' },
        { name: 'Kanuri' },
        { name: 'Kashmiri' },
        { name: 'Kazakh' },
        { name: 'Kinyarwanda' },
        { name: 'Komi' },
        { name: 'Kongo' },
        { name: 'Korean' },
        { name: 'Kwanyama, Kuanyama' },
        { name: 'Kurdish' },
        { name: 'Kyrgyz' },
        { name: 'Lao' },
        { name: 'Latin' },
        { name: 'Latvian' },
        { name: 'Letzeburgesch, Luxembourgish' },
        { name: 'Limburgish, Limburgan, Limburger' },
        { name: 'Lingala' },
        { name: 'Lithuanian' },
        { name: 'Luba-Katanga' },
        { name: 'Macedonian' },
        { name: 'Malagasy' },
        { name: 'Malay' },
        { name: 'Malayalam' },
        { name: 'Maltese' },
        { name: 'Manx' },
        { name: 'Maori' },
        { name: 'Marathi' },
        { name: 'Marshallese' },
        { name: 'Moldovan, Moldavian, Romanian' },
        { name: 'Mongolian' },
        { name: 'Nauru' },
        { name: 'Navajo, Navaho' },
        { name: 'Northern Ndebele' },
        { name: 'Ndonga' },
        { name: 'Nepali' },
        { name: 'Northern Sami' },
        { name: 'Norwegian' },
        { name: 'Norwegian Bokmål' },
        { name: 'Norwegian Nynorsk' },
        { name: 'Nuosu, Sichuan Yi' },
        { name: 'Occitan (post 1500)' },
        { name: 'Ojibwa' },
        { name: 'Oriya' },
        { name: 'Oromo' },
        { name: 'Ossetian, Ossetic' },
        { name: 'Pali' },
        { name: 'Panjabi, Punjabi' },
        { name: 'Pashto, Pushto' },
        { name: 'Persian' },
        { name: 'Polish' },
        { name: 'Portuguese' },
        { name: 'Quechua' },
        { name: 'Romansh' },
        { name: 'Rundi' },
        { name: 'Russian' },
        { name: 'Samoan' },
        { name: 'Sango' },
        { name: 'Sanskrit' },
        { name: 'Sardinian' },
        { name: 'Serbian' },
        { name: 'Shona' },
        { name: 'Sindhi' },
        { name: 'Sinhala, Sinhalese' },
        { name: 'Slovak' },
        { name: 'Slovenian' },
        { name: 'Somali' },
        { name: 'Sotho, Southern' },
        { name: 'South Ndebele' },
        { name: 'Spanish, Castilian' },
        { name: 'Sundanese' },
        { name: 'Swahili' },
        { name: 'Swati' },
        { name: 'Swedish' },
        { name: 'Tagalog' },
        { name: 'Tahitian' },
        { name: 'Tajik' },
        { name: 'Tamil' },
        { name: 'Tatar' },
        { name: 'Telugu' },
        { name: 'Thai' },
        { name: 'Tibetan' },
        { name: 'Tigrinya' },
        { name: 'Tonga (Tonga Islands)' },
        { name: 'Tsonga' },
        { name: 'Tswana' },
        { name: 'Turkish' },
        { name: 'Turkmen' },
        { name: 'Twi' },
        { name: 'Uighur, Uyghur' },
        { name: 'Ukrainian' },
        { name: 'Urdu' },
        { name: 'Uzbek' },
        { name: 'Venda' },
        { name: 'Vietnamese' },
        { name: 'Volap_k' },
        { name: 'Walloon' },
        { name: 'Welsh' },
        { name: 'Western Frisian' },
        { name: 'Wolof' },
        { name: 'Xhosa' },
        { name: 'Yiddish' },
        { name: 'Yoruba' },
        { name: 'Zhuang, Chuang' },
        { name: 'Zulu' }
      ],
      countryList: [
        { name: 'Afghanistan', code: 'AF' },
        { name: 'Åland Islands', code: 'AX' },
        { name: 'Albania', code: 'AL' },
        { name: 'Algeria', code: 'DZ' },
        { name: 'American Samoa', code: 'AS' },
        { name: 'AndorrA', code: 'AD' },
        { name: 'Angola', code: 'AO' },
        { name: 'Anguilla', code: 'AI' },
        { name: 'Antarctica', code: 'AQ' },
        { name: 'Antigua and Barbuda', code: 'AG' },
        { name: 'Argentina', code: 'AR' },
        { name: 'Armenia', code: 'AM' },
        { name: 'Aruba', code: 'AW' },
        { name: 'Australia', code: 'AU' },
        { name: 'Austria', code: 'AT' },
        { name: 'Azerbaijan', code: 'AZ' },
        { name: 'Bahamas', code: 'BS' },
        { name: 'Bahrain', code: 'BH' },
        { name: 'Bangladesh', code: 'BD' },
        { name: 'Barbados', code: 'BB' },
        { name: 'Belarus', code: 'BY' },
        { name: 'Belgium', code: 'BE' },
        { name: 'Belize', code: 'BZ' },
        { name: 'Benin', code: 'BJ' },
        { name: 'Bermuda', code: 'BM' },
        { name: 'Bhutan', code: 'BT' },
        { name: 'Bolivia', code: 'BO' },
        { name: 'Bosnia and Herzegovina', code: 'BA' },
        { name: 'Botswana', code: 'BW' },
        { name: 'Bouvet Island', code: 'BV' },
        { name: 'Brazil', code: 'BR' },
        { name: 'British Indian Ocean Territory', code: 'IO' },
        { name: 'Brunei Darussalam', code: 'BN' },
        { name: 'Bulgaria', code: 'BG' },
        { name: 'Burkina Faso', code: 'BF' },
        { name: 'Burundi', code: 'BI' },
        { name: 'Cambodia', code: 'KH' },
        { name: 'Cameroon', code: 'CM' },
        { name: 'Canada', code: 'CA' },
        { name: 'Cape Verde', code: 'CV' },
        { name: 'Cayman Islands', code: 'KY' },
        { name: 'Central African Republic', code: 'CF' },
        { name: 'Chad', code: 'TD' },
        { name: 'Chile', code: 'CL' },
        { name: 'China', code: 'CN' },
        { name: 'Christmas Island', code: 'CX' },
        { name: 'Cocos (Keeling) Islands', code: 'CC' },
        { name: 'Colombia', code: 'CO' },
        { name: 'Comoros', code: 'KM' },
        { name: 'Congo', code: 'CG' },
        { name: 'Congo, The Democratic Republic of the', code: 'CD' },
        { name: 'Cook Islands', code: 'CK' },
        { name: 'Costa Rica', code: 'CR' },
        { name: 'Cote D\'Ivoire', code: 'CI' },
        { name: 'Croatia', code: 'HR' },
        { name: 'Cuba', code: 'CU' },
        { name: 'Cyprus', code: 'CY' },
        { name: 'Czech Republic', code: 'CZ' },
        { name: 'Denmark', code: 'DK' },
        { name: 'Djibouti', code: 'DJ' },
        { name: 'Dominica', code: 'DM' },
        { name: 'Dominican Republic', code: 'DO' },
        { name: 'Ecuador', code: 'EC' },
        { name: 'Egypt', code: 'EG' },
        { name: 'El Salvador', code: 'SV' },
        { name: 'Equatorial Guinea', code: 'GQ' },
        { name: 'Eritrea', code: 'ER' },
        { name: 'Estonia', code: 'EE' },
        { name: 'Ethiopia', code: 'ET' },
        { name: 'Falkland Islands (Malvinas)', code: 'FK' },
        { name: 'Faroe Islands', code: 'FO' },
        { name: 'Fiji', code: 'FJ' },
        { name: 'Finland', code: 'FI' },
        { name: 'France', code: 'FR' },
        { name: 'French Guiana', code: 'GF' },
        { name: 'French Polynesia', code: 'PF' },
        { name: 'French Southern Territories', code: 'TF' },
        { name: 'Gabon', code: 'GA' },
        { name: 'Gambia', code: 'GM' },
        { name: 'Georgia', code: 'GE' },
        { name: 'Germany', code: 'DE' },
        { name: 'Ghana', code: 'GH' },
        { name: 'Gibraltar', code: 'GI' },
        { name: 'Greece', code: 'GR' },
        { name: 'Greenland', code: 'GL' },
        { name: 'Grenada', code: 'GD' },
        { name: 'Guadeloupe', code: 'GP' },
        { name: 'Guam', code: 'GU' },
        { name: 'Guatemala', code: 'GT' },
        { name: 'Guernsey', code: 'GG' },
        { name: 'Guinea', code: 'GN' },
        { name: 'Guinea-Bissau', code: 'GW' },
        { name: 'Guyana', code: 'GY' },
        { name: 'Haiti', code: 'HT' },
        { name: 'Heard Island and Mcdonald Islands', code: 'HM' },
        { name: 'Holy See (Vatican City State)', code: 'VA' },
        { name: 'Honduras', code: 'HN' },
        { name: 'Hong Kong', code: 'HK' },
        { name: 'Hungary', code: 'HU' },
        { name: 'Iceland', code: 'IS' },
        { name: 'India', code: 'IN' },
        { name: 'Indonesia', code: 'ID' },
        { name: 'Iran, Islamic Republic Of', code: 'IR' },
        { name: 'Iraq', code: 'IQ' },
        { name: 'Ireland', code: 'IE' },
        { name: 'Isle of Man', code: 'IM' },
        { name: 'Israel', code: 'IL' },
        { name: 'Italy', code: 'IT' },
        { name: 'Jamaica', code: 'JM' },
        { name: 'Japan', code: 'JP' },
        { name: 'Jersey', code: 'JE' },
        { name: 'Jordan', code: 'JO' },
        { name: 'Kazakhstan', code: 'KZ' },
        { name: 'Kenya', code: 'KE' },
        { name: 'Kiribati', code: 'KI' },
        { name: 'Korea, Democratic People\'S Republic of', code: 'KP' },
        { name: 'Korea, Republic of', code: 'KR' },
        { name: 'Kuwait', code: 'KW' },
        { name: 'Kyrgyzstan', code: 'KG' },
        { name: 'Lao People\'S Democratic Republic', code: 'LA' },
        { name: 'Latvia', code: 'LV' },
        { name: 'Lebanon', code: 'LB' },
        { name: 'Lesotho', code: 'LS' },
        { name: 'Liberia', code: 'LR' },
        { name: 'Libyan Arab Jamahiriya', code: 'LY' },
        { name: 'Liechtenstein', code: 'LI' },
        { name: 'Lithuania', code: 'LT' },
        { name: 'Luxembourg', code: 'LU' },
        { name: 'Macao', code: 'MO' },
        { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
        { name: 'Madagascar', code: 'MG' },
        { name: 'Malawi', code: 'MW' },
        { name: 'Malaysia', code: 'MY' },
        { name: 'Maldives', code: 'MV' },
        { name: 'Mali', code: 'ML' },
        { name: 'Malta', code: 'MT' },
        { name: 'Marshall Islands', code: 'MH' },
        { name: 'Martinique', code: 'MQ' },
        { name: 'Mauritania', code: 'MR' },
        { name: 'Mauritius', code: 'MU' },
        { name: 'Mayotte', code: 'YT' },
        { name: 'Mexico', code: 'MX' },
        { name: 'Micronesia, Federated States of', code: 'FM' },
        { name: 'Moldova, Republic of', code: 'MD' },
        { name: 'Monaco', code: 'MC' },
        { name: 'Mongolia', code: 'MN' },
        { name: 'Montserrat', code: 'MS' },
        { name: 'Morocco', code: 'MA' },
        { name: 'Mozambique', code: 'MZ' },
        { name: 'Myanmar', code: 'MM' },
        { name: 'Namibia', code: 'NA' },
        { name: 'Nauru', code: 'NR' },
        { name: 'Nepal', code: 'NP' },
        { name: 'Netherlands', code: 'NL' },
        { name: 'Netherlands Antilles', code: 'AN' },
        { name: 'New Caledonia', code: 'NC' },
        { name: 'New Zealand', code: 'NZ' },
        { name: 'Nicaragua', code: 'NI' },
        { name: 'Niger', code: 'NE' },
        { name: 'Nigeria', code: 'NG' },
        { name: 'Niue', code: 'NU' },
        { name: 'Norfolk Island', code: 'NF' },
        { name: 'Northern Mariana Islands', code: 'MP' },
        { name: 'Norway', code: 'NO' },
        { name: 'Oman', code: 'OM' },
        { name: 'Pakistan', code: 'PK' },
        { name: 'Palau', code: 'PW' },
        { name: 'Palestinian Territory, Occupied', code: 'PS' },
        { name: 'Panama', code: 'PA' },
        { name: 'Papua New Guinea', code: 'PG' },
        { name: 'Paraguay', code: 'PY' },
        { name: 'Peru', code: 'PE' },
        { name: 'Philippines', code: 'PH' },
        { name: 'Pitcairn', code: 'PN' },
        { name: 'Poland', code: 'PL' },
        { name: 'Portugal', code: 'PT' },
        { name: 'Puerto Rico', code: 'PR' },
        { name: 'Qatar', code: 'QA' },
        { name: 'Reunion', code: 'RE' },
        { name: 'Romania', code: 'RO' },
        { name: 'Russian Federation', code: 'RU' },
        { name: 'RWANDA', code: 'RW' },
        { name: 'Saint Helena', code: 'SH' },
        { name: 'Saint Kitts and Nevis', code: 'KN' },
        { name: 'Saint Lucia', code: 'LC' },
        { name: 'Saint Pierre and Miquelon', code: 'PM' },
        { name: 'Saint Vincent and the Grenadines', code: 'VC' },
        { name: 'Samoa', code: 'WS' },
        { name: 'San Marino', code: 'SM' },
        { name: 'Sao Tome and Principe', code: 'ST' },
        { name: 'Saudi Arabia', code: 'SA' },
        { name: 'Senegal', code: 'SN' },
        { name: 'Serbia and Montenegro', code: 'CS' },
        { name: 'Seychelles', code: 'SC' },
        { name: 'Sierra Leone', code: 'SL' },
        { name: 'Singapore', code: 'SG' },
        { name: 'Slovakia', code: 'SK' },
        { name: 'Slovenia', code: 'SI' },
        { name: 'Solomon Islands', code: 'SB' },
        { name: 'Somalia', code: 'SO' },
        { name: 'South Africa', code: 'ZA' },
        { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
        { name: 'Spain', code: 'ES' },
        { name: 'Sri Lanka', code: 'LK' },
        { name: 'Sudan', code: 'SD' },
        { name: 'Suriname', code: 'SR' },
        { name: 'Svalbard and Jan Mayen', code: 'SJ' },
        { name: 'Swaziland', code: 'SZ' },
        { name: 'Sweden', code: 'SE' },
        { name: 'Switzerland', code: 'CH' },
        { name: 'Syrian Arab Republic', code: 'SY' },
        { name: 'Taiwan, Province of China', code: 'TW' },
        { name: 'Tajikistan', code: 'TJ' },
        { name: 'Tanzania, United Republic of', code: 'TZ' },
        { name: 'Thailand', code: 'TH' },
        { name: 'Timor-Leste', code: 'TL' },
        { name: 'Togo', code: 'TG' },
        { name: 'Tokelau', code: 'TK' },
        { name: 'Tonga', code: 'TO' },
        { name: 'Trinidad and Tobago', code: 'TT' },
        { name: 'Tunisia', code: 'TN' },
        { name: 'Turkey', code: 'TR' },
        { name: 'Turkmenistan', code: 'TM' },
        { name: 'Turks and Caicos Islands', code: 'TC' },
        { name: 'Tuvalu', code: 'TV' },
        { name: 'Uganda', code: 'UG' },
        { name: 'Ukraine', code: 'UA' },
        { name: 'United Arab Emirates', code: 'AE' },
        { name: 'United Kingdom', code: 'GB' },
        { name: 'United States', code: 'US' },
        { name: 'United States Minor Outlying Islands', code: 'UM' },
        { name: 'Uruguay', code: 'UY' },
        { name: 'Uzbekistan', code: 'UZ' },
        { name: 'Vanuatu', code: 'VU' },
        { name: 'Venezuela', code: 'VE' },
        { name: 'Viet Nam', code: 'VN' },
        { name: 'Virgin Islands, British', code: 'VG' },
        { name: 'Virgin Islands, U.S.', code: 'VI' },
        { name: 'Wallis and Futuna', code: 'WF' },
        { name: 'Western Sahara', code: 'EH' },
        { name: 'Yemen', code: 'YE' },
        { name: 'Zambia', code: 'ZM' },
        { name: 'Zimbabwe', code: 'ZW' }
      ]
    };
  }

  handleClose = () => {
    this.setState({ show: false })
  }

  handleOpen = () => {
    postActions({ Action: "getPairingKey", userID: localStorage.getItem('userID').replace(/['"]+/g, '') }).then((res) => {
      this.setState({ show: true })
      this.startTimer()
      this.setState({ pairingCode: res.Code })
    })
  }

  handleOpenPass = () => {
    this.setState({ showPassChange: true })
  }
  handleClosePass = () => {
    this.setState({ showPassChange: false })
  }

  componentDidMount() {
    if (!this.props.location.state) {
      this.setState({ isSelf: true })
    } else {
      this.setState({ isSelf: false })
    }
    this.getProfile()
  }

  getProfile = () => {
    let userID = localStorage.getItem('userID').replace(/['"]+/g, '')
    let profileId = this.props.location.state ? this.props.location.state : localStorage.getItem('userID').replace(/['"]+/g, '')
    let data = {
      "Action": "getUserProfile",
      "UserID": userID,//User ID
      "ProfileID": profileId//"ID of the requested profile page user"
    }
    postActions(data).then((response) => {
      let langs = []
      if (response.UserData.NativeLanguage) {
        let lang = response.UserData.NativeLanguage.split(',')
        lang.forEach(item => {
          langs.push({ name: item })
        })
      }
      this.setState({ userData: response.UserData })
      this.setState({ selectedLangs: langs })
      this.setState({ selectedGender: response.UserData.Gender })
      this.setState({ Program: response.UserData.Program })
      this.setState({ selectedCountry: response.UserData.Country })
      this.setState({ Bio: response.UserData.Bio })
      this.setState({ Organization: response.UserData.EntityCode })
      this.setState({ Patreon: response.UserData.Patreon })
      this.setState({ isFriend: response.UserData.Friend })
      if (userID === profileId) {
        localStorage.setItem("userPic", response.UserData.ProfilePic)
      }
    })
  }

  sendFriend = () => {
    const data = new FormData();
    const userId = localStorage.getItem("userID").replace(/['"]+/g, '')
    data.append("UserID", userId);
    data.append("FriendID", this.props.location.state);
    data.append("Action", "addFriend");
    postSocial(data).then((response) => {
      toast.success('Friend Request Sent', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        limit: 3,
        progress: undefined,
      });
    })
  }

  countrySelect = (e) => {
    this.setState({ selectedCountry: e.target.value })
  }

  genderSelect = (e) => {
    this.setState({ selectedGender: e.target.value })
  }

  updateUserImage = (file) => {
    const promise = new Promise((resolve, reject) => {
      const data = new FormData();
      data.append("UserID", localStorage.getItem('userID').replace(/['"]+/g, ''));
      data.append("UserName", localStorage.getItem('username'));
      data.append("file", file);
      fetch('https://languagevr.io/test/updateProfilePic.php', {
        method: "POST",
        body: data
      }).then(() => {
        resolve()
        this.getProfile()
      })
    })
    toast.promise(
      promise,
      {
        pending: 'Profile Image Uploading...',
        success: 'Profile Image Changed',
        error: 'Image Cant upload'
      }
    )
  }

  startTimer() {
    let seconds = 60;
    const timer = setInterval(() => {
      seconds = seconds - 1
      this.setState({ time: seconds })
      if (seconds == 0 || !this.state.show) {
        this.handleClose()
        this.setState({ time: 60 })
        clearInterval(timer)
      }
    }, 1000)
  }


  updateUserCertificate = (file) => {
    const promise = new Promise((resolve, reject) => {
      const data = new FormData();
      data.append("UserID", localStorage.getItem('userID').replace(/['"]+/g, ''));
      // data.append("UserName", localStorage.getItem('username'));
      data.append("file", file);
      fetch('https://languagevr.io/test/uploadCertificate.php', {
        method: "POST",
        body: data
      }).then(() => {
        resolve()
        this.getProfile()
      })
    })
    toast.promise(
      promise,
      {
        pending: 'User Certificate Uploading...',
        success: 'User Certificate Uploaded',
        error: 'User Certificate upload'
      }
    )
  }

  setSelectedFile = (file) => {
    this.setState({ file: file })
    this.updateUserImage(file)
  }

  changeMode = () => {
    if (this.state.editMode == true) {
      this.updateProfile()
    }
    this.setState({ editMode: !this.state.editMode })
  }

  selectUiLang = (e) => {
    localStorage.setItem('lang', e.target.value)
    this.props.pushNewLang(e.target.value)
    this.setState({ selectedUiLang: e.target.value })
  }

  sendNewPass = () => {
    let data = {
      Action: "updatePassword",
      UserID: localStorage.getItem('userID').replace(/['"]+/g, ''),
      OldPassword: this.state.oldPassword,
      NewPassword: this.state.newPassword
    }
    postActions(data).then(() => {
      toast.success('Password Change Success', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        limit: 3,
        progress: undefined,
      });
    })
    this.handleClosePass()
  }

  sendError = () => {
    if (!this.state.oldPassword.length) {
      toast.warn('Please enter old password', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        limit: 3,
        progress: undefined,
      })
    } else if (!this.state.newPassword.length || !this.state.reNewPassword.length) {
      toast.warn('Please enter new password', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        limit: 3,
        progress: undefined,
      })
    } else if (this.state.reNewPassword != this.state.newPassword) {
      toast.warn('Passwords Must be the same', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        limit: 3,
        progress: undefined,
      })
    }
  }

  updateProfile = () => {
    let lang = ''
    if (this.state.selectedLangs) {
      this.state.selectedLangs.forEach((item, index) => {
        if (index == 0 && this.state.selectedLangs.length > 1) {
          lang = lang + item.name
        } else {
          lang = lang + ', ' + item.name
        }
      })
    }
    let data = {
      Action: "updateUserProfile",
      UserData: {
        UserID: localStorage.getItem('userID').replace(/['"]+/g, ''),
        NativeLanguage: lang,
        Program: this.state.Program,
        Country: this.state.selectedCountry,
        Bio: this.state.Bio,
        Organization: this.state.Organization,
        Patreon: this.state.Patreon,
        Gender: this.state.selectedGender
      }
    }
    postActions(data).then(() => {
      toast.success('Profile Updated', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        limit: 3,
        progress: undefined,
      });
      this.getProfile()
    })
  }

  callUserProfile = (user) => {
    this.props.history.push({
      pathname: "/learnerClasses",
      state: this.state.userData.ID
    })
  }

  selectLangs = (e) => {
    this.setState({ selectedLangs: e })
  }

  logOut = () => {
    localStorage.clear();
    this.props.pushLogout(true)
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  render() {
    const { userData, newPassword, reNewPassword, oldPassword } = this.state
    let valid = newPassword.length && reNewPassword.length && newPassword == reNewPassword && oldPassword.length
    return (
      <Container>
        {userData &&
          <Row className="profileMain">
            <Col xl={4} className="leftBox">
              <div class="imgClass"><img style={{ width: '100px', height: '100px', }} src={"https://languagevr.io/" + userData.ProfilePic} /></div>
              {this.state.isSelf ?
                <Link to="/profile/friendList">
                  <div class="uploadImgButton" onClick={this.getFriends}>{this.props.langs.yourFriends}</div>
                </Link>
                :
                this.state.isFriend == 'True' ? '' : <div class="uploadImgButton" onClick={this.sendFriend}>{this.props.langs.sendFriendRequest}</div>
              }
              {this.state.isSelf ?
                (
                  <div>
                    <div class="textClass">{localStorage.getItem('type').replace(/['"]+/g, '') == '1' ? this.props.langs.tutor : (this.state.userType == 2 ? this.props.langs.learner : (this.state.userType == 3 ? this.props.langs.speaker : this.props.langs.guest))}</div>
                    <div class="uploadImgButton"><input type="file" class="fileInput" accept="image/png, image/jpeg, image/jpg" onChange={(e) => this.setSelectedFile(e.target.files[0])} />{this.props.langs.newProfilePhoto}</div>
                    {localStorage.getItem('type').replace(/['"]+/g, '') === "1" ? <div class="uploadImgButton"><input type="file" class="fileInput" onChange={(e) => this.updateUserCertificate(e.target.files[0])} />{this.props.langs.uploadCertificate}</div> : ''}
                    <div class="uploadImgButton" onClick={this.handleOpen}>{this.props.langs.getPairingCode}</div>
                    <div class="uploadImgButton" onClick={this.handleOpenPass}>{this.props.langs.changePassword}</div>
                    <Link to="/profile/trophies">
                      <div class="uploadImgButton">{this.props.langs.yourTrophies}</div>
                    </Link>
                    {userData.Occupation === '2' ? <div class="uploadImgButton" onClick={() => this.callUserProfile()}>{this.props.langs.viewGrades}</div> : ''}
                    <div class="uploadImgButton" onClick={this.logOut} to="/login">{this.props.langs.logout}</div>
                  </div>
                )
                :
                localStorage.getItem('type').replace(/['"]+/g, '') === '1' && userData.Occupation === '2' ? <div class="uploadImgButton" onClick={() => this.callUserProfile()}>{this.props.langs.viewGrades}</div> : ''
              }
            </Col>
            <Col xl={8} className="rightBox">
              <div className="rightTopBar">
                <div className="userName">{userData.FullName}</div>
                {this.state.isSelf ?
                  <div className="selectLang">
                    <span>{this.props.langs.selectLanguage}:</span>
                    <div class="uploadImgButton lang">
                      <Form.Control as="select" className="dropdownActions" value={this.state.selectedUiLang} onChange={(e) => this.selectUiLang(e)} placeholder="chose Lecture type" aria-label="Default select example">
                        {this.state.langList && this.state.langList.map(Element =>
                          <option key={Element.code} value={Element.name}>{Element.name}</option>
                        )}
                      </Form.Control>
                    </div>
                  </div>
                  :
                  ''
                }
              </div>
              <div className="form">
                <Row className="profileRow">
                  <Col className="label noP" xl={5}>{this.props.langs.email}:</Col>
                  <Col className="description" xl={7}>{userData.Email}</Col>
                </Row>
                <Row className="profileRow">
                  <Col className="label noP" xl={5}>{this.props.langs.userType}:</Col>
                  <Col className="description" xl={7}>{userData.Occupation === "1" ? this.props.langs.tutor : (this.state.userType == 2 ? this.props.langs.learner : (this.state.userType == 3 ? 'Speaker' : 'Guest'))}</Col>
                </Row>
                {this.state.editMode == true ?
                  (
                    <Col>
                      <Row className="profileRow">
                        <Col className="label noP" xl={5}>{this.props.langs.gender}</Col>
                        <Col className="description" xl={7}>
                          <Form.Control as="select" className="dropdownActions" value={this.state.selectedGender} onChange={(e) => this.genderSelect(e)} placeholder="chose Lecture type" aria-label="Default select example">
                            {this.state.genderList && this.state.genderList.map(Element =>
                              <option key={Element.num} value={Element.num}>{Element.name}</option>
                            )}
                          </Form.Control>
                          {/* <input name="Country" value={this.state.Country} onChange={this.handleChange}/> */}
                        </Col>
                      </Row>
                      <Row className="profileRow">
                        <Col className="label noP" xl={5}>{this.props.langs.nativeLanguages}:</Col>
                        <Col className="description" xl={7}>
                          {/* <input name="NativeLanguage" value={this.state.NativeLanguage} onChange={this.handleChange}/> */}
                          <Multiselect
                            options={this.state.languageList} // Options to display in the dropdown
                            selectedValues={this.state.selectedLangs} // Preselected value to persist in dropdown
                            onSelect={this.selectLangs} // Function will trigger on select event
                            onRemove={this.selectLangs} // Function will trigger on remove event
                            displayValue="name" // Property name to display in the dropdown options
                          />
                        </Col>
                      </Row>
                      <Row className="profileRow">
                        <Col className="label noP" xl={5}>{this.props.langs.about}:</Col>
                        <Col className="description" xl={7}><Form.Control name="Bio" onChange={this.handleChange} value={this.state.Bio} as="textarea" className="textAreaClass" rows={3} maxlength="500" /></Col>
                      </Row>
                      <Row className="profileRow">
                        <Col className="label noP" xl={5}>{this.props.langs.patreon}</Col>
                        <Col className="description" xl={7}><input name="Patreon" value={this.state.Patreon} onChange={this.handleChange} /></Col>
                      </Row>
                      <Row className="profileRow">
                        <Col className="label noP" xl={5}>{this.props.langs.country}</Col>
                        <Col className="description" xl={7}>
                          <Form.Control as="select" className="dropdownActions" value={this.state.selectedCountry} onChange={(e) => this.countrySelect(e)} placeholder="chose Lecture type" aria-label="Default select example">
                            {this.state.countryList && this.state.countryList.map(Element =>
                              <option key={Element.code} value={Element.name}>{Element.name}</option>
                            )}
                          </Form.Control>
                          {/* <input name="Country" value={this.state.Country} onChange={this.handleChange}/> */}
                        </Col>
                      </Row>
                      <Row className="profileRow">
                        <Col className="label noP" xl={5}>{this.props.langs.program}</Col>
                        <Col className="description" xl={7}><input name="Program" value={this.state.Program} onChange={this.handleChange} maxlength="500" disabled={localStorage.getItem('type').replace(/['"]+/g, '') === '4'} /></Col>
                      </Row>
                      <Row className="profileRow">
                        <Col className="label noP" xl={5}>{this.props.langs.organization}</Col>
                        <Col className="description" xl={7}><input name="Organization" value={this.state.Organization} onChange={this.handleChange} disabled={localStorage.getItem('type').replace(/['"]+/g, '') === '4'} maxlength="500" /></Col>
                      </Row>
                    </Col>
                  )
                  :
                  (
                    <Col>
                      <Row className="profileRow">
                        <Col className="label noP" xl={5}>{this.props.langs.gender}:</Col>
                        <Col className="description" xl={7}>{userData.Gender == 1 ? 'Male' : 'Female'}</Col>
                      </Row>
                      <Row className="profileRow">
                        <Col className="label noP" xl={5}>{this.props.langs.nativeLanguages}</Col>
                        <Col className="description" xl={7}>{userData.NativeLanguage ? userData.NativeLanguage : 'No Info'}</Col>
                      </Row>
                      <Row className="profileRow">
                        <Col className="label noP" xl={5}>{this.props.langs.about}</Col>
                        <Col className="description" xl={7}>{userData.Bio ? userData.Bio : 'No Info'}</Col>
                      </Row>
                      <Row className="profileRow">
                        <Col className="label noP" xl={5}>{this.props.langs.patreon}</Col>
                        <Col className="description" xl={7}>{userData.Patreon ? userData.Patreon : 'No Info'}</Col>
                      </Row>
                      <Row className="profileRow">
                        <Col className="label noP" xl={5}>{this.props.langs.country}</Col>
                        <Col className="description" xl={7}>{userData.Country ? userData.Country : 'No Info'}</Col>
                      </Row>
                      <Row className="profileRow">
                        <Col className="label noP" xl={5}>{this.props.langs.program}</Col>
                        <Col className="description" xl={7}>{userData.Program ? userData.Program : 'No Info'}</Col>
                      </Row>
                      <Row className="profileRow">
                        <Col className="label noP" xl={5}>{this.props.langs.organization}</Col>
                        <Col className="description" xl={7}>{userData.EntityCode ? userData.EntityCode : 'No Info'}</Col>
                      </Row>
                    </Col>
                  )
                }
              </div>
              {this.state.isSelf ?
                <Col xl={{ span: 4, offset: 3 }}>
                  <div class="uploadImgButton" onClick={this.changeMode}>{this.state.editMode ? this.props.langs.save : this.props.langs.edit}</div>
                </Col>
                : ''
              }
            </Col>
          </Row>
        }
        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          keyboard={false}
        >
          <Modal.Header>
            <Modal.Title><HeadsetVr color="#577df7" size={25} style={{ marginRight: "10px" }} />VR Pairing Code</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <span class="pairingCode">{this.state.pairingCode}</span>
            <span class="time">{this.state.time + ' Second left to enter code'}</span>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
            {/* <Button variant="primary">Continue</Button> */}
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.showPassChange}
          onHide={this.handleClosePass}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          keyboard={false}
        >
          <Modal.Header>
            <Modal.Title><Key color="#577df7" size={25} style={{ marginRight: "10px" }} />Change Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Col>
              <Form>
                <Form.Label>Old Password</Form.Label>
                <Form.Control
                  type="password"
                  onChange={(e) => { this.setState({ oldPassword: e.target.value }) }}
                  value={oldPassword}
                  aria-describedby="passwordHelpBlock"
                />
                <Form.Label>New Password</Form.Label>
                <Form.Control
                  type="password"
                  onChange={(e) => { this.setState({ newPassword: e.target.value }) }}
                  value={newPassword}
                  aria-describedby="passwordHelpBlock"
                />
                <Form.Label>Re-Enter New Password</Form.Label>
                <Form.Control
                  type="password"
                  onChange={(e) => { this.setState({ reNewPassword: e.target.value }) }}
                  value={reNewPassword}
                  aria-describedby="passwordHelpBlock"
                />
              </Form>
            </Col>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClosePass}>
              Close
            </Button>
            <Button variant="primary" color="#577df7" onClick={valid ? this.sendNewPass : this.sendError}>Continue</Button>
          </Modal.Footer>
        </Modal>
      </Container>
    );
  }
}


export default Profile;
