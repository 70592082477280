import React from 'react';
import './search.scss'
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Container, Button, Dropdown, Col, Row, Table, Form, FormControl, Spinner } from 'react-bootstrap';
import { postActions, postSocial } from '../../helpers/request'



class Search extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         friendList: '',
         data: '',
         friendRequests: '',
         searchText: '',
         userList: '',
         classRequests: [],
         isLoading: false,
         isLoading2: false
      };
   }

   componentDidMount() {
      this.getFriendRequests()
   }

   callUserProfile = (user) => {
      this.props.history.push({
         pathname: "/profile",
         state: user.ID
      })
   }

   sendFriendReq = (user) => {
      const data = new FormData();
      const userId = localStorage.getItem("userID").replace(/['"]+/g, '')
      data.append("UserID", userId);
      data.append("FriendID", user.ID);
      data.append("Action", "addFriend");
      postSocial(data).then((response) => {
         toast.success('Friend Request Sent', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            limit: 3,
            progress: undefined,
         });
      })
   }

   handleChange = (e) => {
      this.setState({ searchText: e.target.value })
   }

   onSearch = () => {
      if (this.state.searchText && this.state.searchText.length > 0) {
         this.setState({ isLoading: true })
         let data = {
            Action: "search",
            UserID: localStorage.getItem('userID').replace(/['"]+/g, ''),//User ID
            SearchText: this.state.searchText
         }
         postActions(data).then((response) => {
            this.setState({ userList: response.Data })
            this.setState({ isLoading: false })
         }).catch(() => {
            this.setState({ isLoading: false })
         })
      }
   }

   friendReqAcceptOrReject = (condition, friendID) => {
      let actionName = condition ? "acceptFriend" : "declineFriend"
      const userId = localStorage.getItem("userID").replace(/['"]+/g, '')
      const data = new FormData()
      data.append("UserID", userId)
      data.append("FriendID", friendID);
      data.append("Action", actionName);
      postSocial(data).then((response) => {
         this.props.changeNoti()
         let arr = [...this.state.friendRequests]
         arr = arr.filter(item => item.friendID !== friendID)
         this.setState({ friendRequests: arr })
         condition ?
            (toast.success('Friend Request Accepted', {
               position: "top-right",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               limit: 3,
               progress: undefined,
            }))
            :
            (toast.error('Friend Request Rejected', {
               position: "top-right",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               limit: 3,
               progress: undefined,
            }))
      })
   }

   isFriend = () => { }

   getFriendRequests = () => {
      this.setState({ isLoading2: true })
      const data = new FormData();
      const userId = localStorage.getItem("userID").replace(/['"]+/g, '')
      data.append("UserID", userId);
      data.append("Action", "getFriendRequests");
      postSocial(data).then((response) => {
         this.setFriendReqs(response)
         this.setState({ isLoading2: false })
      })
   }

   getClassRequests = () => {
      const data = {
         Action: "getClassRequests",
         UserID: localStorage.getItem("userID").replace(/['"]+/g, '')
      }
      postActions(data).then((response) => {
         let obj = []
         if (response.ClassRequests) {
            for (let x = 0; x < response.ClassRequests.length; x++) {
               let createObj = {
                  name: response.ClassRequests[x].ClassName,
                  ID: response.ClassRequests[x].ID,
               }
               obj.push(createObj)
            }
            this.setState({ classRequests: obj })
         }
      })
   }

   setFriendReqs = (data) => {
      let obj = []
      let n = data.friends && data.friends.length
      for (let x = 0; x < n; x++) {
         let createObj = {
            name: data.names[x],
            friendID: data.friends[x],
         }
         obj.push(createObj)
      }
      this.setState({ friendRequests: obj })
      this.getClassRequests()
   }


   replyClassReq = (item, condition) => {
      let data = {
         Action: "replyToClassRequest",
         UserID: localStorage.getItem("userID").replace(/['"]+/g, ''),
         ClassRequestID: item.ID,
         Reply: condition ? 'Accept' : 'Decline'
      }
      postActions(data).then(() => {
         this.props.changeNoti()
         condition ?
            (toast.success('Class Request Accepted', {
               position: "top-right",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               limit: 3,
               progress: undefined,
            }))
            :
            (toast.error('Class Request Rejected', {
               position: "top-right",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               limit: 3,
               progress: undefined,
            }))
         this.getClassRequests()
      })
   }
   render() {
      return (
         <Container>
            <Row className="labelBar justify-content-md-center">
               <Col className="label" xl={12}>
                  <div class="labelMain">Search User</div>
                  {/* <div class="newButtonMain" onClick={this.handleOpen}><PlusSquare color="#577df7" size={25}/><span class="buttonText">New</span></div>
                  <div class="infoButtonMain"><InfoSquareFill color="#577df7" size={25} /><span class="buttonText">Info</span></div> */}
               </Col>
            </Row>
            <Row className="pageRow">
               <Col className="searchBox" xl={12}>
                  <div class="settingBox">
                     <Row className="topBarDiv">
                        <Col xl={12} className="searchBarDiv">
                           <Form className="d-flex">
                              <FormControl
                                 type="search"
                                 placeholder="Search"
                                 className="searchBar"
                                 aria-label="Search"
                                 onChange={this.handleChange}
                              />
                              <Button className="searchButton" onClick={this.onSearch}>Search</Button>
                           </Form>
                        </Col>
                     </Row>
                     <Table striped bordered hover>
                        <thead>
                           <tr class="customTableRowLabel">
                              {/* <th>User Image</th> */}
                              <th>Name</th>
                              <th>Actions</th>
                           </tr>
                        </thead>
                        <tbody style={{ position: 'relative', minHeight: '200px', display: 'table-caption' }}>
                           {this.state.isLoading ?
                              <div class="animCenter">
                                 <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                 </Spinner>
                              </div>
                              :
                              this.state.userList ? this.state.userList.map(user =>
                                 <tr class="customTableRow" key={user.ID}>
                                    {/* <td class="userImageClass"><img width="40px" src={userBlankIcon} /></td> */}
                                    <td><img width="40px" height="40px" style={{ marginRight: '10px', borderRadius: '100%', objectFit: 'cover' }} src={'https://languagevr.io/' + user.ProfilePic} />{user.Name}</td>
                                    <td>
                                       <Button className="acceptButton" onClick={() => this.callUserProfile(user)}>View Profile</Button>
                                       {/* <Button className="acceptButton" onClick={() => this.sendFriendReq(user)}>Send Friend Request</Button> */}
                                    </td>
                                 </tr>
                              )
                                 :
                                 <td className="noUserFound" colSpan="2">No user found</td>
                           }
                        </tbody>
                     </Table>
                  </div>
               </Col>
               {/* <Col className="searchBox" xl={5}>
                  <div class="settingBox">
                     <Row className="topBarDiv">
                        <Col xl={12} className="friendRequestLabel">   
                           Notification
                        </Col>
                     </Row>
                  <Table striped bordered hover>
                     <thead>
                        <tr class="customTableRowLabel">
                           {/* <th>User Image</th> 
                           <th>Name</th>
                           <th>Actions</th>
                        </tr>
                     </thead>
                     <tbody style={{position: 'relative', minHeight: '200px',display: 'table-caption'}}>
                        {this.state.isLoading2 ? 
                              <div class="animCenter">
                                 <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                 </Spinner>
                              </div>
                        :
                        this.state.friendRequests && this.state.friendRequests.map(user => 
                           <tr class="customTableRow" key={user.ID}>
                              {/* <td class="userImageClass"><img width="40px" src={userBlankIcon} /></td>
                              <td>{user.name}</td>
                              <td>
                                 <Button variant="danger" onClick={() => this.friendReqAcceptOrReject(false, user.friendID)}>Reject</Button>
                                 <Button className="acceptButton" onClick={() => this.friendReqAcceptOrReject(true, user.friendID)}>Accept</Button>
                              </td>
                           </tr>
                        )}
                        {this.state.classRequests && this.state.classRequests.map(classItem => 
                           <tr class="customTableRow" key={classItem.ID}>
                              {/* <td class="userImageClass"><img width="40px" src={userBlankIcon} /></td>
                              <td>{classItem.name}</td>
                              <td>
                                 <Button variant="danger" onClick={() => this.replyClassReq(classItem, false)}>Reject</Button>
                                 <Button className="acceptButton" onClick={() => this.replyClassReq(classItem, true)}>Accept</Button>
                              </td>
                           </tr>
                         )}
                         {!this.state.classRequests.length && !this.state.friendRequests.length ?
                           <td className="noUserFound" colSpan="2">No Notification</td>
                           : 
                           ''
                         }
                     </tbody>
                  </Table>
                  </div>
               </Col> */}
            </Row>
         </Container>
      )
   }
}

export default withRouter(Search);