import React from 'react';
import logo from '../../img/MetaCampusLogo.png';
import classIcon from '../../img/Class.png';
import folderIcon from '../../img/folder.png';
import messagesIcon from '../../img/messages.png';
import profileIcon from '../../img/Profile.png';
import userBlankIcon from '../../img/ProfilePH.png';
import menuIcon from '../../img/Menu.png';
import { BellFill, Search } from 'react-bootstrap-icons';
import NotificationTab from './notificationTab'
import { Navbar, Container, Nav, NavDropdown, Button, Dropdown, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { postActions, postSocial } from '../../helpers/request';
import './navbar.scss'

class NavbarLogin extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         user: '',
         userType: '',
         notiCount: 0,
         notiCount2: 0
      };
   }
   setUser = () => {
      let username = localStorage.getItem('username')
      let type = localStorage.getItem('type')
      if (username) {
         username = username.replace(/['"]+/g, '')
         this.setState({ user: username })
      }
      if (type) {
         type = type.replace(/['"]+/g, '')
         this.setState({ userType: type })
      }
   }

   getFriendRequests = () => {
      const data = new FormData();
      const userId = localStorage.getItem("userID").replace(/['"]+/g, '')
      data.append("UserID", userId);
      data.append("Action", "getFriendRequests");
      postSocial(data).then((response) => {
         if (response.friends && response.friends.length) {
            this.setState({ notiCount: response.friends.length })
         } else {
            this.setState({ notiCount: 0 })
         }
      })
   }

   getClassRequests = () => {
      const data = {
         Action: "getClassRequests",
         UserID: localStorage.getItem("userID").replace(/['"]+/g, '')
      }
      postActions(data).then((response) => {
         if (response.ClassRequests && response.ClassRequests.length) {
            this.setState({ notiCount2: response.ClassRequests.length })
         } else {
            this.setState({ notiCount2: 0 })
         }
      })
   }

   componentDidUpdate(nextProps, state) {
      if (nextProps.noti !== this.props.noti) {
         if (localStorage.getItem("userID")) {
            this.getFriendRequests()
            this.getClassRequests()
         }
      }
   }

   changeNoti = () => {
      this.props.changeNoti()
   }

   componentDidMount() {
      this.setUser()
      if (localStorage.getItem("userID")) {
         this.getFriendRequests()
         this.getClassRequests()
      }
   }

   logOut = () => {
      localStorage.clear();
      this.props.pushLogout(true)
   }
   render() {
      return (
         <div>
            <Navbar collapseOnSelect className="customNavbar d-md-none d-none d-sm-none d-lg-none d-xl-block" expand="xl" sticky="top">
               <Container fluid>
                  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                  <Navbar.Brand className="brandCenter"><Link to="/index"><img style={{ width: '110px', height: '60px', }} src={logo} /></Link></Navbar.Brand>
                  <Nav className="me-auto right">
                     <Link to="/login">
                        <div className='loginNavButton'>Login</div>
                     </Link>
                     <Link to="/signup">
                        <div className='signupNavButton'>SingUp</div>
                     </Link>
                  </Nav>
               </Container>
            </Navbar>
            <Navbar collapseOnSelect className="customNavbarMobileTop d-xl-none d-lg-block" expand="sm" fixed="top">
               <Container fluid className="mobileNavbarBottom">
                  <div class="mobileTopLeft">
                     <Navbar.Brand className="brandCenter"><Link to="/index"><img style={{ width: '120px', height: '60px', }} src={logo} /></Link></Navbar.Brand>
                  </div>
                  <Link to="/profile">
                     {/* <div class="mobileTopRight">
                        <div className="userText">
                           <span className="userName">{this.state.user}</span>
                           <span className="roleName">{this.state.userType == 1 ? 'Tutor' : (this.state.userType == 2 ? 'Learner' : (this.state.userType == 3 ? 'Speaker' : 'Guest'))}</span>
                        </div>
                        <div className="userImg">
                           <img style={{ width: '50px', height: '50px', marginTop: '10px' }} src={"https://languagevr.io/" + localStorage.getItem('userPic')} />
                        </div>
                     </div> */}
                  </Link>
               </Container>
            </Navbar>
         </div>
      )
   }
}

export default NavbarLogin;