import React from 'react';
import './grades.scss'
import { Container, Col, Row, Modal, Button } from 'react-bootstrap';
import { postActions } from '../../helpers/request'
import { Person } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class CheckAssignment extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         data: '',
         grades: [],
         showAssi: '',
         selectedGrade: '',
         grade: ''
      };
   }

   handleOpen = (item) => {
      this.setState({ showAssi: true })
      this.selectGrade(item)
   }

   handleClose = () => {
      this.setState({ showAssi: false })
   }

   componentDidMount = () => {
      this.getUnGrades()
   }

   giveGrade = () => {
      let data = {
         Action: "gradeAssignment",
         UserID: localStorage.getItem('userID').replace(/['"]+/g, ''),
         ClassID: this.props.history.location.state.ID,
         AnswerID: this.state.selectedGrade.ID,
         Grade: this.state.grade
      }
      postActions(data).then(() => {
         toast.success('Assignment Graded', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            limit: 3,
            progress: undefined,
         });
         this.getUnGrades()
         this.handleClose()
      })
   }

   getUnGrades = () => {
      let data = {
         Action: "getUncheckedAssignments",
         UserID: localStorage.getItem('userID').replace(/['"]+/g, ''),//User ID
         ClassID: this.props.location.state
      }
      postActions(data).then((response) => {
         this.setState({ grades: response.Assignments })
         console.log(response.Assignments)
      })
   }

   selectGrade = (item) => {
      this.setState({ selectedGrade: item })
   }

   handleChange = (e) => {
      this.setState({ grade: e.target.value })
   }

   render() {
      return (
         <Container>
            <Row className="labelBar">
               <Col className="lectureLabel" xl={6}>Check Assignments</Col>
               {/* <Col className="lectureAverage" xl={6}>Current Average 66%</Col> */}
            </Row>
            {this.state.grades ?
               <Row className="pageRow">
                  {this.state.grades && this.state.grades.map(item =>
                     <Col xl={3} key={item.AssignmentID} onClick={() => this.handleOpen(item)}>
                        <div class="gradesBox">
                           <div class="topLabel">{item.FullName}</div>
                           {/* <div class="last_nextClasss">Next Class: 07-20-2021</div> */}
                           <div class="tableRow">
                              <div class="lectureName">Assignment Name: <span class="grade">{item.Description}</span></div>
                           </div>
                        </div>
                     </Col>
                  )}
               </Row>
               :
               <Col xl={12} className="noClass">No Assignment Found</Col>
            }
            <Modal
               show={this.state.showAssi}
               onHide={this.handleClose}
               backdrop="static"
               aria-labelledby="contained-modal-title-vcenter"
               centered
               keyboard={false}
            >
               <Modal.Header>
                  <Modal.Title><Person color="#577df7" size={25} style={{ marginRight: "10px" }} />{this.state.selectedGrade.FullName}</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  <Col className="assiLabel">Assigment Name: </Col>
                  <Col className="assiInside">{this.state.selectedGrade.Name}</Col>
                  <Col className="assiLabel">Assigment Answer:</Col>
                  <Col className="assiInside">{this.state.selectedGrade.Answer}</Col>
                  <Row>
                     <Col className="assiInside grade">Assigment Grade: <input placeholder='Grade' type="text" onChange={this.handleChange} /><span>{'/' + this.state.selectedGrade.MaxGrade}</span></Col>
                  </Row>
               </Modal.Body>
               <Modal.Footer>
                  <Button variant="secondary" onClick={this.handleClose}>
                     Close
                  </Button>
                  <Button className="copyButton" onClick={this.giveGrade}>
                     Give Grade
                  </Button>
               </Modal.Footer>
            </Modal>
         </Container>
      )
   }
}

export default CheckAssignment;