import React from 'react';
import userBlankIcon from '../../img/ProfilePH.png';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../classes/classes.scss'
import { Modal, Container, Button, Dropdown, Col, Row, Table, Form, FormControl } from 'react-bootstrap';
import { PlusSquare, Hourglass, InfoSquareFill } from 'react-bootstrap-icons';
import { postActions, postFormHandling } from '../../helpers/request'

class ActiveAssignments extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         data: '',
         showDelete: false,
         readyToDeleteId: '',
         results: [],
         actives: []
      };
   }

   handleOpenDelete = (item) => {
      this.setState({ readyToDeleteId: item.ID })
      this.setState({ showDelete: true })
   }

   handleCloseDelete = () => {
      this.setState({ showDelete: false })
   }

   deleteFile = () => {
      let data = {
         Action: "deleteAssignment",
         AssignmentID: this.state.readyToDeleteId
      }
      postFormHandling(data).then((response) => {
         this.getActiveAssigments(this.state.selectedLibrary)
         toast.success('File deleted', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            limit: 3,
            progress: undefined,
         });
         this.handleCloseDelete()
      })
   }

   componentDidMount() {
      this.getActiveAssigments()
   }

   goToAssignment = (item) => {
      if (item.Type == '1') {
         let data = {
            assign: item,
            ClassID: this.props.location.state.ID
         }
         this.props.history.push({
            pathname: "/classes/createNewLecture",
            state: data
         })
      } else {
         let data = {
            assign: item,
            ClassID: this.props.location.state.ID
         }
         this.props.history.push({
            pathname: "/classes/createNewAssignment",
            state: data
         })
      }
   }

   getActiveAssigments = () => {
      let data = {
         Action: "getActiveAssignmentList",
         ClassID: this.props.location.state.ID
      }
      postFormHandling(data).then((response) => {
         this.setState({ actives: response.Assignments })
      })
   }
   render() {
      return (
         <Container>
            <Row className="labelBar justify-content-md-center">
               <Col className="label" xl={12}>
                  <div class="labelMain">{this.props.langs.activeAssignment}</div>
                  {/* <div class="newButtonMain" onClick={this.handleOpen}><PlusSquare color="#577df7" size={25}/><span class="buttonText">Add Student</span></div> */}
               </Col>
            </Row>
            <Row className="pageRow">
               <Col xl={12}>
                  <div class="settingBox">
                     <Row className="topBarDiv">
                        <Col xl={12} className="filesLabel">{this.props.history.location.state && this.props.history.location.state.Name}</Col>
                     </Row>
                     <Table striped bordered hover>
                        <thead>
                           <tr class="customTableRowLabel">
                              {/* <th>User Image</th> */}
                              {/* <th>{this.props.langs.name}</th> */}
                              <th>{this.props.langs.type}</th>
                              <th>{this.props.langs.endDate}</th>
                              <th class="actions">{this.props.langs.actions}</th>
                           </tr>
                        </thead>
                        <tbody>
                           {this.state.actives && this.state.actives.map(item =>
                              <tr class="customTableRow" key={item.ID}>
                                 {/* <td>{item.Name}</td> */}
                                 <td>
                                    {item.Type && item.Type == 1 ? this.props.langs.lecture :
                                       (item.Type == 2 ? this.props.langs.reading :
                                          (item.Type == 3 ? this.props.langs.test :
                                             (item.Type == 4 ? this.props.langs.fillintheblanks :
                                                (item.Type == 5 ? this.props.langs.wordMatch :
                                                   (item.Type == 6 ? this.props.langs.writing :
                                                      (item.Type == 7 ? this.props.langs.trueFalse :
                                                         (item.Type == 8 ? this.props.langs.dramaA :
                                                            (item.Type == 9 ? this.props.langs.dramaB :
                                                               (item.Type == 10 ? this.props.langs.dramaC :
                                                                  (item.Type == 11 ? this.props.langs.dramaD :
                                                                     (item.Type == 12 ? this.props.langs.conversationClub :
                                                                        'Error')))))))))))}
                                 </td>
                                 <td>{item.EndDate.slice(0, 16)}</td>
                                 <td class="actions">
                                    <Dropdown>
                                       <Dropdown.Toggle id="dropdown-button-dark-example1" className="dropdownActions" variant="secondary">
                                       </Dropdown.Toggle>
                                       <Dropdown.Menu variant="dark">
                                          <Dropdown.Item onClick={() => this.goToAssignment(item)}>{this.props.langs.edit}</Dropdown.Item>
                                          <Dropdown.Item onClick={() => this.handleOpenDelete(item)}>{this.props.langs.delete}</Dropdown.Item>
                                       </Dropdown.Menu>
                                    </Dropdown>
                                 </td>
                              </tr>
                           )}
                        </tbody>
                     </Table>
                  </div>
               </Col>
            </Row>
            <Modal
               show={this.state.showDelete}
               onHide={this.handleCloseDelete}
               backdrop="static"
               aria-labelledby="contained-modal-title-vcenter"
               centered
               keyboard={false}
            >
               <Modal.Header>
                  <Modal.Title><InfoSquareFill color="#577df7" size={25} style={{ marginRight: "10px" }} />Delete</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  Are sure to delete this file ?
               </Modal.Body>
               <Modal.Footer>
                  <Button variant="secondary" onClick={this.handleCloseDelete}>
                     Cancel
                  </Button>
                  <Button variant="danger" onClick={this.deleteFile}>Delete</Button>
               </Modal.Footer>
            </Modal>
         </Container>
      )
   }
}

export default ActiveAssignments;