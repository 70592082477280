import React from 'react';
import {Container, Col, Row, Form} from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './createAssignment.scss'
import { postActions, postFormHandling } from '../../helpers/request'
import moment from 'moment'

class CreateNewAssignment extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         data: '',
         assignmentTypes: ['Select Lecture type','Quiz','Test', 'True/False', 'Writing', 'Reading', 'Fill in the blanks', 'Match', 'DramaA', 'DramaB', 'DramaC', 'DramaD','ConversationClub'],
         selectedLecture: [],
         userFiles: [],
         classesList: [],
         EndDateDate: '',
         EndDateTime: '',
         fileListByFilter: [
            {
               name: 'Reading',
               id: 2,
               fileList: []
            },
            {
               name: 'Test',
               id: 3,
               fileList: []
            },
            {
               name: 'TrueFalse',
               id: 4,
               fileList: []
            },
            {
               name: 'Blank',
               id: 5,
               fileList: []
            },
            {
               name: 'WordMatch',
               id: 6,
               fileList: []
            },
            {
               name: 'DramaA',
               id: 8,
               fileList: []
            },
            {
               name: 'DramaB',
               id: 9,
               fileList: []
            },
            {
               name: 'DramaC',
               id: 10,
               fileList: []
            },
            {
               name: 'DramaD',
               id: 11,
               fileList: []
            },
            {
               name: 'ConversationClub',
               id: 12,
               fileList: []
            },

         ],
         Form:[
            {
               name: 'Select Lecture type',
               id:0,
               Test: {}
            },
            {
               name: 'Quiz',
               id: 1,
               Test:{
                  FileID:"",
                  Time:"",
                  MaxGrade:""
               }
            },
            {
               name: 'Reading',
               id: 2,
               Test:{
                  FileID:"",
                  Time:"",
                  MaxGrade:""
               }
            },
            {
               name: 'Test',
               id: 3,
               Test:{
                  FileID:"",
                  Time:"",
                  MaxGrade:""
               }
            },
            {
               name: 'True/False',
               id: 4,
               Test:{
                  FileID:"",
                  Time:"",
                  MaxGrade:""
               }
            },
            {
               name: 'Fill in the blank',
               id: 5,
               Test:{
                  FileID:"",
                  Time:"",
                  MaxGrade:""
               }
            },
            {
               name: 'WordMatch',
               id: 6,
               Test:{
                  FileID:"",
                  Time:"",
                  MaxGrade:""
               }
            },
            {
               name: 'Writing',
               id: 7,
               Test:{
                  Question:"",
                  Time:"",
                  MaxGrade:""
               }
            },
            {
               name: 'Drama A',
               id: 8,
               Test:{
                  FileID:"",
                  Time:"",
                  MaxGrade:""
               }
            },
            {
               name: 'Drama B',
               id: 9,
               Test:{
                  FileID:"",
                  Time:"",
                  MaxGrade:""
               }
            },
            {
               name: 'Drama C',
               id: 10,
               Test:{
                  FileID:"",
                  Time:"",
                  MaxGrade:""
               }
            },
            {
               name: 'Drama D',
               id: 11,
               Test:{
                  FileID:"",
                  Time:"",
                  MaxGrade:""
               }
            },
            {
               name: 'Conversation Club',
               id: 12,
               Test:{
                  FileID:"",
                  Time:"",
                  MaxGrade:""
               }
            },
         ],
         classID: ''
      };
    }
    
    componentDidMount () {
      this.getLibaryies()
      if (this.props.location.state && this.props.location.state.ID) {
         this.getClasses()
      } else if (this.props.location.state && this.props.location.state.assign) {
         this.setState({classID: this.props.location.state.ClassID})
      } else {
         this.setState({classID: this.props.location.state})
      }
      let MyDate = new Date()
      let endMonth = moment(MyDate).add(1, 'month').format('YYYY-MM-DD')
      let endTime = moment(endMonth).format('HH:mm')
      this.setState({EndDateDate: endMonth})
      this.setState({EndDateTime: endTime})
    }
    
    filterFiles () {
      let files = this.state.userFiles
      let filterList = this.state.fileListByFilter
      if (files.length) {
         for (let x = 0; x < filterList.length; x++) {
            let find = files.filter(item => item.Type === filterList[x].name)
            if (find && find.length) {
               let index = filterList.findIndex(item => item.name === find[0].Type)
               filterList[index].fileList = find
            }
         }
      }
      if (this.props.location.state && this.props.location.state.ID) {
         this.setAssignmentFromFiles()
      } else if (this.props.location.state && this.props.location.state.assign) {
         this.fillAssignment()
      }
    }

    fillAssignment = () => {
      let arr = [...this.state.selectedLecture]
      let data = this.props.location.state.assign
      let type = this.state.userFiles.find(item => item.ID === data.FileID)
      if (type) {
         let count = this.state.fileListByFilter.find(item => item.name === type.Type)
         let obj = this.state.Form.find(item => item.id === count.id)
         obj.Test.FileID = type.ID
         obj.Test.MaxGrade = data.MaxGrade
         obj.Test.Time = data.Time
         this.setState({EndDateDate: data.EndDate.split(' ')[0]})
         this.setState({EndDateTime: data.EndDate.split(' ')[1]})
         arr = []
         arr.push(obj)
         this.setState({selectedLecture: arr})
      }
    }

    setAssignmentFromFiles = () => {
      let arr = [...this.state.selectedLecture]
      let file = this.props.location.state
      let count = this.state.fileListByFilter.find(item => item.name === file.Type)
      let obj = this.state.Form.find(item => item.id === count.id)
      obj.Test.FileID = file.ID
      arr = []
      arr.push(obj)
      this.setState({selectedLecture: arr})
    }



   createAssignment = () => {
      let data = {}
      let startDate = moment(new Date()).utc().format('YYYY-MM-DD HH:mm')
      let endDate = moment(this.state.EndDateDate + ' ' + this.state.EndDateTime).utc().format('YYYY-MM-DD HH:mm')
      if (moment(startDate).isSameOrAfter(endDate)) {
         toast.warn('Due date can not be earlier than today date', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            limit: 3,
            progress: undefined,
         })
         return ''
      }
      if (this.props.location.state && this.props.location.state.assign) {
         data = {
            Action:"updateAssignment",
            ID: localStorage.getItem('userID').replace(/['"]+/g, ''),
            AssignmentID: this.props.location.state.assign.ID,
            Form:{
               // ClassID: this.state.classID,
               Name:"",
               Description:"",
               StartDate:"0000-00-00 00:00:00",
               EndDate:this.state.EndDateDate + ' ' + this.state.EndDateTime,
               FileID: this.state.selectedLecture[0].Test.FileID,
               MaxGrade: this.state.selectedLecture[0].Test.MaxGrade,
               Time: this.state.selectedLecture[0].Test.Time
            }
         }
      } else {
         if (this.state.selectedLecture && this.state.selectedLecture.length > 1) {
            data = {
               Type:"newQuiz",
               ID: localStorage.getItem('userID').replace(/['"]+/g, ''),
               Form:{
                  ClassID: this.state.classID,
                  EndDate:this.state.EndDateDate + ' ' + this.state.EndDateTime,
                  Reading: this.state.selectedLecture[1].Test,
                  Test: this.state.selectedLecture[2].Test,
                  TrueFalse: this.state.selectedLecture[3].Test,
                  FillInTheBlanks: this.state.selectedLecture[4].Test,
                  WordMatch: this.state.selectedLecture[5].Test,
                  Writing: this.state.selectedLecture[6].Test
               }
            }
         } else {
            if (this.state.selectedLecture[0].name == 'Test') {
               data = {
                  Type:"newTest",
                  ID: localStorage.getItem('userID').replace(/['"]+/g, ''),
                  Form:{
                     ClassID: this.state.classID,
                     EndDate:this.state.EndDateDate + ' ' + this.state.EndDateTime,
                     Test: this.state.selectedLecture[0].Test
                  }
               }
            } else if (this.state.selectedLecture[0].name == 'WordMatch') {
               data = {
                  Type:"newWordMatch",
                  ID: localStorage.getItem('userID').replace(/['"]+/g, ''),
                  Form:{
                     ClassID: this.state.classID,
                     EndDate:this.state.EndDateDate + ' ' + this.state.EndDateTime,
                     WordMatch: this.state.selectedLecture[0].Test
                  }
               }
            } else if (this.state.selectedLecture[0].name == 'True/False') {
               data = {
                  Type:"newTrueFalse",
                  ID: localStorage.getItem('userID').replace(/['"]+/g, ''),
                  Form:{
                     ClassID: this.state.classID,
                  EndDate:this.state.EndDateDate + ' ' + this.state.EndDateTime,
                  TrueFalse: this.state.selectedLecture[0].Test
               }
            }
            } else if (this.state.selectedLecture[0].name == 'Reading') {
               data = {
                  Type:"newReading",
                  ID: localStorage.getItem('userID').replace(/['"]+/g, ''),
                  Form:{
                     ClassID: this.state.classID,
                     EndDate:this.state.EndDateDate + ' ' + this.state.EndDateTime,
                     Reading: this.state.selectedLecture[0].Test
                  }
               }
            } else if (this.state.selectedLecture[0].name == 'Writing') {
               data = {
                  Type:"newWriting",
                  ID: localStorage.getItem('userID').replace(/['"]+/g, ''),
                  Form:{
                     ClassID: this.state.classID,
                     EndDate:this.state.EndDateDate +  ' ' + this.state.EndDateTime,
                     Writing: this.state.selectedLecture[0].Test
                  }
               }
            } else if (this.state.selectedLecture[0].name == 'Fill Blank') {
               data = {
                  Type:"newBlank",
                  ID: localStorage.getItem('userID').replace(/['"]+/g, ''),
                  Form:{
                     ClassID: this.state.classID,
                     EndDate:this.state.EndDateDate + ' ' + this.state.EndDateTime,
                     FillInTheBlanks: this.state.selectedLecture[0].Test
                  }
               }
            } else if (this.state.selectedLecture[0].name == 'Drama A') {
               data = {
                  Type:"newDramaA",
                  ID: localStorage.getItem('userID').replace(/['"]+/g, ''),
                  Form:{
                     ClassID: this.state.classID,
                     EndDate:this.state.EndDateDate + ' ' + this.state.EndDateTime,
                     DramaA: this.state.selectedLecture[0].Test
                  }
               }
            }  else if (this.state.selectedLecture[0].name == 'Drama B') {
               data = {
                  Type:"newDramaB",
                  ID: localStorage.getItem('userID').replace(/['"]+/g, ''),
                  Form:{
                     ClassID: this.state.classID,
                     EndDate:this.state.EndDateDate + ' ' + this.state.EndDateTime,
                     DramaB: this.state.selectedLecture[0].Test
                  }
               }
            }  else if (this.state.selectedLecture[0].name == 'Drama C') {
               data = {
                  Type:"newDramaC",
                  ID: localStorage.getItem('userID').replace(/['"]+/g, ''),
                  Form:{
                     ClassID: this.state.classID,
                     EndDate:this.state.EndDateDate + ' ' + this.state.EndDateTime,
                     DramaC: this.state.selectedLecture[0].Test
                  }
               }
            }  else if (this.state.selectedLecture[0].name == 'Drama D') {
               data = {
                  Type:"newDramaD",
                  ID: localStorage.getItem('userID').replace(/['"]+/g, ''),
                  Form:{
                     ClassID: this.state.classID,
                     EndDate:this.state.EndDateDate + ' ' + this.state.EndDateTime,
                     DramaD: this.state.selectedLecture[0].Test
                  }
               }
            } else if (this.state.selectedLecture[0].name == 'Conversation Club') {
               data = {
                  Type:"newConversationClub",
                  ID: localStorage.getItem('userID').replace(/['"]+/g, ''),
                  Form:{
                     ClassID: this.state.classID,
                     EndDate:this.state.EndDateDate + ' ' + this.state.EndDateTime,
                     ConversationClub: this.state.selectedLecture[0].Test
                  }
               }
            }
         }
      }
      postFormHandling(data).then(() => {
         toast.success('Assignment successfuly created', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            limit: 3,
            progress: undefined,
         });
         this.props.history.push("/classes")
      })
   }
    
   getClasses = () => {
      let data = {
         Action: "getUserClasses",
         UserID: localStorage.getItem('userID').replace(/['"]+/g, '')
      }
      postActions(data).then((response) => {
         this.setState({classesList: response.Classes})
         this.setState({isLoading: false})
      }).catch(() => {
         this.setState({isLoading: false})
      })
   }

   getLibaryies = () => {
      let data = {
         Action:"getUserLibraries",
         UserID: localStorage.getItem('userID').replace(/['"]+/g, '')
      }
      postActions(data).then((response) => {
         this.getLibraryFilelist(response.Libraries[0])
      })
   }

   getLibraryFilelist = (lib) => {
      let userID = localStorage.getItem('userID').replace(/['"]+/g, '')
      let data = {
         Action:"getLibraryFileList",
         UserID: userID,
         LibraryID: lib.ID
      }
      postActions(data).then((response) => {
         this.setState({userFiles: response.Files})
         this.filterFiles()
      })
   }

   setLecture = (e) => {
      if (e.target.value != 'Quiz') {
         let arr = [...this.state.selectedLecture]
         let obj = this.state.Form.find(item => item.name == e.target.value)
         arr = []
         arr.push(obj)
         this.setState({selectedLecture: arr})
      } else if (e.target.value == 'Quiz') {
         this.setState({selectedLecture: []})
         let arr = [...this.state.selectedLecture]
         arr = this.state.Form
         arr.splice(-5, 5)
         arr.shift()
         this.setState({selectedLecture: arr})
      }
   }

   setClass = (e) => {
      this.setState({classID: e.target.value})
   }
   
   handleChangeTime = (e) => {
      this.setState({[e.target.name]: e.target.value})
   }

   handleChange = (e, item) => {
      let selected = [...this.state.selectedLecture]
      let index = this.state.selectedLecture.findIndex(el => el.id == item.id)
      selected[index].Test[e.target.name] = e.target.value
      this.setState({selectedLecture: selected})
   }

   handleChangeFile = (e, item) => {
      let selected = [...this.state.selectedLecture]
      let index = this.state.selectedLecture.findIndex(el => el.id == item.id)
      selected[index].Test.FileID = e.target.value
      this.setState({selectedLecture: selected})
   }

   sendError = () => {
      toast.warn('Please Fill In All Inputs', {
         position: "top-right",
         autoClose: 5000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         limit: 3,
         progress: undefined,
      })
   }

   render () {
      let { EndDateDate, EndDateTime, selectedLecture, classesList} = this.state
      let valid = EndDateDate && EndDateTime && selectedLecture && selectedLecture.length
      return (
         <Container>
            <Col xl={6} className="mainBox">
               <Col xl={12} className="lectureLabel">{this.props.location.state && this.props.location.state.assign ? this.props.langs.updateAssignment : this.props.langs.newAssignment}</Col>
               {classesList && classesList.length ? 
                  <Row className="newAssignmentRow">
                     <Col className="inputLabel" sm={12} xl={4}>{this.props.langs.selectClass}</Col>
                     <Col className="inputBox" sm={12} xl={8}>
                        <Row>
                        <Col xl={7} className="noP">
                           <Form.Control as="select" className="dropdownActions" onChange={(e) => this.setClass(e)} placeholder="chose Lecture type" aria-label="Default select example">
                              <option>{this.props.langs.selectClass}</option>           
                              {classesList && classesList.map(Element => 
                                 <option key={Element.ID} value={Element.ID}>{Element.Name}</option>           
                              )}
                           </Form.Control>
                        </Col>
                        </Row>
                     </Col>
                  </Row>
                  :
                  ''
               }
               <Row className="newAssignmentRow">
                  <Col className="inputLabel" sm={12} xl={4}>{this.props.langs.dueDate}:</Col>
                  <Col className="inputBox" sm={12} xl={8}>
                     <Row>
                     <Col xl={7} className="noP">
                           <Form.Control className="customDatepicker" name="EndDateDate" type="date" value={this.state.EndDateDate} onChange={(e) => this.handleChangeTime(e)}></Form.Control>
                        </Col>
                        <Col xl={5} className="noP">
                           <Form.Control className="customDatepicker" name="EndDateTime" type="time" value={this.state.EndDateTime} onChange={(e) => this.handleChangeTime(e)}></Form.Control>
                        </Col>
                     </Row>
                  </Col>
               </Row>
               <Row className="newAssignmentRow">
                  <Col className="inputLabel" sm={12} xl={4}>{this.props.langs.type}:</Col>
                  <Col xl={8} className="noP">
                     {this.props.location.state && this.props.location.state.assign ? 
                        <Col className="inputBox filled" xl={8}>{selectedLecture[0] && selectedLecture[0].name}</Col>
                        :
                        <Form.Control as="select" className="dropdownActions" value={selectedLecture[0] && selectedLecture[0].name} onChange={(e) => this.setLecture(e)} placeholder="chose Lecture type" aria-label="Default select example">
                           {this.state.Form && this.state.Form.map(Element => 
                              <option key={Element.id} value={Element.name}>{Element.name}</option>           
                              )}
                        </Form.Control>
                     }
                  </Col>
               </Row>
               {this.state.selectedLecture && this.state.selectedLecture.map(item => 
                  <div class="selectedLectureClass" key={item.id}>
                     {item.name == 'Writing' ? (
                        <Row className="newAssignmentRow">
                           <Col className="inputLabel" xl={4}>{this.props.langs.question}:</Col>
                           <Col className="inputBox" xl={8}><input name="Question" value={item.Test.Question} onChange={(e) => this.handleChange(e, item)} type="text"  maxlength="500"/></Col>
                        </Row>
                        )
                        :
                        (
                        item.name !== 'Quiz' ?
                        <Row className="newAssignmentRow">
                           <Col className="inputLabel" xl={4}>{item.name + ' File:'}</Col>
                              {this.state.fileListByFilter && this.state.fileListByFilter.map(fileArr => 
                                 fileArr.id == item.id ?
                                    <Col xl={8} className="noP" key={fileArr.id}>
                                          <Form.Control as="select" className="dropdownActions" value={item.Test.FileID} onChange={(e) => this.handleChangeFile(e, item)} key={item.id} placeholder="chose Lecture type" aria-label="Default select example">
                                                <option>{this.props.langs.selectFile}</option>
                                             {fileArr.fileList && fileArr.fileList.map(file =>
                                                <option value={file.ID} key={file.ID}>{file.Name}</option>
                                             )}
                                          </Form.Control>
                                    </Col>
                                    : 
                                    ''
                              )}
                        </Row>
                        : 
                         ''
                        )
                     }
                     {item.name === 'Drama A' || item.name === 'Drama B' || item.name === 'Drama C' || item.name === 'Drama D' ?
                        ''
                        :
                        <Col>
                           <Row className="newAssignmentRow">
                              <Col className="inputLabel" xl={4}>{this.props.langs.maxPoint}:</Col>
                              <Col className="inputBox" xl={8}><input name="MaxGrade" value={item.Test.MaxGrade} onChange={(e) => this.handleChange(e, item)} min="0" type="number"/></Col>
                           </Row>
                           <Row className="newAssignmentRow">
                              <Col className="inputLabel" xl={4}>Time:</Col>
                              <Col className="inputBox" xl={8}><input name="Time" value={item.Test.Time} onChange={(e) => this.handleChange(e, item)} min="0" type="number"/></Col>
                           </Row>
                        </Col>
                     }
                  </div> 
               )}
               <div class="createButton" onClick={valid ? this.createAssignment : this.sendError}><span class="buttonText">{this.props.langs.createAssignment}</span></div>
            </Col>
         </Container>
      )
   }
}

export default CreateNewAssignment;