import './css/util.css';
import './css/main.css';
import './App.scss';
import Navbar from './components/navbar/navbar';
import NavbarLogin from './components/navbar/navbarLogin';
import Profile from './components/profile/profile';
import Classes from './components/classes/classes';
import ClassDetail from './components/classes/classDetail';
import CreateNewClass from './components/classes/createNewClass';
import CreateNewAssignment from './components/classes/CreateNewAssignment';
import CreateNewLecture from './components/classes/createNewLecture';
import Grades from './components/grades/grades';
import ActiveAssignments from './components/grades/activeAssignments';
import CheckAssignment from './components/grades/checkAssignments';
import Files from './components/files/files';
import AdminFileList from './components/admin/adminFileList';
import AdminClasses from './components/admin/adminClasses'
import Message from './components/message/message';
import MessageForMobile from './components/message/messageForMobile';
import Test from './components/questions/test';
import TrueFalse from './components/questions/trueFalse';
import WordMatch from './components/questions/wordMatch';
import DramaA from './components/questions/dramaA';
import DramaB from './components/questions/dramaB';
import DramaC from './components/questions/dramaC';
import DramaD from './components/questions/dramaD';
import ConversationClub from './components/questions/conversationClub';
import Login from './components/login/login';
import Register from './components/login/signup';
import Search from './components/search/search';
import FriendList from './components/profile/friendList';
import Trophies from './components/trophies/trophies';
import MainPage from './components/mainPage/mainPage';
import MainPageIndex from './components/mainPage/mainPageIndex';
import PrivacyPolicy from './components/mainPage/privacyPolicy';
import Terms from './components/mainPage/terms';
import ContactUs from './components/mainPage/contactUs';
import Download from './components/mainPage/Download';
import Support from './components/mainPage/support';
import CreateNewLibrary from './components/files/createNewLibrary';
import ClassFileList from './components/files/classFileList';
import { history } from './history';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, Switch, Route, BrowserRouter as Router, Redirect, useHistory, withRouter } from 'react-router-dom';
import React from "react";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Reading from './components/questions/reading';
import FillInTheBlanks from './components/questions/fillInTheBlanks';
import LearnerClasses from './components/classes/learnerClasses';
import config from './langs/strings';


export const API_PATH = React.createContext("main");

class App extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      isLogged: true,
      loginType: '1',
      username: '',
      type: '',
      notiStatus: false,
      isMainPage: false,
      selectedLang: 'English',
      languageProp: {},
      componentMounted: false
    };
  }

  componentDidMount() {
    toast.configure();
    this.changeLang('English')
    if (localStorage.getItem('stayLog') === 'true' || sessionStorage.getItem('stayLog') === 'false') {
      if (localStorage.getItem('lang')) {
        this.setState({ selectedLang: localStorage.getItem('lang') })
      }
      const userInfo = localStorage.getItem("user")
      const type = localStorage.getItem("type") && localStorage.getItem("type").replace(/['"]+/g, '')
      if (userInfo) {
        if (type) {
          this.setState({ loginType: type })
        }
        this.setState({ isLogged: true })
      } else {
        this.setState({ isLogged: false })
        this.props.history.push("/index")
      }
      if (this.props.location.pathname === '/') {
        this.props.history.push("/index")
      }
    } else {
      localStorage.clear()
      this.setState({ isLogged: false })
    }
    this.setState({ componentMounted: true })
  }

  changeLang = (lang) => {
    let conf = config.langs
    if (lang) {
      if (lang === 'English') {
        this.setState({ languageProp: conf.en })
      } else {
        this.setState({ languageProp: conf.tr })
      }
    } else {
      if (this.state.selectedLang === 'English') {
        this.setState({ languageProp: conf.en })
      } else {
        this.setState({ languageProp: conf.tr })
      }
    }
    console.log(this.state.languageProp)
  }

  changeNotiStatus = () => {
    this.setState({ notiStatus: !this.state.notiStatus })
  }

  changeLogStatus = (data) => {
    this.setState({ isLogged: data })
    if (this.state.loginType == '1' || this.state.loginType == '3' || this.state.loginType == '6') {
      this.props.history.push("/index")
    } else if (this.state.loginType == '2') {
      this.props.history.push("/index")
    } else if (this.state.loginType == '4') {
      this.props.history.push("/index")
    } else {
      this.props.history.push("/index")
    }
  }

  setUser = (data) => {
    this.setState({ username: data.Name })
    this.setState({ type: data.Occupation })
  }

  logOut = () => {
    this.setState({ isLogged: false })
    this.props.history.push("/login")
  }

  componentDidUpdate(prevProps, prevState) {
    if (localStorage.getItem('type') && localStorage.getItem('type').replace(/['"]+/g, '') !== this.state.loginType) {
      this.setState({ loginType: localStorage.getItem('type').replace(/['"]+/g, '') })
    }
    if (this.props.location.pathname === "/index" ||
      this.props.location.pathname === "/privacyPolicy" ||
      this.props.location.pathname === "/termsConditions" ||
      this.props.location.pathname === "/contactUs" ||
      this.props.location.pathname === "/Download" ||
      this.props.location.pathname === "/support"
    ) {
      if (!this.state.isMainPage) {
        this.setState({ isMainPage: true })
      }
    } else {
      if (this.state.isMainPage) {
        this.setState({ isMainPage: false })
      }
    }
  }
  render() {
    const { location, history } = this.props
    return (
      this.state.componentMounted === true ?
        <div className="App">
          {this.state.isLogged && <Navbar pushLogout={this.logOut} noti={this.state.notiStatus} changeNoti={this.changeNotiStatus} />}
          {!this.state.isLogged && this.state.isMainPage && <NavbarLogin pushLogout={this.logOut} noti={this.state.notiStatus} changeNoti={this.changeNotiStatus} />}
          <Route history={history} render={({ location }) => (
            <div className={`customDiv ${this.state.isLogged ? (!this.state.isMainPage ? "" : "main") : (this.state.isMainPage ? "main" : "login")}`}>
              <TransitionGroup>
                <CSSTransition
                  key={location.key}
                  timeout={450}
                  classNames="fade"
                >
                  <Switch location={location}>
                    {!this.state.isLogged &&
                      <Route path="/login" exact>
                        <Login setUserData={this.setUser} logStatus={this.changeLogStatus} />
                        <div className="bottomCopyrightRouter">Copyright &copy; 2021 LANGUAGE VR Corporation, All Rights Reserved</div>
                      </Route>
                    }
                    {!this.state.isLogged &&
                      <Route path="/signup" exact>
                        <Register location={location} history={history} logStatus={this.changeLogStatus} langs={this.state.languageProp} />
                        <div className="bottomCopyrightRouter">Copyright &copy; 2021 LANGUAGE VR Corporation, All Rights Reserved</div>
                      </Route>
                    }
                    {!this.state.isLogged &&
                      <Route path="/index" exact>
                        <MainPageIndex location={location} history={history} langs={this.state.languageProp} />
                      </Route>
                    }
                    <Route path="/privacyPolicy" exact>
                      <PrivacyPolicy location={location} history={history} langs={this.state.languageProp} />
                    </Route>
                    <Route path="/termsConditions" exact>
                      <Terms location={location} history={history} langs={this.state.languageProp} />
                    </Route>
                    <Route path="/contactUs" exact>
                      <ContactUs location={location} history={history} langs={this.state.languageProp} />
                    </Route>
                    <Route path="/support" exact>
                      <Support location={location} history={history} langs={this.state.languageProp} />
                    </Route>
                    <Route path="/download" exact>
                      <Download location={location} history={history} langs={this.state.languageProp} />
                    </Route>
                    {this.state.isLogged &&
                      <Route path="/index" exact>
                        <MainPage location={location} history={history} langs={this.state.languageProp} />
                      </Route>
                    }
                    {this.state.isLogged &&
                      <Route path="/profile" exact>
                        <Profile location={location} pushLogout={this.logOut} pushNewLang={this.changeLang} history={history} langs={this.state.languageProp} />
                        <div className="bottomCopyrightRouter">Copyright &copy; 2021 LANGUAGE VR Corporation, All Rights Reserved</div>
                      </Route>
                    }
                    {this.state.isLogged &&
                      <Route path="/profile/friendList" exact>
                        <FriendList history={history} langs={this.state.languageProp} />
                        <div className="bottomCopyrightRouter">Copyright &copy; 2021 LANGUAGE VR Corporation, All Rights Reserved</div>
                      </Route>
                    }
                    {this.state.isLogged &&
                      <Route path="/profile/trophies" exact>
                        <Trophies history={history} langs={this.state.languageProp} />
                        <div className="bottomCopyrightRouter">Copyright &copy; 2021 LANGUAGE VR Corporation, All Rights Reserved</div>
                      </Route>
                    }
                    {this.state.isLogged && (this.state.loginType == '1' || this.state.loginType == '3' || this.state.loginType == '6') &&
                      <Route path="/classes" exact>
                        <Classes history={history} langs={this.state.languageProp} />
                        <div className="bottomCopyrightRouter">Copyright &copy; 2021 LANGUAGE VR Corporation, All Rights Reserved</div>
                      </Route>
                    }
                    {this.state.isLogged && this.state.loginType != '4' &&
                      <Route path="/learnerClasses" exact>
                        <LearnerClasses location={location} history={history} langs={this.state.languageProp} />
                        <div className="bottomCopyrightRouter">Copyright &copy; 2021 LANGUAGE VR Corporation, All Rights Reserved</div>
                      </Route>
                    }
                    {this.state.isLogged && (this.state.loginType == '1' || this.state.loginType == '3' || this.state.loginType == '6') &&
                      <Route path="/classes/classDetail" exact>
                        <ClassDetail location={location} history={history} langs={this.state.languageProp} />
                        <div className="bottomCopyrightRouter">Copyright &copy; 2021 LANGUAGE VR Corporation, All Rights Reserved</div>
                      </Route>
                    }
                    {this.state.isLogged && (this.state.loginType == '1' || this.state.loginType == '3' || this.state.loginType == '6') &&
                      <Route path='/classes/createNewClass' exact>
                        <CreateNewClass location={location} history={history} langs={this.state.languageProp} />
                        <div className="bottomCopyrightRouter">Copyright &copy; 2021 LANGUAGE VR Corporation, All Rights Reserved</div>
                      </Route>
                    }
                    {this.state.isLogged && (this.state.loginType == '1' || this.state.loginType == '3' || this.state.loginType == '6') &&
                      <Route path="/classes/classFileList" exact>
                        <ClassFileList history={history} langs={this.state.languageProp} />
                        <div className="bottomCopyrightRouter">Copyright &copy; 2021 LANGUAGE VR Corporation, All Rights Reserved</div>
                      </Route>
                    }
                    {this.state.isLogged && (this.state.loginType == '1' || this.state.loginType == '3' || this.state.loginType == '6') &&
                      <Route path='/classes/createNewLecture' exact>
                        <CreateNewLecture history={history} location={location} langs={this.state.languageProp} />
                        <div className="bottomCopyrightRouter">Copyright &copy; 2021 LANGUAGE VR Corporation, All Rights Reserved</div>
                      </Route>
                    }
                    {this.state.isLogged && (this.state.loginType == '1' || this.state.loginType == '3' || this.state.loginType == '6') &&
                      <Route path='/classes/createNewAssignment' exact>
                        <CreateNewAssignment history={history} location={location} langs={this.state.languageProp} />
                        <div className="bottomCopyrightRouter">Copyright &copy; 2021 LANGUAGE VR Corporation, All Rights Reserved</div>
                      </Route>
                    }
                    {this.state.isLogged && this.state.loginType != '4' &&
                      <Route path="/grades" exact>
                        <Grades location={location} history={history} langs={this.state.languageProp} />
                        <div className="bottomCopyrightRouter">Copyright &copy; 2021 LANGUAGE VR Corporation, All Rights Reserved</div>
                      </Route>
                    }
                    {this.state.isLogged && (this.state.loginType == '1' || this.state.loginType == '3' || this.state.loginType == '6') &&
                      <Route path="/activeAssignments" exact>
                        <ActiveAssignments location={location} history={history} langs={this.state.languageProp} />
                        <div className="bottomCopyrightRouter">Copyright &copy; 2021 LANGUAGE VR Corporation, All Rights Reserved</div>
                      </Route>
                    }
                    {this.state.isLogged && (this.state.loginType == '1' || this.state.loginType == '3' || this.state.loginType == '6') &&
                      <Route path="/checkAssignment" exact>
                        <CheckAssignment history={history} location={location} langs={this.state.languageProp} />
                        <div className="bottomCopyrightRouter">Copyright &copy; 2021 LANGUAGE VR Corporation, All Rights Reserved</div>
                      </Route>
                    }
                    {this.state.isLogged &&
                      <Route path="/search" exact>
                        <Search history={history} location={location} changeNoti={this.changeNotiStatus} langs={this.state.languageProp} />
                        <div className="bottomCopyrightRouter">Copyright &copy; 2021 LANGUAGE VR Corporation, All Rights Reserved</div>
                      </Route>
                    }
                    {this.state.isLogged && (this.state.loginType == '1' || this.state.loginType == '3' || this.state.loginType == '6') &&
                      <Route path="/files" exact>
                        <Files location={location} history={history} langs={this.state.languageProp} />
                        <div className="bottomCopyrightRouter">Copyright &copy; 2021 LANGUAGE VR Corporation, All Rights Reserved</div>
                      </Route>
                    }
                    {this.state.isLogged && this.state.loginType == '6' &&
                      <Route path="/adminfiles" exact>
                        <AdminFileList location={location} history={history} langs={this.state.languageProp} />
                        <div className="bottomCopyrightRouter">Copyright &copy; 2021 LANGUAGE VR Corporation, All Rights Reserved</div>
                      </Route>
                    }
                    {this.state.isLogged && this.state.loginType == '6' &&
                      <Route path="/adminclasses" exact>
                        <AdminClasses location={location} history={history} langs={this.state.languageProp} />
                        <div className="bottomCopyrightRouter">Copyright &copy; 2021 LANGUAGE VR Corporation, All Rights Reserved</div>
                      </Route>
                    }
                    {this.state.isLogged &&
                      <Route path="/messages" exact>
                        {window.innerWidth > 1200 ?
                          <Message location={location} langs={this.state.languageProp} />
                          :
                          <MessageForMobile location={location} langs={this.state.languageProp} />
                        }
                        <div className="bottomCopyrightRouter">Copyright &copy; 2021 LANGUAGE VR Corporation, All Rights Reserved</div>
                      </Route>
                    }
                    {this.state.isLogged && this.state.loginType != '4' &&
                      <Route path="/newLibrary" exact>
                        <CreateNewLibrary history={history} location={location} langs={this.state.languageProp} />
                        <div className="bottomCopyrightRouter">Copyright &copy; 2021 LANGUAGE VR Corporation, All Rights Reserved</div>
                      </Route>
                    }
                    {this.state.isLogged && (this.state.loginType == '1' || this.state.loginType == '3' || this.state.loginType == '6') &&
                      <Route path="/createNewTest" exact>
                        <Test location={location} history={history} langs={this.state.languageProp} />
                        <div className="bottomCopyrightRouter">Copyright &copy; 2021 LANGUAGE VR Corporation, All Rights Reserved</div>
                      </Route>
                    }
                    {this.state.isLogged && (this.state.loginType == '1' || this.state.loginType == '3' || this.state.loginType == '6') &&
                      <Route path="/trueFalse" exact>
                        <TrueFalse location={location} history={history} langs={this.state.languageProp} />
                        <div className="bottomCopyrightRouter">Copyright &copy; 2021 LANGUAGE VR Corporation, All Rights Reserved</div>
                      </Route>
                    }
                    {this.state.isLogged && (this.state.loginType == '1' || this.state.loginType == '3' || this.state.loginType == '6') &&
                      <Route path="/fillintheblanks" exact>
                        <FillInTheBlanks location={location} history={history} langs={this.state.languageProp} />
                        <div className="bottomCopyrightRouter">Copyright &copy; 2021 LANGUAGE VR Corporation, All Rights Reserved</div>
                      </Route>
                    }
                    {this.state.isLogged && (this.state.loginType == '1' || this.state.loginType == '3' || this.state.loginType == '6') &&
                      <Route path="/conversationclub" exact>
                        <ConversationClub location={location} history={history} langs={this.state.languageProp} />
                        <div className="bottomCopyrightRouter">Copyright &copy; 2021 LANGUAGE VR Corporation, All Rights Reserved</div>
                      </Route>
                    }
                    {this.state.isLogged && (this.state.loginType == '1' || this.state.loginType == '3' || this.state.loginType == '6') &&
                      <Route path="/wordMatch" exact>
                        <WordMatch location={location} history={history} langs={this.state.languageProp} />
                        <div className="bottomCopyrightRouter">Copyright &copy; 2021 LANGUAGE VR Corporation, All Rights Reserved</div>
                      </Route>
                    }
                    {this.state.isLogged && (this.state.loginType == '1' || this.state.loginType == '3' || this.state.loginType == '6') &&
                      <Route path="/reading" exact>
                        <Reading location={location} history={history} langs={this.state.languageProp} />
                        <div className="bottomCopyrightRouter">Copyright &copy; 2021 LANGUAGE VR Corporation, All Rights Reserved</div>
                      </Route>
                    }
                    {this.state.isLogged && (this.state.loginType == '1' || this.state.loginType == '3' || this.state.loginType == '6') &&
                      <Route path="/dramaA" exact>
                        <DramaA location={location} history={history} langs={this.state.languageProp} />
                        <div className="bottomCopyrightRouter">Copyright &copy; 2021 LANGUAGE VR Corporation, All Rights Reserved</div>
                      </Route>
                    }
                    {this.state.isLogged && (this.state.loginType == '1' || this.state.loginType == '3' || this.state.loginType == '6') &&
                      <Route path="/dramaB" exact>
                        <DramaB location={location} history={history} langs={this.state.languageProp} />
                        <div className="bottomCopyrightRouter">Copyright &copy; 2021 LANGUAGE VR Corporation, All Rights Reserved</div>
                      </Route>
                    }
                    {this.state.isLogged && (this.state.loginType == '1' || this.state.loginType == '3' || this.state.loginType == '6') &&
                      <Route path="/dramaC" exact>
                        <DramaC location={location} history={history} langs={this.state.languageProp} />
                        <div className="bottomCopyrightRouter">Copyright &copy; 2021 LANGUAGE VR Corporation, All Rights Reserved</div>
                      </Route>
                    }
                    {this.state.isLogged && (this.state.loginType == '1' || this.state.loginType == '3' || this.state.loginType == '6') &&
                      <Route path="/dramaD" exact>
                        <DramaD location={location} history={history} langs={this.state.languageProp} />
                        <div className="bottomCopyrightRouter">Copyright &copy; 2021 LANGUAGE VR Corporation, All Rights Reserved</div>
                      </Route>
                    }
                    {!this.state.isLogged && <Redirect exact to="/index" />}
                  </Switch>
                </CSSTransition>
              </TransitionGroup>
            </div>
          )} />
        </div>
        :
        ''
    );
  }



}

export default withRouter(App);
