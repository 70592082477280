import React from "react"
import { BrowserRouter } from "react-router-dom"
import Router from "./Router"
import ReactGA from 'react-ga';

class App extends React.Component{
  initGa = () => {
    ReactGA.initialize('UA-216512862-1')
    ReactGA.pageview('Homepage')
  }
  render() {
    this.initGa()
    return( 
    <BrowserRouter>
      <Router />
    </BrowserRouter>
    )
  }
}

export default App
