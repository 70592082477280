import React from 'react';
import logo from '../../img/MetaCampusLogo.png';
import classIcon from '../../img/Class.png';
import folderIcon from '../../img/folder.png';
import messagesIcon from '../../img/messages.png';
import profileIcon from '../../img/Profile.png';
import userBlankIcon from '../../img/ProfilePH.png';
import menuIcon from '../../img/Menu.png';
import { BellFill, Search, Display } from 'react-bootstrap-icons';
import NotificationTab from './notificationTab'
import { Navbar, Container, Nav, NavDropdown, Button, Dropdown, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { postActions, postSocial } from '../../helpers/request';
import './navbar.scss'

class TopNavbar extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         user: '',
         userType: '',
         notiCount: 0,
         notiCount2: 0
      };
   }
   setUser = () => {
      let username = localStorage.getItem('username')
      let type = localStorage.getItem('type')
      if (username) {
         username = username.replace(/['"]+/g, '')
         this.setState({ user: username })
      }
      if (type) {
         type = type.replace(/['"]+/g, '')
         this.setState({ userType: type })
      }
   }

   getFriendRequests = () => {
      const data = new FormData();
      const userId = localStorage.getItem("userID").replace(/['"]+/g, '')
      data.append("UserID", userId);
      data.append("Action", "getFriendRequests");
      postSocial(data).then((response) => {
         if (response.friends && response.friends.length) {
            this.setState({ notiCount: response.friends.length })
         } else {
            this.setState({ notiCount: 0 })
         }
      })
   }

   getClassRequests = () => {
      const data = {
         Action: "getClassRequests",
         UserID: localStorage.getItem("userID").replace(/['"]+/g, '')
      }
      postActions(data).then((response) => {
         if (response.ClassRequests && response.ClassRequests.length) {
            this.setState({ notiCount2: response.ClassRequests.length })
         } else {
            this.setState({ notiCount2: 0 })
         }
      })
   }

   componentDidUpdate(nextProps, state) {
      if (nextProps.noti !== this.props.noti) {
         if (localStorage.getItem("userID")) {
            this.getFriendRequests()
            this.getClassRequests()
         }
      }
   }

   changeNoti = () => {
      this.props.changeNoti()
   }

   componentDidMount() {
      this.setUser()
      if (localStorage.getItem("userID")) {
         this.getFriendRequests()
         this.getClassRequests()
      }
   }

   logOut = () => {
      localStorage.clear();
      this.props.pushLogout(true)
   }
   render() {
      return (
         <div>
            <Navbar collapseOnSelect className="customNavbar d-md-none d-none d-sm-none d-lg-none d-xl-block" expand="xl" sticky="top">
               <Container fluid>
                  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                  <Navbar.Brand className="brandCenter"><Link to="/index"><img style={{ width: '110px', height: '60px', }} src={logo} /></Link></Navbar.Brand>
                  <Nav className="me-auto">
                     <Nav.Link className="customIcon">
                        <Link to="/search">
                           <Search color="#577DF7" size={33} />
                        </Link>
                     </Nav.Link>
                     {this.state.userType && this.state.userType == 6 ?
                        <Nav.Link>
                           <Link to="adminclasses">
                              <Display style={{ marginTop: "5px" }} color="#577DF7" size={42} />
                           </Link>
                        </Nav.Link>
                        :
                        ''
                     }
                     {this.state.userType && this.state.userType != 4 ?
                        <Nav.Link>
                           <Link to={this.state.userType == 2 ? "/learnerClasses" : "/classes"}>
                              <img style={{ width: '50px', height: '50px', }} src={classIcon} />
                           </Link>
                        </Nav.Link>
                        :
                        ''
                     }
                     {this.state.userType && this.state.userType != 4 && this.state.userType != 2 ?
                        <Nav.Link>
                           <Link to="/files">
                              <img style={{ width: '50px', height: '50px', }} src={folderIcon} />
                           </Link>
                        </Nav.Link>
                        :
                        ''
                     }
                     <Nav.Link>
                        <Link to="/messages">
                           <img style={{ width: '50px', height: '50px', }} src={messagesIcon} />
                        </Link>
                     </Nav.Link>
                  </Nav>
                  <Nav className="me-auto right">
                     <Dropdown className="notificationDrop">
                        {this.state.notiCount || this.state.notiCount2 ?
                           <div class="notificationInfo">{(this.state.notiCount + this.state.notiCount2)}</div>
                           :
                           ''
                        }
                        <Dropdown.Toggle variant="success" id="dropdown-basic" className="notifButton">
                           <BellFill color="#577DF7" size={33} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                           <NotificationTab changeNoti={this.changeNoti} />
                        </Dropdown.Menu>
                     </Dropdown>
                     <Dropdown className="customNavbarDropdown">
                        <div class="navbarImg">
                           <img style={{ width: '50px', height: '50px', marginTop: '10px' }} src={"https://languagevr.io/" + localStorage.getItem('userPic')} />
                        </div>
                        <div className="userText">
                           <span className="userName">{this.state.user}</span>
                           <span className="roleName">{this.state.userType == 1 ? 'Tutor' : (this.state.userType == 2 ? 'Learner' : (this.state.userType == 3 ? 'Speaker' : (this.state.userType == 4 ? 'Guest' : 'Admin')))}</span>
                        </div>
                        <Dropdown.Toggle variant="success" id="dropdown-basic" className="dropdownNavbar" />
                        <Dropdown.Menu className="dropdownMenuDiv">
                           <Link to="/profile" className="Nav__brand">
                              <Dropdown.Item href="#/action-1"><img style={{ width: '20px', height: '20px', }} src={profileIcon} />My Profile</Dropdown.Item>
                           </Link>
                           <Link to="/login">
                              <Dropdown.Item onClick={this.logOut} to="/login">Logout</Dropdown.Item>
                           </Link>
                        </Dropdown.Menu>
                     </Dropdown>
                  </Nav>
               </Container>
            </Navbar>
            <Navbar collapseOnSelect className="customNavbarMobileTop d-xl-none d-lg-block" expand="sm" fixed="top">
               <Container fluid className="mobileNavbarBottom">
                  <div class="mobileTopLeft">
                     <Navbar.Brand className="brandCenter"><Link to="/index"><img style={{ width: '120px', height: '60px', }} src={logo} /></Link></Navbar.Brand>
                  </div>
                  <Link to="/profile">
                     <div class="mobileTopRight">
                        <div className="userText">
                           <span className="userName">{this.state.user}</span>
                           <span className="roleName">{this.state.userType == 1 ? 'Tutor' : (this.state.userType == 2 ? 'Learner' : (this.state.userType == 3 ? 'Speaker' : (this.state.userType == 4 ? 'Guest' : 'Admin')))}</span>
                        </div>
                        <div className="userImg">
                           <img style={{ width: '50px', height: '50px', marginTop: '10px' }} src={"https://languagevr.io/" + localStorage.getItem('userPic')} />
                        </div>
                     </div>
                  </Link>
               </Container>
            </Navbar>
            <Navbar collapseOnSelect className="customNavbarMobile d-xl-none d-lg-block" expand="sm" fixed="bottom">
               <Container fluid className="mobileNavbarBottom">
                  <Col sm={4} className="customIcon">
                     <Link to="/search">
                        <Search color="#577DF7" size={33} />
                     </Link>
                  </Col>
                  {this.state.userType && this.state.userType == 4 ?
                     ''
                     :
                     <Col sm={4}>
                        <Link to="/classes">
                           <img style={{ width: '50px', height: '50px', }} src={classIcon} />
                        </Link>
                     </Col>
                  }
                  {this.state.userType && this.state.userType == 4 && this.state.userType == 2 ?
                     ''
                     :
                     <Col sm={4}>
                        <Link to="/files">
                           <img style={{ width: '50px', height: '50px', }} src={folderIcon} />
                        </Link>
                     </Col>
                  }
                  <Col sm={4}>
                     <Link to="/messages">
                        <img style={{ width: '50px', height: '50px', }} src={messagesIcon} />
                     </Link>
                  </Col>
               </Container>
            </Navbar>
         </div>
      )
   }
}

export default TopNavbar;