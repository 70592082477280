
const API_URL = "https://languagevr.io/test";

// langvr Api Request List
export const postActions = (params) => {
  return new Promise((resolve, reject) => {
    fetch(API_URL + "/actions.php", {
      method: "POST",
      body: JSON.stringify(params)
    }).then((response) => {
      response.json().then(data => ({
        data: data,
        status: response.status
      })).then((res) => {
        if (res.data && res.data.Error) {
          console.log(res.data.Errror)
          reject()
        } else {
          resolve(res.data)
        }
      })
    })
  })
}

export const postSocial = (params) => {
  return new Promise((resolve, reject) => {
    fetch(API_URL + "/social.php", {
      method: "POST",
      body: params
    }).then((response) => {
      response.json().then(data => ({
        data: data,
        status: response.status
      })).then((res) => {
        if (res.data && res.data.Error) {
          reject()
        } else {
          resolve(res.data)
        }
      })
    })
  })
}

export const postChat = (params) => {
  return new Promise((resolve, reject) => {
    fetch(API_URL + "/chat.php", {
      method: "POST",
      body: params
    }).then((response) => {
      response.json().then(data => ({
        data: data,
        status: response.status
      })).then((res) => {
        if (res.data && res.data.Error) {
          reject()
        } else {
          resolve(res.data)
        }
      })
    })
  })
}

export const postFormHandling = (params) => {
  return new Promise((resolve, reject) => {
    fetch(API_URL + "/formHandling.php", {
      method: "POST",
      body: JSON.stringify(params)
      }).then((response) => {
      response.json().then(data => ({
         data: data,
         status: response.status
      })).then((res) => {
        if (res.data && res.data.Error) {
          reject()
        } else {
          resolve(res.data)
        }
      })
    })
  })
}
// // Get and Set Tokens
// export const setToken = token => {
//    return localStorage.setItem('JWT', token);
//  };
//  export const removeToken = token => {
//    return localStorage.removeItem('JWT');
//  };
 
//  export const getToken = () => {
//    return localStorage.getItem('JWT');
//  };
 
//  export const setItem = (name, item) => {
//    return localStorage.setItem(name, item);
//  };
 
//  export const getItem = name => {
//    return localStorage.getItem(name);
//  };

// // Request Helpers
// export const getRequest = (url) => {
//   return axios.get(API_URL + url).then((response) => {
//     if (response.data.error) {
//       throw response.data.error;
//     } else {
//       return response.data;
//     }
//   });
// };
// export const postRequest = (url, params) => {
//   return axios.post(API_URL + url, params).then((response) => {
//     if (response.data.error) {
//       throw response.data.error;
//     } else {
//       return response.data;
//     }
//   });
// };

// export const postRequestWithToken = async (url, params) => {
//   const token = await getToken();
//   return axios({
//     method: "POST",
//     url: API_URL + url,
//     headers: { Authorization: token },
//     data: params,
//   }).then((response) => {
//     if (response.data.error) {
//       throw response.data.error;
//     } else {
//       return response.data;
//     }
//   });
// };
// export const putRequestWithToken = async (url, params) => {
//   const token = await getToken();
//   return axios({
//     method: "PUT",
//     url: API_URL + url,
//     headers: { Authorization: token },
//     data: params,
//   }).then((response) => {
//     if (response.data.error) {
//       throw response.data.error;
//     } else {
//       return response.data;
//     }
//   });
// };

// export const getRequestWithToken = async (url) => {
//   const token = await getToken();
//   return axios({
//     method: "GET",
//     url: API_URL + url,
//     headers: { Authorization: token },
//   }).then((response) => {
//     if (response.data.error) {
//       throw response.data.error;
//     } else {
//       return response.data;
//     }
//   });
// };
// export const putRequestMultipart = async (url, params) => {
//   const token = await getToken();
//   return axios({
//     method: "PUT",
//     url: API_URL + url,
//     headers: { Authorization: token, "Content-Type": "multipart/form-data" },
//     data: params,
//   }).then((response) => {
//     if (response.data.error) {
//       throw response.data.error;
//     } else {
//       return response.data;
//     }
//   });
// };
// export const postRequestMultipart = async (url, params) => {
//   const token = await getToken();
//   return axios({
//     method: "POST",
//     url: API_URL + url,
//     headers: { Authorization: token, "Content-Type": "multipart/form-data" },
//     data: params,
//   }).then((response) => {
//     if (response.data.error) {
//       throw response.data.error;
//     } else {
//       return response.data;
//     }
//   });
// };
// export const deleteRequestWithToken = async (url) => {
//   console.log("url", url);
//   const token = await getToken();
//   return axios({
//     method: "DELETE",
//     url: API_URL + url,
//     headers: { Authorization: token },
//   }).then((response) => {
//     if (response.data.error) {
//       throw response.data.error;
//     } else {
//       return response.data;
//     }
//   });
// };