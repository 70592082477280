
import { Canvas } from "@react-three/fiber";
import { useLoader } from "@react-three/fiber";
import { Environment, OrbitControls } from "@react-three/drei";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { Suspense } from "react";

const Model = (item) => {
  const gltf = useLoader(GLTFLoader, "https://languagevr.io/" + item.prop.Link);
  return (
    <>
      <primitive object={gltf.scene} scale={1} />
    </>
  );
};

export default function Scene(props) {
  console.log(props)
  return (
    <div className="Scene">
      <Canvas>
		    <ambientLight intensity={1} />
        <Suspense fallback={null}>
          <Model prop={props.model} />
          <OrbitControls />
        </Suspense>
      </Canvas>
    </div>
  );
}