import React from 'react';
import './adminFiles.scss'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AdminModal from './adminModal'
import { Container, Button, Dropdown, Col, Row, Table, Form, FormControl, Modal, Tabs, Tab } from 'react-bootstrap';
import { PlusSquare, InfoSquareFill } from 'react-bootstrap-icons';
import { postActions } from '../../helpers/request'

class AdminFileList extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         data: '',
         parentObj: '',
         filter: '',
         fileList: [],
         show: false,
         result: [],
         readyToDeleteId: '',
         setShow: false,
         ShowInfo: false,
         setShowInfo: false,
         showDelete: false,
         selectedImage: null,
         libraryFileList: []
      };
   }

   handleClose = () => {
      this.setState({ show: false })
   }

   handleCloseInfo = () => {
      this.setState({ showInfo: false })
   }

   handleCloseDelete = () => {
      this.setState({ showDelete: false })
   }

   handleOpen = () => {
      this.setState({ show: true })
   }

   handleOpenInfo = () => {
      this.setState({ showInfo: true })
   }

   handleOpenDelete = (item) => {
      this.setState({ readyToDeleteId: item.ID })
      this.setState({ showDelete: true })
   }

   handleFilter = (e) => {
      this.setState({ filter: e.target.value })
   }

   removeFile = () => {
      let data = {
         Action: "makeFileDefault",
         Location: "None",
         FileID: this.state.readyToDeleteId
      }
      postActions(data).then((response) => {
         this.getFiles(this.state.parentObj)
         toast.success('File Renıved', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            limit: 3,
            progress: undefined,
         });
         this.handleCloseDelete()
      })
   }

   getLibraryFilelist = (lib) => {
      return new Promise((resolve, reject) => {
         let userID = localStorage.getItem('userID').replace(/['"]+/g, '')
         let data = {
            Action: "getLibraryFileList",
            UserID: userID,
            LibraryID: lib.ID
         }
         postActions(data).then((response) => {
            if (response.Files) {
               let data = []
               if (this.state.parentObj.type === 'Audio') {
                  data = response.Files.filter(item => item.Type === 'Audio')
               } else if (this.state.parentObj.type === 'Video') {
                  data = response.Files.filter(item => item.Type === 'Video')
               } else if (this.state.parentObj.type === 'Slide' || this.state.parentObj.type === 'PDF') {
                  data = response.Files.filter(item => item.Type === 'PDF')
               } else if (this.state.parentObj.type === '3Dmodel') {
                  data = response.Files.filter(item => item.Type === '3Dmodel')
               }
               this.setState({ libraryFileList: data })
            } else {
               this.setState({ libraryFileList: [] })
            }
            this.setState({ isLoading: false })
            resolve()
         })
      })
   }



   // 1 => TUTOR
   //"Occupation":"2",//learner
   getLibaryies = () => {
      let data = {
         Action: "getUserLibraries",
         UserID: localStorage.getItem('userID').replace(/['"]+/g, '')
      }
      postActions(data).then((response) => {
         this.getLibraryFilelist(response.Libraries[0])
      })
   }

   mimeType = () => {
   }

   getFiles = (obj) => {
      let data = {
         Action: "getDefaultFiles",
         Location: obj.name,
         Type: obj.type
      }
      postActions(data).then((response) => {
         this.setState({ fileList: response.Files })
      })
   }

   openNewTab = (item) => {
      window.open("https://languagevr.io/" + item.Link)
   }

   componentDidMount() {
      this.getFiles(this.props.history.location.state)
      this.getLibaryies()
      this.setState({ parentObj: this.props.history.location.state })
   }

   searchUser = (e) => {
      if (e.target.value && e.target.value.length > 2) {
         let data = {
            Action: "searchForStudent",
            UserID: localStorage.getItem('userID').replace(/['"]+/g, ''),
            SearchText: e.target.value
         }
         postActions(data).then((response) => {
            this.setState({ result: response.Data })
         })
      } else {
         this.setState({ result: '' })
      }
   }

   setItem = (item) => {
      let data = {
         Action: "makeFileDefault",
         Location: this.state.parentObj.name,
         FileID: item.ID
      }
      postActions(data).then((response) => {
         this.setState({ result: response.Data })
         this.getFiles(this.state.parentObj)
      })
   }

   render() {
      const { filter, fileList, libraryFileList } = this.state;
      const lowercasedFilter = filter.toLowerCase();
      const filteredData = libraryFileList ? libraryFileList.filter(item => {
         return Object.keys(item).some(key =>
            item[key].toLowerCase().includes(lowercasedFilter)
         );
      }) : ''
      return (
         <Container>
            <Row className="labelBar justify-content-md-center">
               <Col className="label" xl={12}>
                  <div class="labelMain">{this.state.parentObj.mainName + ' Files'}</div>
                  {/* <div class="newButtonMain" onClick={this.handleOpen}><PlusSquare color="#577df7" size={25}/><span class="buttonText">New</span></div>
                  <div class="infoButtonMain" onClick={this.handleOpenInfo}><InfoSquareFill color="#577df7" size={25} /><span class="buttonText">Info</span></div> */}
               </Col>
               {/* <Col xl={3}>
                  <Dropdown>
                     <Dropdown.Toggle id="dropdown-button-dark-example1" className="selectLibrary" variant="secondary">
                        Library List
                     </Dropdown.Toggle>
                     <Dropdown.Menu className="drop">
                        {this.state.library && this.state.library.map(item => 
                           <Dropdown.Item onClick={() => this.selectLibarary(item)}>{item.Name}</Dropdown.Item>
                        )}
                     </Dropdown.Menu>
                  </Dropdown>
               </Col> */}
            </Row>
            <Row className="pageRow">
               <Col xl={12}>
                  <div class="settingBox">
                     <Row className="topBarDiv">
                        <Col xl={6} className="filesLabel">
                           {this.state.parentObj.type}
                        </Col>
                        <Col xl={6} className="searchBarDiv">
                           <Form className="d-flex">
                              <Dropdown className="dropdownSearchOutside">
                                 <Dropdown.Toggle id="dropdown-button-dark-example1" className="dropdownSearch" variant="secondary">
                                    Select File To Add
                                 </Dropdown.Toggle>
                                 <Dropdown.Menu variant="dark">
                                    <Form onSubmit={e => { e.preventDefault(); }}>
                                       <FormControl
                                          type="text"
                                          placeholder="search..."
                                          className="searchBar"
                                          aria-label="text"
                                          value={filter}
                                          onChange={this.handleFilter}
                                          onKeyDown={e => { if (e.key === 'Enter') e.preventDefault(); }}
                                          onKeyDownCapture={e => { if (e.key === 'Enter') e.preventDefault(); }}
                                          onKeyPress={e => { if (e.key === 'Enter') e.preventDefault(); }}
                                       />
                                    </Form>
                                    {this.state.filteredData && this.state.filteredData.length ? this.state.filteredData.map(item =>
                                       <Dropdown.Item onClick={() => this.setItem(item)} key={item.ID}>{item.Name}</Dropdown.Item>
                                    )
                                       : this.state.libraryFileList.map(item =>
                                          <Dropdown.Item onClick={() => this.setItem(item)} key={item.ID}>{item.Name}</Dropdown.Item>
                                       )
                                    }
                                 </Dropdown.Menu>
                              </Dropdown>
                           </Form>
                        </Col>
                     </Row>
                     <Table striped bordered hover>
                        <thead>
                           <tr class="customTableRowLabel">
                              <th>{this.props.langs.type}</th>
                              <th>{this.props.langs.name}</th>
                              {/* <th>Class Name</th> */}
                              {/* <th>Date</th> */}
                              <th>{this.props.langs.actions}</th>
                           </tr>
                        </thead>
                        <tbody>
                           {fileList && fileList.length ? fileList.map(item =>
                              <tr className="customTableRow" key={item.ID}>
                                 <td>{item.Type}</td>
                                 <td>{item.Name}</td>
                                 {/* <td>Listening</td> */}
                                 {/* <td>{item.Date}</td> */}
                                 <td>
                                    <Dropdown>
                                       <Dropdown.Toggle id="dropdown-button-dark-example1" className="dropdownActions" variant="secondary">
                                          Actions
                                       </Dropdown.Toggle>
                                       <Dropdown.Menu variant="dark">
                                          <Dropdown.Item onClick={() => this.openNewTab(item)}>{this.props.langs.downloadView}</Dropdown.Item>
                                          <Dropdown.Item onClick={() => this.handleOpenDelete(item)}>{this.props.langs.remove}</Dropdown.Item>
                                       </Dropdown.Menu>
                                    </Dropdown>
                                 </td>
                              </tr>
                           )
                              :
                              <td className="noUserFound" colSpan="4">{this.props.langs.noDataFound}</td>
                           }
                        </tbody>
                     </Table>
                  </div>
               </Col>
            </Row>
            <Modal
               show={this.state.show}
               onHide={this.handleClose}
               backdrop="static"
               aria-labelledby="contained-modal-title-vcenter"
               centered
               keyboard={false}
            >
               <Modal.Header>
                  <Modal.Title><PlusSquare color="#577df7" size={25} style={{ marginRight: "10px" }} />Edit baloon banner</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  <AdminModal img={this.state.selectedImage} langs={this.props.langs} />
               </Modal.Body>
               <Modal.Footer>
                  <Button variant="secondary" onClick={this.handleClose}>
                     Close
                  </Button>
                  {/* <Button variant="primary">Continue</Button>  */}
               </Modal.Footer>
            </Modal>
            <Modal
               show={this.state.showDelete}
               onHide={this.handleCloseDelete}
               backdrop="static"
               aria-labelledby="contained-modal-title-vcenter"
               centered
               keyboard={false}
            >
               <Modal.Header>
                  <Modal.Title><InfoSquareFill color="#577df7" size={25} style={{ marginRight: "10px" }} />Delete</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  Are sure to delete this file ?
               </Modal.Body>
               <Modal.Footer>
                  <Button variant="secondary" onClick={this.handleCloseDelete}>
                     Cancel
                  </Button>
                  <Button variant="danger" onClick={this.removeFile}>Delete</Button>
               </Modal.Footer>
            </Modal>
            {/* <Modal
               show={this.state.showInfo}
               onHide={this.handleCloseInfo}
               backdrop="static"
               aria-labelledby="contained-modal-title-vcenter"
               centered
               keyboard={false}
            >
               <Modal.Header>
                  <Modal.Title><InfoSquareFill color="#577df7" size={25} style={{marginRight: "10px"}}/>Info</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  Info text
               </Modal.Body>
               <Modal.Footer>
                  <Button variant="secondary" onClick={this.handleCloseInfo}>
                     Close
                  </Button>
               </Modal.Footer>
            </Modal> */}
         </Container>
      )
   }
}

export default AdminFileList;