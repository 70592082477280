import React from 'react';
import { toast } from 'react-toastify';
import { Container, Button, Dropdown, Col, Row, Table, Form, FormControl } from 'react-bootstrap';
import { postSocial } from '../../helpers/request'

class Trophies extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         data: '',
         friends: ''
      };
   }
   render () {
      return (
         <Container>
            <Col xl={12} className="noClass">Not Available Now</Col>
         </Container>
      )
   }
}

export default Trophies;