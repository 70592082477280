import React from 'react';
import userBlankIcon from '../../img/ProfilePH.png';
import './classes.scss'
import {Container, Button, Col, Row, Modal, Spinner} from 'react-bootstrap';
import { Check, Folder, Plus, PlusSquare } from 'react-bootstrap-icons';
import CreateNewAssignment from './CreateNewAssignment';
import { Link } from 'react-router-dom';
import { postActions } from '../../helpers/request'

class LearnerClasses extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         userGrades: '',
         userScores: [],
         classesList: []
      };
    }
    componentDidMount () {
       this.getClasses()
    }

    getClasses = () => {
      let data = {
         Action: "getUserClasses",
         UserID: localStorage.getItem('userID').replace(/['"]+/g, '')
      }
      postActions(data).then((response) => {
         this.setState({classesList: response.Classes})
         this.getGrades()
      }).catch(() => {
      })
   }

    getGrades = () => {
      let data = {
         Action: "getScores",
         UserID: localStorage.getItem('userID').replace(/['"]+/g, ''),
         LearnerID: this.props.location.state && this.props.location.state.user && this.props.location.state.user.ID ? this.props.location.state.user.ID : localStorage.getItem('userID').replace(/['"]+/g, ''),
         // ClassID: this.props.location.state.classId
      }
      postActions(data).then((response) => {
         if (localStorage.getItem('type').replace(/['"]+/g, '') == "1") {
            let res = response
            let scores = []
            for(let x = 0; x < this.state.classesList.length; x++) {
               let data = res.Classes.find(item => item.ClassID === this.state.classesList[x].ID)
               if (data) {
                  scores.push(data)
               }
            }
            res.Classes = scores
            this.setState({userScores: res})
         } else {
            this.setState({userScores: response})
         }
      })
    }

    goDetail = (cls) => {
      let data = {
         UserID: localStorage.getItem('userID').replace(/['"]+/g, ''),
         ClassID: cls.ClassID
      }
      this.props.history.push({
         pathname: "/grades",
         state: data 
      })
    }

   render () {
      const { userGrades, userScores } = this.state
      return (
         <Container>
            <div style={{marginTop: '20px'}}>
            <Row className="labelBar">
               <Col className="lectureLabel" xl={6}></Col>
            </Row>
            <Row className="pageRow">
            {userScores && userScores.Classes && userScores.Classes.length ? userScores.Classes.map(item =>
               <Col xl={3} onClick={() => this.goDetail(item)}>
                  <div class="gradesBox">
                     <div class="topLabel">{item.ClassName}</div>
                     <div class="last_nextClasss">Next Class: 07-20-2021</div>
                        <div class="tableRow">
                           <div class="lectureName">{this.props.langs.attendance}</div>
                           <div class="grade">{item.Overall.Attendance}</div>
                        </div>
                        <div class="tableRow">
                           <div class="lectureName">{this.props.langs.score}</div>
                           <div class="grade">{item.Overall.Score}</div>
                        </div>
                        <div class="tableRow">
                           <div class="lectureName">{this.props.langs.termstatus}</div>
                           <div class="grade">{item.Overall.TermStatus}</div>
                        </div>
                  </div>
               </Col>
               ) 
               : 
               <Col xl={12} className="noClass">{this.props.langs.noGradeFound}</Col>
            }
            </Row>
            </div>
         </Container>
      )
   }
}

export default LearnerClasses;