import React from 'react';
import './adminFiles.scss'
import { Navbar, Container, Nav, NavDropdown, Button, Dropdown, Spinner, Col, Row, Table, Form, FormControl } from 'react-bootstrap';
import { Check, Folder, Plus, PlusSquare, InfoSquareFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { postActions } from '../../helpers/request';
import { timers } from 'jquery';

class AdminModal extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         data: '',
         isLoading: true,
         apiType: '',
         files: null,
         fileType: '',
         files2: null,
         fileType2: '',
         bannerImgLink: '',
         bannerImgLink2: '',
         bannerVideoLink: '',
         userType: ''
      };
   }
   setSelectedFile2 = (file) => {
      if (file) {
         const fSize = parseFloat(file.size / (1024 * 1024)).toFixed(2)
         const index = file.name.split(".")
         const fileType = index[index.length - 1]
         if (fSize < 50) {
            var type = file.type.substr(0, file.type.indexOf('/'));
            console.log(type)
            if (type === 'image') {
               this.setState({ apiType: 'imageUpload.php' })
            } else if (type === 'video') {
               this.setState({ apiType: 'videoUpload.php' })
            } else if (type === 'application') {
               this.setState({ apiType: 'slidesUpload.php' })
            } else if (type === 'audio') {
               this.setState({ apiType: 'audioUpload.php' })
            } else if (fileType === 'glb' || fileType === 'gltf') {
               this.setState({ apiType: 'modelUpload.php' })
            } else {
               toast.error('Unsupported file type', {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  limit: 3,
                  progress: undefined,
               });
            }
            this.setState({ fileType2: file.type })
            this.setState({ files2: file })
         } else {
            toast.error('File size must be less than 50MB, your file size is ' + fSize + 'MB', {
               position: "top-right",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               limit: 3,
               progress: undefined,
            });
         }
      }
   }

   setSelectedFile = (file) => {
      if (file) {
         const fSize = parseFloat(file.size / (1024 * 1024)).toFixed(2)
         const index = file.name.split(".")
         const fileType = index[index.length - 1]
         if (fSize < 50) {
            var type = file.type.substr(0, file.type.indexOf('/'));
            console.log(type)
            if (type === 'image') {
               this.setState({ apiType: 'imageUpload.php' })
            } else if (type === 'video') {
               this.setState({ apiType: 'videoUpload.php' })
            } else if (type === 'application') {
               this.setState({ apiType: 'slidesUpload.php' })
            } else if (type === 'audio') {
               this.setState({ apiType: 'audioUpload.php' })
            } else if (fileType === 'glb' || fileType === 'gltf') {
               this.setState({ apiType: 'modelUpload.php' })
            } else {
               toast.error('Unsupported file type', {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  limit: 3,
                  progress: undefined,
               });
            }
            this.setState({ fileType: file.type })
            this.setState({ files: file })
         } else {
            toast.error('File size must be less than 50MB, your file size is ' + fSize + 'MB', {
               position: "top-right",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               limit: 3,
               progress: undefined,
            });
         }
      }
   }

   handleSubmit = () => {
      if (this.state.files) {
         const promise = new Promise((resolve, reject) => {
            const data = new FormData();
            if (this.props.data.type.split('.')[1] === 'png' || this.props.data.ext) {
               data.append("location", this.props.data.type);
            } else {
               data.append("location", this.props.data.type + '.jpg');
            }
            data.append("File", this.state.files);
            fetch("https://languagevr.io/test/updateDefaults.php", {
               method: "POST",
               body: data
            }).then((response) => {
               response.json().then(data => ({
                  data: data,
                  status: response.status
               })).then((res) => {
                  resolve()
               })
            }).catch((err) => {
               console.log("ERROR")
            });
         })
         toast.promise(
            promise,
            {
               pending: 'File Uploading...',
               success: 'File Uploaded',
               error: 'File Cant upload'
            }
         ).then(() => {
            this.props.isFilesUploaded(true)
         })
      }
   }

   handleSubmit2 = () => {
      if (this.state.files) {
         const promise = new Promise((resolve, reject) => {
            const data = new FormData();
            if (this.props.data.type.split('.')[1] === 'png' || this.props.data.ext) {
               data.append("location", this.props.data.type);
            } else {
               data.append("location", this.props.data.type + '.jpg');
            }
            data.append("File", this.state.files2);
            fetch("https://languagevr.io/test/updateDefaults.php", {
               method: "POST",
               body: data
            }).then((response) => {
               response.json().then(data => ({
                  data: data,
                  status: response.status
               })).then((res) => {
                  resolve()
               })
            }).catch((err) => {
               console.log("ERROR")
            });
         })
         toast.promise(
            promise,
            {
               pending: 'File Uploading...',
               success: 'File Uploaded',
               error: 'File Cant upload'
            }
         ).then(() => {
            this.props.isFilesUploaded(true)
         })
      }
   }
   componentDidMount() {
      this.getImage(this.props.data)
   }

   getImage = (payload) => {
      if (payload && payload.type2) {
         let url = "https://languagevr.io/dynamicPosters/"
         let fullUrl = url + payload.type + ".jpg"
         let fullUrl2 = url + payload.type2 + ".jpg"
         this.setState({ bannerImgLink: fullUrl })
         this.setState({ bannerImgLink2: fullUrl2 })
      } else if (payload && payload.video) {
         let url = "https://languagevr.io/dynamicPosters/"
         let fullUrl = url + payload.type + ".mp4"
         this.setState({ bannerVideoLink: fullUrl })
      } else {
         let url = "https://languagevr.io/dynamicPosters/"
         let fullUrl = ''
         if (payload.type.split('.')[1] === 'png') {
            fullUrl = url + payload.type
         } else {
            fullUrl = url + payload.type + ".jpg"
         }
         this.setState({ bannerImgLink: fullUrl })
      }
      this.setState({ isLoading: false })
   }

   render() {
      const { files } = this.state
      return (
         <Container>
            <Row className="createNewModal">
               <Col xl={this.state.bannerImgLink2 ? 6 : 12}>
                  <div className="nowPhoto">
                     {this.state.isLoading ?
                        <div class="animCenter">
                           <Spinner animation="border" role="status">
                              <span className="visually-hidden">Loading...</span>
                           </Spinner>
                        </div>
                        :
                        this.props.data && this.props.data.video ? <video width="100%" height="100%" controls><source src={this.state.bannerVideoLink} type="video/mp4" /></video> : (this.props.data.ext ? <p>No Preview</p> : <img src={this.state.bannerImgLink} />)
                     }
                  </div>
                  <Col className="rightDiv" style={{ margin: 'auto' }} xl={12}>
                     <div class="uploadDivAdmin">
                        <div class="fileInput"><img src={this.props.selectedImage} /></div>
                        <input type="file" class="fileInput" accept="image, video, .glb, .gltf" onChange={(e) => this.setSelectedFile(e.target.files[0])} />
                        <span class="uploadLogo">{files ? '' : <PlusSquare color="#577df7" size={35} />}</span>
                        <span class="uploadText">
                           {files ? files.name : "Select New file"}
                        </span>
                     </div>
                     <div class="uploadFile" onClick={this.handleSubmit}>{this.props.langs.uploadFile}</div>
                  </Col>
               </Col>
               {this.state.bannerImgLink2 ?
                  <Col xl={6}>
                     <div className="nowPhoto">
                        {this.state.isLoading ?
                           <div class="animCenter">
                              <Spinner animation="border" role="status">
                                 <span className="visually-hidden">Loading...</span>
                              </Spinner>
                           </div>
                           :
                           this.props.data && this.props.data.video ? <video width="100%" height="100%" controls><source src={this.state.bannerVideoLink} type="video/mp4" /></video> : <img src={this.state.bannerImgLink2} />
                        }
                     </div>
                     <Col className="rightDiv" style={{ margin: 'auto' }} xl={12}>
                        <div class="uploadDivAdmin">
                           <div class="fileInput"><img src={this.props.selectedImage2} /></div>
                           <input type="file" class="fileInput" accept="image, video, .glb, .gltf" onChange={(e) => this.setSelectedFile2(e.target.files[0])} />
                           <span class="uploadLogo">{files ? '' : <PlusSquare color="#577df7" size={35} />}</span>
                           <span class="uploadText">
                              {files ? files.name : "Select New file"}
                           </span>
                        </div>
                        <div class="uploadFile" onClick={this.handleSubmit2}>{this.props.langs.uploadFile}</div>
                     </Col>
                  </Col>
                  : ''
               }
            </Row>
         </Container>
      )
   }
}

export default AdminModal;