import React from 'react';
import { toast } from 'react-toastify';
import { Container, Button, Dropdown, Col, Row, Table, Form, FormControl } from 'react-bootstrap';
import { postSocial } from '../../helpers/request'

class FriendList extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         data: '',
         friends: ''
      };
   }

   componentDidMount() {
      this.getFriends()
   }

   getFriends = () => {
      const userId = localStorage.getItem("userID").replace(/['"]+/g, '')
      const data = new FormData();
      data.append("UserID", userId);
      // data.append("FriendID", "1");
      data.append("Action", "getFriends")
      postSocial(data).then((response) => {
         this.createFriendsObject(response)
      })
   }

   createFriendsObject = (data) => {
      let obj = []
      let n = data.friends.length
      for (let x = 0; x < n; x++) {
         let createObj = {
            name: data.names[x],
            friendID: data.friends[x],
            profilePic: data.profilePics[x]
         }
         obj.push(createObj)
      }
      this.setState({ friends: obj })
   }

   callUserProfile = (user) => {
      this.props.history.push({
         pathname: "/profile",
         state: user.friendID
      })
   }

   removeFriend = (item) => {
      const userId = localStorage.getItem("userID").replace(/['"]+/g, '')
      const data = new FormData();
      data.append("UserID", userId);
      data.append("FriendID", item.friendID);
      data.append("Action", "removeFriend")
      postSocial(data).then((response) => {
         let data = [...this.state.friends]
         data = data.filter(user => user.friendID !== item.friendID)
         this.setState({ friends: data })
         toast.warn('Friend Removed', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            limit: 3,
            progress: undefined,
         });
      })
   }

   render() {
      return (
         <Container>
            {/* <Row className="labelBar justify-content-md-center">
               <Col className="label" xl={12}>
                  <div class="labelMain">Friend List</div>
                  <div class="newButtonMain" onClick={this.handleOpen}><PlusSquare color="#577df7" size={25}/><span class="buttonText">New</span></div>
                  <div class="infoButtonMain"><InfoSquareFill color="#577df7" size={25} /><span class="buttonText">Info</span></div>
               </Col>
            </Row> */}
            <Row className="pageRow">
               <Col xl={12}>
                  <div class="settingBox">
                     <Row className="topBarDiv">
                        <Col xl={6} className="filesLabel">Friend List</Col>
                        <Col xl={6} className="searchBarDiv">
                           <Form className="d-flex">
                              <FormControl
                                 type="search"
                                 placeholder="Search"
                                 className="searchBar"
                                 aria-label="Search"
                              />
                              <Button className="searchButton">Search</Button>
                           </Form>
                        </Col>
                     </Row>
                     <Table striped bordered hover>
                        <thead>
                           <tr class="customTableRowLabel">
                              {/* <th>User Image</th> */}
                              <th>User</th>
                              <th class="actions">Actions</th>
                           </tr>
                        </thead>
                        <tbody>
                           {this.state.friends && this.state.friends.length ? this.state.friends.map(item =>
                              <tr class="customTableRow">
                                 {/* <td class="userImageClass"><img width="40px" src={'https://languagevr.io/' + item.profilePic} /></td> */}
                                 <td><img width="40px" height="40px" style={{ marginRight: '10px', borderRadius: '100%', objectFit: 'cover' }} src={'https://languagevr.io/' + item.profilePic} />{item.name}</td>
                                 <td class="actions">
                                    <Dropdown>
                                       <Dropdown.Toggle id="dropdown-button-dark-example1" className="dropdownActions" variant="secondary">
                                       </Dropdown.Toggle>
                                       <Dropdown.Menu variant="dark">
                                          <Dropdown.Item onClick={() => this.callUserProfile(item)}>View Profile</Dropdown.Item>
                                          <Dropdown.Item onClick={() => this.removeFriend(item)}>Remove Friend</Dropdown.Item>
                                          {/* <Dropdown.Item>Block User</Dropdown.Item> */}
                                       </Dropdown.Menu>
                                    </Dropdown>
                                 </td>
                              </tr>
                           )
                              :
                              <td className="noUserFound" colSpan="4">No Friends</td>
                           }
                        </tbody>
                     </Table>
                  </div>
               </Col>
            </Row>
            {/* <Modal
               show={this.state.show}
               onHide={this.handleClose}
               backdrop="static"
               aria-labelledby="contained-modal-title-vcenter"
               centered
               keyboard={false}
            >
            <Modal.Header>
               <Modal.Title><PlusSquare color="#577df7" size={25} style={{marginRight: "10px"}}/>New</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <CreateNewModal/>
            </Modal.Body>
            <Modal.Footer>
               <Button variant="secondary" onClick={this.handleClose}>
                  Close
               </Button>
               <Button variant="primary">Continue</Button>
            </Modal.Footer>
            </Modal> */}
         </Container>
      )
   }
}

export default FriendList;