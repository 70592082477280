import React from 'react';
import { Container, Col, Row, Form, Dropdown } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './createNewLibrary.scss'
import { postActions, postFormHandling } from '../../helpers/request'

class CreateNewLibrary extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         selectedLibrary: '',
      };
   }
   handleChange = (e) => {
      this.setState({[e.target.name]: e.target.value})  
   }

   selectLibarary1 = (item) => {
      this.setState({selectedLibrary: item})
      this.props.sendData(item)
   }
   
   componentDidMount () {
      console.log(this.props)
   }

   render () {
      return (
         <Container>
            <Col xl={12} className="mainBoxCopy">
               <Col xl={12}>
                  <Col xl={12}>
                     <Col xl={12}><span class="label">{this.props.isShare ? this.props.langs.selectedLibrary : this.props.langs.selectedFileName}: </span>{this.props.file.Name}</Col>
                  </Col>
                  <Col xl={12}>
                     <Row>
                        <Col xl={12}><span class="label">{this.props.isShare ? this.props.langs.selectedFriend : this.props.langs.selectedLibrary}: </span><span class="highlight">{this.state.selectedLibrary.Name}</span></Col>
                     </Row>
                  </Col>
               </Col>
               <Col className="libselect" xl={12}>
                  <Dropdown className="drop">
                     <Dropdown.Toggle id="dropdown-button-dark-example1" className="selectLibrary" variant="secondary">
                        {this.props.isShare ?  this.props.langs.friendList : this.props.langs.libraryList}
                     </Dropdown.Toggle>
                     <Dropdown.Menu>
                        {this.props.libraryList && this.props.libraryList.map(item => 
                           <Dropdown.Item onClick={() => this.selectLibarary1(item)}>{item.Name}</Dropdown.Item>
                        )}
                     </Dropdown.Menu>
                  </Dropdown>
               </Col>
            </Col>
         </Container>
      )
   }
}

export default CreateNewLibrary;