import React from 'react';
import './mainPage.scss'
import { Container, Col, Row } from 'react-bootstrap';
import vrChatİllu from '../../svg/vrChatİllu.svg'
import friends from '../../svg/friends.svg'
import breaking from '../../svg/breakingWalls.svg'
import { Link } from 'react-router-dom'
import teacher from '../../svg/teacherTeaching.svg'
import { Joystick, LightningFill, PeopleFill, Person } from 'react-bootstrap-icons';

class MainPage extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         data: ''
      };
   }

   componentDidMount = () => {
   }

   render() {
      return (
         <Container fluid className="containerFull" >
            <Col xl={12} className="mainBoxLandingTop">
               <Row className="rowOutside">
                  <Col xl={8} className="mainInside text">
                     <Col xl={12} className="textLabel">
                        Welcome to LangVR Project.
                        <Col xl={12} className="subText">Your own virtual hub</Col>
                        <Col xl={12} className="text">
                           Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                           Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                           when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                           It has survived not only five centuries, but also the leap into electronic typesetting,
                           remaining essentially unchanged. It was popularised i
                        </Col>
                        <Link to="/profile"><div class="goProfileButton"><Person color="#fff" size={25} /> Go to your profile</div></Link>
                     </Col>
                  </Col>
                  <Col xl={4} className="mainInside d-md-none d-none d-sm-none d-lg-none d-xl-block">
                     <img width="100%" height="450" src={vrChatİllu} />
                  </Col>
               </Row>
            </Col>
            <Col xl={12} className="mainBoxLandingMid">
               <Container fluid>
                  <Row className="rowOutside">
                     <Col xl={3} className="mainOutside">
                        <Col className="mainInside">
                           <LightningFill color="#577df7" size={165} />
                        </Col>
                        <Col xl={12} className="textLabel">
                           POWER WITH UNREAL ENGINE
                           <Col xl={12} className="text">
                              Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                              Lorem Ipsum has been the industry's
                           </Col>
                        </Col>
                     </Col>
                     <Col xl={3} className="mainOutside">
                        <Col xl={12} className="mainInside">
                           <Joystick color="#577df7" size={165} />
                        </Col>
                        <Col xl={12} className="textLabel">
                           UNLIMITED VR EXPERIENCE
                           <Col xl={12} className="text">
                              Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                              Lorem Ipsum has been the industry's
                           </Col>
                        </Col>
                     </Col>
                     <Col xl={3} className="mainOutside">
                        <Col xl={12} className="mainInside">
                           <PeopleFill color="#577df7" size={165} />
                        </Col>
                        <Col xl={12} className="textLabel">
                           CONNECT WITH YOUR FRIENDS
                           <Col xl={12} className="text">
                              Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                              Lorem Ipsum has been the industry's
                           </Col>
                        </Col>
                     </Col>
                  </Row>
               </Container>
            </Col>
            <Col xl={12} className="mainBoxLanding">
               <Container>
                  <Row className="rowOutside">
                     <Col xl={8} className="mainInside text">
                        <Col xl={12} className="textLabel">
                           Hi this is text  ?
                           <Col xl={12} className="text">
                              Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                              Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                              when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                              It has survived not only five centuries, but also the leap into electronic typesetting,
                              remaining essentially unchanged. It was popularised i
                           </Col>
                        </Col>
                     </Col>
                     <Col xl={4} className="mainInside">
                        <img width="100%" height="100%" src={friends} />
                     </Col>
                  </Row>
               </Container>
            </Col>
            <Col xl={12} className="mainBoxLanding">
               <Container>
                  <Row className="rowOutside">
                     <Col xl={6} className="mainInside">
                        <img width="100%" height="100%" src={teacher} />
                     </Col>
                     <Col xl={6} className="mainInside text">
                        <Col xl={12} className="textLabel">
                           Hi this is text  ?
                           <Col xl={12} className="text">
                              Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                              Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                              when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                              It has survived not only five centuries, but also the leap into electronic typesetting,
                              remaining essentially unchanged. It was popularised i
                           </Col>
                        </Col>
                     </Col>
                  </Row>
               </Container>
            </Col>
            <Col xl={12} className="footer">
               <Row style={{ justifyContent: 'center' }}>
                  <Col xl={3} className="footerInside">
                     <Col xl={12} className="header">MENU</Col>
                     <Col xl={12} className="links">
                        <Link to="/login">
                           <span>LOGIN</span>
                        </Link>
                        <Link to="/download">
                           <span>DOWNLOAD</span>
                        </Link>
                        <Link to="/support">
                           <span>SUPPORT</span>
                        </Link>
                     </Col>
                  </Col>
                  <Col xl={3} className="footerInside">
                     <Col xl={12} className="header">SUPPORT</Col>
                     <Col xl={12} className="links">
                        <Link to="/contactUs">
                           <span>CONTACT US</span>
                        </Link>
                        <span>FORUM</span>
                     </Col>
                  </Col>
                  <Col xl={3} className="footerInside">
                     <Col xl={12} className="header">LEGAL</Col>
                     <Col xl={12} className="links">
                        <Link to="/privacyPolicy">
                           <span>PRIVACY POLICY</span>
                        </Link>
                        <Link to="/termsConditions">
                           <span>TERMS & CONDITIONS</span>
                        </Link>
                     </Col>
                  </Col>
               </Row>
            </Col>
            <Col xl={12} className="bottomCopyright">Copyright &copy; 2021 LANGUAGE VR Corporation, All Rights Reserved</Col>
         </Container>
      )
   }
}

export default MainPage;