import React from 'react';
import { Container, Col, Row, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './createNewLibrary.scss'
import { postActions, postFormHandling } from '../../helpers/request'

class CreateNewLibrary extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         Name:"",
         Type:"2"
      };
   }
   handleChange = (e) => {
      this.setState({[e.target.name]: e.target.value})  
   }
   creatNewLec = () => {
      let data = {
         Action: "newLibrary",
         UserID: localStorage.getItem('userID').replace(/['"]+/g, ''),
         Name: this.state.Name,
         Type: this.state.Type
      }
      postActions(data).then(() => {
         toast.success('Library successfuly created', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            limit: 3,
            progress: undefined,
         });
         this.props.history.push("/files")
      })
   }

   sendError = () => {
      toast.warn('Please Fill Library Name', {
         position: "top-right",
         autoClose: 5000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         limit: 3,
         progress: undefined,
      })
   }

   render () {
      const { Name } = this.state
      const valid = Name && Name.length
      return (
         <Container>
            <Col xl={6} className="mainBox">
               <Col xl={12} className="lectureLabel">{this.props.langs.newLibrary}</Col>
               <Row className="newAssignmentRow">
                  <Col className="inputLabel" xl={4}>{this.props.langs.libraryName}</Col>
                  <Col className="inputBox" xl={8}><input name="Name" value={this.state.Name} onChange={(e) => this.handleChange(e)} type="text" maxlength="50"/></Col>
               </Row>
               {/* <Row className="newAssignmentRow">
                  <Col className="inputLabel" xl={4}>Library Type:</Col>
                  <Col className="inputBox" xl={8}><input name="Type" value={this.state.Type} onChange={(e) => this.handleChange(e)} type="text"/></Col>
               </Row> */}
               <Col xl={12}>
                  <div class="createButton" onClick={valid ? this.creatNewLec : this.sendError}><span class="buttonText">{this.props.langs.createLibrary}</span></div>
               </Col>
            </Col>
         </Container>
      )
   }
}

export default CreateNewLibrary;