import React from 'react';
import './navbar.scss'
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Container, Button, Dropdown, Col, Row, Table, Form, FormControl, Spinner } from 'react-bootstrap';
import { postActions, postSocial } from '../../helpers/request'
import { BellFill, Easel, PeopleFill } from 'react-bootstrap-icons';



class NotificationTab extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         friendList: '',
         data: '',
         friendRequests: [],
         searchText: '',
         userList: '',
         classRequests: [],
         isLoading: false,
         isLoading2: false
      };
   }

   componentDidMount() {
      this.getFriendRequests()
   }

   callUserProfile = (user) => {
      this.props.history.push({
         pathname: "/profile",
         state: user.ID
      })
   }

   friendReqAcceptOrReject = (condition, friendID) => {
      let actionName = condition ? "acceptFriend" : "declineFriend"
      const userId = localStorage.getItem("userID").replace(/['"]+/g, '')
      const data = new FormData()
      data.append("UserID", userId)
      data.append("FriendID", friendID);
      data.append("Action", actionName);
      postSocial(data).then((response) => {
         this.props.changeNoti()
         let arr = [...this.state.friendRequests]
         arr = arr.filter(item => item.friendID !== friendID)
         this.setState({ friendRequests: arr })
         condition ?
            (toast.success('Friend Request Accepted', {
               position: "top-right",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               limit: 3,
               progress: undefined,
            }))
            :
            (toast.error('Friend Request Rejected', {
               position: "top-right",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               limit: 3,
               progress: undefined,
            }))
      })
   }

   getFriendRequests = () => {
      this.setState({ isLoading2: true })
      const data = new FormData();
      const userId = localStorage.getItem("userID").replace(/['"]+/g, '')
      data.append("UserID", userId);
      data.append("Action", "getFriendRequests");
      postSocial(data).then((response) => {
         this.setFriendReqs(response)
         this.setState({ isLoading2: false })
      })
   }

   getClassRequests = () => {
      const data = {
         Action: "getClassRequests",
         UserID: localStorage.getItem("userID").replace(/['"]+/g, '')
      }
      postActions(data).then((response) => {
         let obj = []
         if (response.ClassRequests) {
            for (let x = 0; x < response.ClassRequests.length; x++) {
               let createObj = {
                  name: response.ClassRequests[x].ClassName,
                  ID: response.ClassRequests[x].ID,
               }
               obj.push(createObj)
            }
            this.setState({ classRequests: obj })
         } else {
            this.setState({ classRequests: [] })
         }
      })
   }

   setFriendReqs = (data) => {
      let obj = []
      let n = data.friends && data.friends.length
      for (let x = 0; x < n; x++) {
         let createObj = {
            name: data.names[x],
            friendID: data.friends[x],
         }
         obj.push(createObj)
      }
      this.setState({ friendRequests: obj })
      this.getClassRequests()
   }


   replyClassReq = (item, condition) => {
      let data = {
         Action: "replyToClassRequest",
         UserID: localStorage.getItem("userID").replace(/['"]+/g, ''),
         ClassRequestID: item.ID,
         Reply: condition ? 'Accept' : 'Decline'
      }
      postActions(data).then(() => {
         this.props.changeNoti()
         condition ?
            (toast.success('Class Request Accepted', {
               position: "top-right",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               limit: 3,
               progress: undefined,
            }))
            :
            (toast.error('Class Request Rejected', {
               position: "top-right",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               limit: 3,
               progress: undefined,
            }))
         this.getClassRequests()
      })
   }
   render() {
      return (
         <Container>
            <Col className="searchBox" xl={12}>
               <div class="settingBox">
                  <Row className="topBarDiv">
                     <Col xl={12} className="friendRequestLabel">
                        <BellFill color="#577DF7" size={25} /> Notifications
                     </Col>
                  </Row>
                  <Table striped bordered hover>
                     <thead>
                        <tr class="customTableRowLabel">
                           {/* <th>User Image</th> */}
                           <th></th>
                           <th></th>
                           <th></th>
                        </tr>
                     </thead>
                     <tbody class="tBodyNoti">
                        {this.state.isLoading2 ?
                           <div class="animCenter">
                              <Spinner animation="border" role="status">
                                 <span className="visually-hidden">Loading...</span>
                              </Spinner>
                           </div>
                           :
                           this.state.friendRequests && this.state.friendRequests.map(user =>
                              <tr class="customTableRow" key={user.ID}>
                                 {/* <td class="userImageClass"><img width="40px" src={userBlankIcon} /></td> */}
                                 <td class="icon"><PeopleFill color="#577DF7" size={23} /></td>
                                 <td><span>{user.name} Sent Friend Request</span></td>
                                 <td>
                                    <Button variant="danger" onClick={() => this.friendReqAcceptOrReject(false, user.friendID)}>Reject</Button>
                                    <Button className="acceptButton" onClick={() => this.friendReqAcceptOrReject(true, user.friendID)}>Accept</Button>
                                 </td>
                              </tr>
                           )}
                        {!this.state.isLoading2 && this.state.classRequests && this.state.classRequests.map(classItem =>
                           <tr class="customTableRow" key={classItem.ID}>
                              {/* <td class="userImageClass"><img width="40px" src={userBlankIcon} /></td> */}
                              <td class="icon"><Easel color="#577DF7" size={23} /></td>
                              <td><span>{classItem.name} Sent Class Join Request</span></td>
                              <td>
                                 <Button variant="danger" onClick={() => this.replyClassReq(classItem, false)}>Reject</Button>
                                 <Button className="acceptButton" onClick={() => this.replyClassReq(classItem, true)}>Accept</Button>
                              </td>
                           </tr>
                        )}
                        {!this.state.isLoading2 && !this.state.classRequests.length && !this.state.friendRequests.length ?
                           <td className="noUserFound" colSpan="2">No Notification</td>
                           :
                           ''
                        }
                     </tbody>
                  </Table>
               </div>
            </Col>
         </Container>
      )
   }
}

export default withRouter(NotificationTab);