import React from 'react';
import './login.scss'
import logo from '../../img/MetaCampusLogo.png';
import { Navbar, Container, Nav, NavDropdown, Button, Dropdown, Col, Row, Table, Form } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import { history } from '../../history';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



class Login extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      // showPage: 1
      username: '',
      password: '',
      data: {
        status: false,
        userID: 0,
        userPass: 0
      },
      stayLog: false
    };
  }

  sendLoginRequest = () => {
    console.log(localStorage.getItem("user"))
    const data = new FormData();
    data.append("username", this.state.username);
    data.append("pass", this.state.password);
    fetch("https://languagevr.io/test/login.php", {
      method: "POST",
      body: data
    }).then((response) => {
      response.json().then(data => ({
        data: data,
        status: response.status
      })).then((res) => {
        console.log(res.data.Result == 'Failure')
        if (res.data.Result == 'Failure') {
          toast.error('Username or Password is Wrong', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            limit: 3,
            progress: undefined,
          });
        } else {
          toast.success('Login successful', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            limit: 3,
            progress: undefined,
          });
          localStorage.setItem("user", JSON.stringify(res.data))
          localStorage.setItem("userID", JSON.stringify(res.data.UserID))
          localStorage.setItem("type", JSON.stringify(res.data.Occupation))
          localStorage.setItem("username", JSON.stringify(res.data.UserName))
          localStorage.setItem("userPic", res.data.ProfilePictureLink)
          if (this.state.stayLog) {
            localStorage.setItem("stayLog", this.state.stayLog)
          } else {
            sessionStorage.setItem("stayLog", false)
          }
          this.props.logStatus(true)
          this.props.setUserData(res.data)
        }
      })
    }).catch((err) => {
      console.log("ERROR")
    });
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }
  render(Props) {
    return (
      <Container>
        <Row className="justify-content-md-center align-items-center">
          <Col className="loginBoxMain" xs={11} xl={4}>
            <Row>
              <Col className="align-items-center" xl={12}>
                <Link to="/index">
                  <img style={{ width: '200px', height: '120px', }} src={logo} />
                </Link>
              </Col>
              <Col className="loginText" xl={12}>
                LOGIN
              </Col>
              <Col xl={12}>
                <Col className="loginInputs" xl={12}>
                  <input type="text" name="username" placeholder="Username" value={this.state.username} onChange={this.handleChange} />
                </Col>
                <Col className="loginInputs" xl={12}>
                  <input type="password" placeholder="Password" name="password" value={this.state.password} onChange={this.handleChange} />
                </Col>
              </Col>
              <Col xl={12}>
                <Row style={{ padding: "10px" }}>
                  <Col className="checkboxDiv" xl={6}>
                    <Form.Check
                      type={"checkbox"}
                      id={"1"}
                      label={"Remember Me"}
                      onChange={() => {
                        this.setState({ stayLog: !this.state.stayLog })
                      }}
                    />
                  </Col>
                  {/* <Col className="forgotDiv" xl={6}>
                    <Link to="/forgotPass">
                      Forgot password ?
                    </Link>
                  </Col> */}
                </Row>
              </Col>
              <Col xl={12}>
                <Row className="loginSignupButtonDiv" style={{ padding: "20px" }}>
                  <Col className="loginButton" xl={12} onClick={this.sendLoginRequest}>
                    Login
                  </Col>
                  <Link to="/signup">
                    <Col className="signupButton" xl={12}>
                      Sign Up
                    </Col>
                  </Link>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}
export default withRouter(Login);
