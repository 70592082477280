import React from 'react';
import './questions.scss'
import { Container, Col, Row } from 'react-bootstrap';
import { postFormHandling } from '../../helpers/request'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class DramaB extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         data: '',
         optionId: 2,
         QuestionId: 2,
         boxCount: 0,
         testTitle: '',
         questionData: [
            {
               Word1: '',
               Word2: '',
               Num: 1
            },
         ]
      };
   }

   sendError = () => {
      toast.warn('Please Add Question', {
         position: "top-right",
         autoClose: 5000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         limit: 3,
         progress: undefined,
      })
   }

   componentDidMount () {
      if (this.props.location.state && this.props.location.state.edit) {
         this.getData(this.props.location.state.id)
      }
    }

   getData = (Qid) => {
      let data = {
         Type: "getWordMatch",
         FileID: Qid
      }
      postFormHandling(data).then((res) => {
         console.log(res)
         let que = res.Data.Words
         let arr = []
         for (let x = 0; x < que.length; x++) {
            let obj = {
               Word1: que[x].Word1,
               Word2: que[x].Word2,
               Num: que[x].Num
            }
            arr.push(obj)
         }
         this.setState({testTitle: res.Data.Name})
         this.setState({questionData: arr})
      })
   }

   createWordMatchRequest = () => {
      let data = []
      if (this.props.location.state && this.props.location.state.edit) {
         let obj = {
            Type:"updateWordMatch",
            FileID: this.props.location.state.id,
            ID: localStorage.getItem('userID').replace(/['"]+/g, ''),
            Form: {
               Title: this.state.testTitle,
               Words: this.state.questionData
            }
         }
         data = obj
      } else {
         let obj = {
            Type:"createDramaB",
            ID: localStorage.getItem('userID').replace(/['"]+/g, ''),
            LibraryID: localStorage.getItem('FList'),
            Form: {
               Title: this.state.testTitle,
               Words: this.state.questionData 
            }
         }
         data = obj
      }
      postFormHandling(data).then(() => {
         if (this.props.location.state && this.props.location.state.edit) {
            toast.success('DramaB successfuly Updated', {
               position: "top-right",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               limit: 3,
               progress: undefined,
            });
         } else {  
            toast.success('DramaB successfuly created', {
               position: "top-right",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               limit: 3,
               progress: undefined,
            });
         }
         this.props.history.push("/files")
      })
   }

   creteNewQuestion = () => {
      let boxCount = this.state.boxCount
      let arr = this.state.questionData
      let count = this.state.optionId
      for (let x = 0; x < boxCount; x++) {
         let newQueData = {
            Word1: '',
            Word2: '',
            Num: count
         }
         count++
         arr.push(newQueData)
      }
      this.setState({optionId: count})
      this.setState({questionData: arr})
   }

   deleteQue = (item) => {
      let newList = this.state.questionData.filter(que => que.Num !== item.Num)
      this.setState({questionData:newList})
   }
   
   handleChange = (e, item) => {
      if (item) {
         let array = [...this.state.questionData]
         let index = this.state.questionData.findIndex(que => que.Num === item.Num)
         if (e.target.name == 'Word1') {
            array[index].Word1 = e.target.value
         } else {
            array[index].Word2 = e.target.value
         }
         this.setState({questionData: array})
      } else {
         this.setState({[e.target.name]: e.target.value})
      }
   }
    
   changeCount = (e) => {
      this.setState({boxCount: e.target.value})
   }

   render () {
      const { questionData } = this.state
      const valid = questionData && questionData.length && questionData[0]
      return (
         <Container>
            <Row className="labelBar justify-content-md-center">
               <Col className="label" xl={12}>
                  <Row style={{width: '100%'}}>
                     <Col xl={3} className="labelMain">Drama B</Col>
                     <Col xl={4} className="titleBox">
                        <span className="title">Title:</span>
                        <input type="text" name="testTitle" value={this.state.testTitle} onChange={this.handleChange} placeholder="Question Name" maxlength="500"/>
                     </Col>
                     {/* <Col xl={4} className="titleBox">
                        <span className="title">Time:</span>
                        <input type="text" name="name" placeholder="Question Name"/>
                     </Col> */}
                  </Row>
               </Col>
            </Row>
            <Row className="pageRow">
               <Col xl={12}>
                  <div class="settingBox">
                     <Row className="questionBorder">
                        <Col xl={9} className="questionLabel drama">Antonyms</Col>
                        <Col xl={3} sm={12}>
                           <Row>
                              <Col xl={3} sm={2} xs={2} className="addQueButtonDramaInput"><input type="number" value={this.state.boxCount} onChange={this.changeCount}/></Col>
                              <Col xl={9} sm={10} xs={10} className="addQueButtonDrama" onClick={this.creteNewQuestion}>Add</Col>
                           </Row>
                        </Col>
                     </Row>
                     {this.state.questionData && this.state.questionData.map((item, index) =>
                        <Col xl={12} key={item.Num} className="questionBox">
                           <Row className="justify-content-md-center">
                              <Col xl={11} className="wordMatchBox">
                                 <Row>
                                    <Col xl={6} className="inputBox">
                                       <div class="inputBoxLeft">
                                          <div class="number">{index + 1 + '.'}</div>
                                          <input type="text" name="Word1" placeholder="Example" onChange={(e) => this.handleChange(e, item)} value={item.Word1}/>
                                       </div>
                                    </Col>
                                    <Col xl={6} className="inputBox">
                                       <div class="inputBoxRight">
                                          <input type="text" name="Word2" placeholder="Example" onChange={(e) => this.handleChange(e, item)} value={item.Word2}/>
                                       </div>
                                    </Col>
                                 </Row>
                              </Col>
                              <Col xl={1} className="selectAudio">
                                 <div class="deleteButton" onClick={() => this.deleteQue(item)}>Delete</div>
                              </Col>
                           </Row>
                        </Col>
                     )}
                     <Col xl={12} className="addQueButton" onClick={valid ? this.createWordMatchRequest : this.sendError}>Save</Col>
                  </div>
               </Col>
            </Row>
         </Container>
      )
   }
}

export default DramaB;