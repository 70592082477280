import React from 'react';
import './files.scss'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CreateNewModal from './createNewModal'
import CopyFile from './copyFile'
import { Container, Button, Dropdown, Col, Row, Table, Form, FormControl, Modal } from 'react-bootstrap';
import { PlusSquare, InfoSquareFill } from 'react-bootstrap-icons';
import { postActions } from '../../helpers/request'

class Files extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         data: '',
         filter: '',
         fileList: [],
         library: null,
         selectedLibrary: [],
         show: false,
         readyToDeleteId: '',
         readyToCopyId: '',
         setShow: false,
         ShowInfo: false,
         setShowInfo: false,
         showDelete: false,
         showCopy: false,
         copyLibData: {}
      };
   }

   handleClose = () => {
      this.setState({ show: false })
   }

   handleCloseCopy = () => {
      this.setState({ showCopy: false })
   }

   handleCloseInfo = () => {
      this.setState({ showInfo: false })
   }

   handleCloseDelete = () => {
      this.setState({ showDelete: false })
   }

   handleOpen = () => {
      this.setState({ show: true })
   }

   handleOpenCopy = (item) => {
      this.setState({ showCopy: true })
      this.setState({ readyToCopyId: item })
   }

   handleOpenInfo = () => {
      this.setState({ showInfo: true })
   }

   handleOpenDelete = (item) => {
      this.setState({ readyToDeleteId: item.ID })
      this.setState({ showDelete: true })
   }

   handleFilter = (e) => {
      this.setState({ filter: e.target.value })
   }

   setCopyLib = (item) => {
      this.setState({ copyLibData: item })
   }

   copyFileToLib = () => {
      let data = {
         Action: "copyFileToLib",
         UserID: localStorage.getItem('userID').replace(/['"]+/g, ''),
         CurrentLibID: this.state.selectedLibrary.ID,
         NextLibID: this.state.copyLibData.ID,
         FileID: this.state.readyToCopyId.ID
      }
      postActions(data).then(() => { })
   }

   deleteFile = () => {
      let data = {
         Action: "removeFileFromClassFolder",
         UserID: localStorage.getItem('userID').replace(/['"]+/g, ''),
         ClassID: this.props.history.location.state.ID,
         FileID: this.state.readyToDeleteId
      }
      postActions(data).then((response) => {
         this.getClassFilelist(this.state.selectedLibrary)
         toast.success('File deleted', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            limit: 3,
            progress: undefined,
         });
         this.handleCloseDelete()
      })
   }

   mimeType = () => {
   }

   getClassFilelist = (classObj) => {
      let userID = localStorage.getItem('userID').replace(/['"]+/g, '')
      let data = {
         Action: "getClassFolderFileList",
         UserID: userID,
         ClassID: classObj.ID
      }
      postActions(data).then((response) => {
         this.setState({ fileList: response.Files })
      })
   }

   openNewTab = (item) => {
      window.open("https://languagevr.io/" + item.Link)
   }

   componentDidMount() {
      this.getClassFilelist(this.props.history.location.state)
   }

   selectLibarary = (item) => {
      this.setState({ selectedLibrary: item })
      this.getLibraryFilelist(item)
   }
   render() {
      const { filter, fileList } = this.state;
      const lowercasedFilter = filter.toLowerCase();
      const filteredData = fileList ? fileList.filter(item => {
         return Object.keys(item).some(key =>
            item[key].toLowerCase().includes(lowercasedFilter)
         );
      }) : ''
      return (
         <Container>
            <Row className="labelBar justify-content-md-center">
               <Col className="label" xl={12}>
                  <div class="labelMain">{this.props.langs.classFiles}</div>
                  {/* <div class="newButtonMain" onClick={this.handleOpen}><PlusSquare color="#577df7" size={25}/><span class="buttonText">New</span></div>
                  <div class="infoButtonMain" onClick={this.handleOpenInfo}><InfoSquareFill color="#577df7" size={25} /><span class="buttonText">Info</span></div> */}
               </Col>
               {/* <Col xl={3}>
                  <Dropdown>
                     <Dropdown.Toggle id="dropdown-button-dark-example1" className="selectLibrary" variant="secondary">
                        Library List
                     </Dropdown.Toggle>
                     <Dropdown.Menu className="drop">
                        {this.state.library && this.state.library.map(item => 
                           <Dropdown.Item onClick={() => this.selectLibarary(item)}>{item.Name}</Dropdown.Item>
                        )}
                     </Dropdown.Menu>
                  </Dropdown>
               </Col> */}
            </Row>
            <Row className="pageRow">
               <Col xl={12}>
                  <div class="settingBox">
                     <Row className="topBarDiv">
                        <Col xl={6} className="filesLabel">{this.props.history.location.state && this.props.history.location.state.Name}</Col>
                        <Col xl={6} className="searchBarDiv">
                           <Form className="d-flex">
                              <FormControl
                                 type="search"
                                 placeholder="Search"
                                 className="searchBar"
                                 aria-label="Search"
                                 value={filter}
                                 onChange={this.handleFilter}
                              />
                              {/* <Button className="searchButton">Search</Button> */}
                           </Form>
                        </Col>
                     </Row>
                     <Table striped bordered hover>
                        <thead>
                           <tr class="customTableRowLabel">
                              <th>{this.props.langs.type}</th>
                              <th>{this.props.langs.name}</th>
                              {/* <th>Class Name</th> */}
                              {/* <th>Date</th> */}
                              <th>{this.props.langs.actions}</th>
                           </tr>
                        </thead>
                        <tbody>
                           {filteredData && filteredData.length ? filteredData.map(item =>
                              <tr className="customTableRow" key={item.ID}>
                                 <td>{item.Type}</td>
                                 <td>{item.Name}</td>
                                 {/* <td>Listening</td> */}
                                 {/* <td>{item.Date}</td> */}
                                 <td>
                                    <Dropdown>
                                       <Dropdown.Toggle id="dropdown-button-dark-example1" className="dropdownActions" variant="secondary">
                                          Actions
                                       </Dropdown.Toggle>
                                       <Dropdown.Menu variant="dark">
                                          {/* <Dropdown.Item >View</Dropdown.Item> */}
                                          {/* <Dropdown.Item>Edit</Dropdown.Item> */}
                                          {/* <Dropdown.Item >Create Assignment</Dropdown.Item>
                                       <Dropdown.Item >Add to Class Folder</Dropdown.Item>
                                       <Dropdown.Item onClick={() => this.handleOpenCopy(item)}>Copy to Shared Lib</Dropdown.Item> */}
                                          <Dropdown.Item onClick={() => this.openNewTab(item)}>{this.props.langs.downloadView}</Dropdown.Item>
                                          <Dropdown.Item onClick={() => this.handleOpenDelete(item)}>{this.props.langs.remove}</Dropdown.Item>
                                       </Dropdown.Menu>
                                    </Dropdown>
                                 </td>
                              </tr>
                           )
                              :
                              <td className="noUserFound" colSpan="4">{this.props.langs.noDataFound}</td>
                           }
                        </tbody>
                     </Table>
                  </div>
               </Col>
            </Row>
            {/* <Modal
               show={this.state.show}
               onHide={this.handleClose}
               backdrop="static"
               aria-labelledby="contained-modal-title-vcenter"
               centered
               keyboard={false}
            >
               <Modal.Header>
                  <Modal.Title><PlusSquare color="#577df7" size={25} style={{marginRight: "10px"}}/>New</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  <CreateNewModal/>
               </Modal.Body>
               <Modal.Footer>
                  <Button variant="secondary" onClick={this.handleClose}>
                     Close
                  </Button>
                  {/* <Button variant="primary">Continue</Button> 
               </Modal.Footer>
            </Modal> */}
            {/* <Modal
               show={this.state.showCopy}
               onHide={this.handleCloseCopy}
               backdrop="static"
               aria-labelledby="contained-modal-title-vcenter"
               centered
               keyboard={false}
            >
               <Modal.Header>
                  <Modal.Title><PlusSquare color="#577df7" size={25} style={{marginRight: "10px"}}/>Select library for copy</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  <CopyFile libraryList={this.state.library} libraryId={this.state.selectedLibrary} file={this.state.readyToCopyId} sendData={this.setCopyLib}/>
               </Modal.Body>
               <Modal.Footer>
                  <Button variant="secondary" onClick={this.handleCloseCopy}>
                     Close
                  </Button>
                  <Button className="copyButton" onClick={this.copyFileToLib}>Copy</Button>
               </Modal.Footer>
            </Modal> */}
            <Modal
               show={this.state.showDelete}
               onHide={this.handleCloseDelete}
               backdrop="static"
               aria-labelledby="contained-modal-title-vcenter"
               centered
               keyboard={false}
            >
               <Modal.Header>
                  <Modal.Title><InfoSquareFill color="#577df7" size={25} style={{ marginRight: "10px" }} />Delete</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  Are sure to delete this file ?
               </Modal.Body>
               <Modal.Footer>
                  <Button variant="secondary" onClick={this.handleCloseDelete}>
                     Cancel
                  </Button>
                  <Button variant="danger" onClick={this.deleteFile}>Delete</Button>
               </Modal.Footer>
            </Modal>
            {/* <Modal
               show={this.state.showInfo}
               onHide={this.handleCloseInfo}
               backdrop="static"
               aria-labelledby="contained-modal-title-vcenter"
               centered
               keyboard={false}
            >
               <Modal.Header>
                  <Modal.Title><InfoSquareFill color="#577df7" size={25} style={{marginRight: "10px"}}/>Info</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  Info text
               </Modal.Body>
               <Modal.Footer>
                  <Button variant="secondary" onClick={this.handleCloseInfo}>
                     Close
                  </Button>
               </Modal.Footer>
            </Modal> */}
         </Container>
      )
   }
}

export default Files;