import React from 'react';
import './questions.scss'
import { Container, Col, Row, Form } from 'react-bootstrap';
import { postFormHandling, postActions } from '../../helpers/request';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Test extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         data: '',
         optionId: 5,
         LibararyID: localStorage.getItem('FList'),
         QuestionId: 2,
         Title: '',
         Time: '',
         selectedImage: '',
         imageList: [],
         questionData: [
            {
               name: '',
               id: 1,
               Answer: '1',
               options: [
                  {
                     name: '',
                     id: 1
                  },
                  {
                     name: '',
                     id: 2
                  },
                  {
                     name: '',
                     id: 3
                  },
                  {
                     name: '',
                     id: 4
                  },
               ]
            },
         ]
      };
   }

   sendError = () => {
      toast.warn('Please fill in all the input fields', {
         position: "top-right",
         autoClose: 5000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         limit: 3,
         progress: undefined,
      })
   }

   componentDidMount = () => {
      this.getAudioFiles().then(() => {
         if (this.props.location.state && this.props.location.state.edit) {
            this.getData(this.props.location.state.id)
         }
      })
   }

   getData = (Qid) => {
      let data = {
         Type: "getTest",
         FileID: Qid
      }
      postFormHandling(data).then((res) => {
         let que = res.Data.Questions
         let arr = []
         let idCount = 1
         for (let x = 0; x < que.length; x++) {
            let obj = {
               name: que[x].Question,
               id: idCount,
               Answer: que[x].Answer,
               options: [
                  {
                     name: que[x].A,
                     id: 1
                  },
                  {
                     name: que[x].B,
                     id: 2
                  },
                  {
                     name: que[x].C,
                     id: 3
                  },
                  {
                     name: que[x].D,
                     id: 4
                  },
               ]
            }
            idCount++
            arr.push(obj)
         }
         let imageName = que[0].Num
         imageName = imageName.split('/').pop()
         let selected = this.state.imageList.filter(item => item.Name == imageName)
         this.setState({ selectedImage: selected[0] })
         this.setState({ Title: res.Data.Name })
         this.setState({ Time: res.Data.Time })
         this.setState({ questionData: arr })
      })
   }

   getAudioFiles = () => {
      return new Promise((resolve, reject) => {
         let data = {
            Action: "getUserLibraries",
            UserID: localStorage.getItem('userID').replace(/['"]+/g, '')
         }
         postActions(data).then((response) => {
            let fileList = []
            let data = {
               Action: "getLibraryFileList",
               UserID: localStorage.getItem('userID').replace(/['"]+/g, ''),
               LibraryID: response.Libraries[0].ID
            }
            postActions(data).then((response2) => {
               if (response2.Files) {
                  fileList = response2.Files
                  fileList = fileList.filter(item => item.Type == "Image")
                  this.setState({ imageList: fileList })
                  resolve()
               }
               resolve()
            })
            // }
         })
      })
   }

   handleSelectImage = (e, item) => {
      let value = e.target.value
      this.setState({ selectedImage: value })
   }

   createQuestionRequest = () => {
      let questionData = []
      let imageString = this.state.selectedImage && this.state.selectedImage.Link ? 'https://languagevr.io/' + this.state.selectedImage.Link : 'https://languagevr.io/' + this.state.selectedImage
      for (let x = 0; x < this.state.questionData.length; x++) {
         let queData = {
            // Num: this.state.questionData[x].id,
            Num: imageString,
            Question: this.state.questionData[x].name,
            A: this.state.questionData[x].options[0].name,
            B: this.state.questionData[x].options[1].name,
            C: this.state.questionData[x].options[2].name,
            D: this.state.questionData[x].options[3].name,
            Answer: this.state.questionData[x].Answer,
         }
         questionData.push(queData)
      }
      let data = {}
      if (this.props.location.state && this.props.location.state.edit) {
         let obj = {
            Type: "updateTest",
            ID: localStorage.getItem('userID').replace(/['"]+/g, ''),
            FileID: this.props.location.state.id,
            LibraryID: localStorage.getItem('FList'),
            Form: {
               Time: this.state.Time,
               Title: this.state.Title,
               Questions: questionData
            }
         }
         data = obj
      } else {
         let obj = {
            Type: "createTest",
            ID: localStorage.getItem('userID').replace(/['"]+/g, ''),
            LibraryID: localStorage.getItem('FList'),
            Form: {
               Time: this.state.Time,
               Title: this.state.Title,
               Questions: questionData
            }
         }
         data = obj
      }
      postFormHandling(data).then(() => {
         if (this.props.location.state && this.props.location.state.edit) {
            toast.success('Test successfuly Updated', {
               position: "top-right",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               limit: 3,
               progress: undefined,
            });
         } else {
            toast.success('Test successfuly created', {
               position: "top-right",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               limit: 3,
               progress: undefined,
            });
         }
         this.props.history.push("/files")
      })
   }
   creteNewQuestion = () => {
      let count = this.state.optionId
      let questionId = this.state.QuestionId
      let newQueData = {
         name: '',
         id: questionId,
         options: []
      }
      questionId++
      for (let x = 0; x < 4; x++) {
         const obj = { name: '', id: count }
         newQueData.options.push(obj)
         count++
         this.setState({ QuestionId: count })
      }
      this.setState({ questionData: [...this.state.questionData, newQueData], QuestionId: questionId })
   }

   deleteQue = (item) => {
      let newList = this.state.questionData.filter(que => que.id !== item.id)
      this.setState({ questionData: newList })
   }

   handleSelectedAnswer = (options, indexNum, item) => {
      let array = [...this.state.questionData]
      let index = this.state.questionData.findIndex(que => que.id === item.id)
      if (indexNum == 0) array[index].Answer = 'A'
      if (indexNum == 1) array[index].Answer = 'B'
      if (indexNum == 2) array[index].Answer = 'C'
      if (indexNum == 3) array[index].Answer = 'D'
      this.setState({ questionData: array })
   }

   handleChangeOption = (e, queItem, options) => {
      let array = [...this.state.questionData]
      let index = this.state.questionData.findIndex(que => que.id === queItem.id)
      let optio = array[index].options.find(item => item.id === options.id)
      optio.name = e.target.value
      this.setState({ questionData: array })
   }

   handleChange = (e, item) => {
      if (item) {
         let array = [...this.state.questionData]
         let index = this.state.questionData.findIndex(que => que.id === item.id)
         array[index].name = e.target.value
         this.setState({ questionData: array })
      } else {
         this.setState({ [e.target.name]: e.target.value })
      }
   }
   render() {
      const { questionData, Title, Time, selectedImage } = this.state
      const valid = Title && Time && questionData && questionData.length && questionData[0] && selectedImage
      return (
         <Container>
            <Row className="labelBar justify-content-md-center">
               <Col className="label" xl={12}>
                  <Row style={{ width: '100%' }}>
                     <Col xl={3} sm={12} className="labelMain">Test</Col>
                     <Col xl={4} sm={12} className="titleBox">
                        <span className="title">Title:</span>
                        <input type="text" name="Title" value={this.state.Title} onChange={this.handleChange} placeholder="Title" />
                     </Col>
                     <Col xl={4} sm={12} className="titleBox">
                        <span className="title">Time:</span>
                        <input min="0" type="number" name="Time" value={this.state.Time} placeholder="Time" onChange={this.handleChange} />
                     </Col>
                  </Row>
               </Col>
            </Row>
            <Row className="pageRow">
               <Col xl={12}>
                  <div class="settingBox">
                     <Col xl={12} className="questionLabel">Question</Col>
                     {this.state.questionData && this.state.questionData.map((item, index) =>
                        <Col xl={12} className="questionBox">
                           <Row className="NoMargin">
                              <Col xl={9} className="questionText">
                                 <div class="number">{index + 1}</div>
                                 <input type="text" name="name" value={item.name} onChange={(e) => this.handleChange(e, item)} placeholder="Question" maxlength="500" />
                              </Col>
                              <Col xl={2} className="selectAudio">
                                 <Form.Control as="select" value={this.state.selectedImage} className="selectBox" size="sm" onChange={(e) => this.handleSelectImage(e)}>
                                    {/*Need to be FUNCTION LATER */}
                                    {selectedImage && selectedImage.Name ?
                                       <option> {this.state.selectedImage.Name} </option> : <option> Select Image </option>
                                    }
                                    {this.state.imageList && this.state.imageList.map(el =>
                                       <option key={el.ID} value={el.Link}>{el.Name}</option>
                                    )}
                                 </Form.Control>
                              </Col>
                              <Col xl={1} className="selectAudio">
                                 <div class="deleteButton" onClick={() => this.deleteQue(item)}>Delete</div>
                              </Col>
                           </Row>
                           <Row>
                              {item.options && item.options.map((options, indexNum) =>
                                 <Col xl={3} className="optionBox">
                                    <div class="optionInside noP">
                                       <input type="radio" name={item.id} key={item.id} checked={(indexNum === 0 && item.Answer === 'A') || (indexNum === 1 && item.Answer === 'B') || (indexNum === 2 && item.Answer === 'C') || (indexNum === 3 && item.Answer === 'D')} onChange={() => this.handleSelectedAnswer(options, indexNum, item)} style={{ marginRight: '5px', marginTop: '7px' }} />
                                       <input type="text" name={options.name} value={options.name} key={options.id} onChange={(e) => this.handleChangeOption(e, item, options)} placeholder='Option' />
                                    </div>
                                 </Col>
                              )}
                           </Row>
                        </Col>
                     )}
                     <Col xl={12} className="addQueButton" onClick={this.creteNewQuestion}>Add</Col>
                     <Col xl={12} className="addQueButton" onClick={valid ? this.createQuestionRequest : this.sendError}>Save</Col>
                  </div>
               </Col>
            </Row>
         </Container>
      )
   }
}

export default Test;