import React from 'react';
import './files.scss'
import { Navbar, Container, Nav, NavDropdown, Button, Dropdown, Col, Row, Table, Form, FormControl } from 'react-bootstrap';
import { Check, Folder, Plus, PlusSquare, InfoSquareFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class CreateNewModal extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         data: '',
         files: null,
         apiType: '',
         fileType: '',
         userType: ''
      };
   }
   setSelectedFile = (file) => {
      if (file) {
         const fSize = parseFloat(file.size / (1024 * 1024)).toFixed(2)
         const index = file.name.split(".")
         const fileType = index[index.length - 1]
         if (fSize < 25) {
            var type = file.type.substr(0, file.type.indexOf('/'))
            if (type === 'image') {
               this.setState({ apiType: 'imageUpload.php' })
            } else if (type === 'video') {
               this.setState({ apiType: 'videoUpload.php' })
            } else if (type === 'application') {
               this.setState({ apiType: 'slidesUpload.php' })
            } else if (type === 'audio') {
               if (fSize < 15) {
                  this.setState({ apiType: 'audioUpload.php' })
               } else {
                  toast.error('File size must be less than 15MB, your file size is ' + fSize + 'MB', {
                     position: "top-right",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     limit: 3,
                     progress: undefined,
                  });
                  return ''
               }
            } else if (fileType === 'glb' || fileType === 'gltf') {
               this.setState({ apiType: 'modelUpload.php' })
            } else {
               toast.error('Unsupported file type', {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  limit: 3,
                  progress: undefined,
               });
            }
            this.setState({ fileType: file.type })
            this.setState({ files: file })
         } else {
            toast.error('File size must be less than 25MB, your file size is ' + fSize + 'MB', {
               position: "top-right",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               limit: 3,
               progress: undefined,
            });
         }
      }
   }
   handleSubmit = () => {
      if (this.state.files) {
         const promise = new Promise((resolve, reject) => {
            const data = new FormData();
            data.append("libraryID", localStorage.getItem('FList'));
            data.append("file", this.state.files);
            fetch("https://languagevr.io/test/" + this.state.apiType, {
               method: "POST",
               body: data
            }).then((response) => {
               response.json().then(data => ({
                  data: data,
                  status: response.status
               })).then((res) => {
                  if (res.data.Result === 'ERROR') {
                     reject()
                  } else {
                     resolve()
                  }
               }).catch((err) => {
                  reject()
               })
            }).catch((err) => {
            });
         })
         toast.promise(
            promise,
            {
               pending: 'File Uploading...',
               success: 'File Uploaded',
               error: 'File Cant upload'
            }
         ).then(() => {
            this.props.isFilesUploaded(true)
         })
      }
   }
   render() {
      const { files } = this.state
      return (
         <Container>
            <Row className="createNewModal">
               {localStorage.getItem('type').replace(/['"]+/g, '') !== '3' ?
                  <Col xl={5}>
                     <Link to="/createNewTest">
                        <Col className="leftCols" xl={12}>
                           <span class="leftColsLinkText">{this.props.langs.test}</span>
                        </Col>
                     </Link>
                     <Link to="/trueFalse">
                        <Col className="leftCols" xl={12}>
                           <span class="leftColsLinkText">{this.props.langs.trueFalse}</span>
                        </Col>
                     </Link>
                     <Link to="/wordMatch">
                        <Col className="leftCols" xl={12}>
                           <span class="leftColsLinkText">{this.props.langs.wordMatch}</span>
                        </Col>
                     </Link>
                     <Link to="/dramaA">
                        <Col className="leftCols" xl={12}>
                           <span class="leftColsLinkText">Drama A</span>
                        </Col>
                     </Link>
                     <Link to="/dramaB">
                        <Col className="leftCols" xl={12}>
                           <span class="leftColsLinkText">Drama B</span>
                        </Col>
                     </Link>
                     <Link to="/dramaC">
                        <Col className="leftCols" xl={12}>
                           <span class="leftColsLinkText">Drama C</span>
                        </Col>
                     </Link>
                     <Link to="/dramaD">
                        <Col className="leftCols" xl={12}>
                           <span class="leftColsLinkText">Drama D</span>
                        </Col>
                     </Link>
                     <Link to="/conversationclub">
                        <Col className="leftCols" xl={12}>
                           <span class="leftColsLinkText">{this.props.langs.conversationClub}</span>
                        </Col>
                     </Link>
                     <Link to="/fillintheblanks">
                        <Col className="leftCols" xl={12}>
                           <span class="leftColsLinkText">{this.props.langs.fillintheblanks}</span>
                        </Col>
                     </Link>
                     <Link to="/reading">
                        <Col className="leftCols" xl={12}>
                           <span class="leftColsLinkText">{this.props.langs.reading}</span>
                        </Col>
                     </Link>
                  </Col>
                  : ''}
               {localStorage.getItem('type').replace(/['"]+/g, '') !== '3' ? <Col xl={2} style={{ margin: "auto", textAlign: "center" }}>OR</Col> : ''}
               <Col className="rightDiv" style={{ margin: 'auto' }} xl={localStorage.getItem('type').replace(/['"]+/g, '') === '3' ? 12 : 5}>
                  <div class="uploadDiv">
                     <input type="file" class="fileInput" accept="application, image, video, audio" onChange={(e) => this.setSelectedFile(e.target.files[0])} />
                     <span class="uploadLogo">{files ? '' : <PlusSquare color="#577df7" size={35} />}</span>
                     <span class="uploadText">
                        {files ? files.name : "Upload Video, Audio, PDF & 3D Model"}
                     </span>
                  </div>
                  <div class="uploadFile" onClick={this.handleSubmit}>{this.props.langs.uploadFile}</div>
               </Col>
            </Row>
         </Container>
      )
   }
}

export default CreateNewModal;