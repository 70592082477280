import React from 'react';
import './questions.scss'
import { Container, Col, Row } from 'react-bootstrap';
import { postFormHandling } from '../../helpers/request'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class DramaA extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         data: '',
         numCount: 2,
         boxCount: 0,
         Title: '',
         Words: [
            {
               Num:1,
               Word:""
            }
         ]
      };
    }
    CrateNewDramaARequest = () => {
       let data = {}
      if (this.props.location.state && this.props.location.state.edit) {
         let convertedData = []
         for (let x = 0; x < this.state.Words.length; x++) {
            let cnvrt = {
               Word1: this.state.Words[x].Word,
               Word2: '',
               Num: this.state.Words[x].Num
            }
            convertedData.push(cnvrt)
         }
         let obj = {
            Type:"updateWordMatch",
            ID: localStorage.getItem('userID').replace(/['"]+/g, ''),
            FileID: this.props.location.state.id,
            Form: {
               Title: this.state.Title,
               Words: convertedData
            }
         }
         data = obj
      } else {
         let obj = {
            Type:"createDramaA",
            ID: localStorage.getItem('userID').replace(/['"]+/g, ''),
            LibraryID: localStorage.getItem('FList'),
            Form: {
               Title: this.state.Title,
               Words: this.state.Words
            }
         }
         data = obj
      }
      postFormHandling(data).then(() => {
         if (this.props.location.state && this.props.location.state.edit) {
            toast.success('DramaA successfuly Updated', {
               position: "top-right",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               limit: 3,
               progress: undefined,
            });
         } else {  
            toast.success('DramaA successfuly created', {
               position: "top-right",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               limit: 3,
               progress: undefined,
            });
         }
         this.props.history.push("/files")
      })
   }

   deleteQue = () => {
      let arr = this.state.Words
      arr.pop()
      this.setState({Words: arr})
   }
   
   handleTitle = (e) => {
      this.setState({Title: e.target.value})
   }

   handleChange = (e, element) => {
      let data = [...this.state.Words]
      let index = data.findIndex(item => item.Num === element.Num)
      data[index].Word = e.target.value
      this.setState({Words: data})
   }

   changeCount = (e) => {
      this.setState({boxCount: e.target.value})
   }

   componentDidMount () {
      if (this.props.location.state && this.props.location.state.edit) {
         this.getData(this.props.location.state.id)
      }
   }

   getData = (Qid) => {
      let data = {
         Type: "getWordMatch",
         FileID: Qid
      }
      postFormHandling(data).then((res) => {
         console.log(res)
         let que = res.Data.Words
         let arr = []
         for (let x = 0; x < que.length; x++) {
            let obj = {
               Word: que[x].Word1,
               Num: que[x].Num
            }
            arr.push(obj)
         }
         this.setState({Title: res.Data.Name})
         this.setState({Words: arr})
      })
   }

   CreteNew = () =>{
      let count = this.state.boxCount
      let num = this.state.numCount
      let data = [...this.state.Words]
      for (let x = 0; x < count; x++) {
         let newData = {
            Num: num,
            Word: ""
         }
         data.push(newData)
         num++
      }
      this.setState({numCount: num})
      this.setState({Words: data})
   }

   sendError = () => {
      toast.warn('Please Add Words', {
         position: "top-right",
         autoClose: 5000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         limit: 3,
         progress: undefined,
      })
   }
   render () {
      const { Words, Title } = this.state
      const valid = Words && Words.length && Words[0] && Title && Title.length
      return (
         <Container>
            <Row className="labelBar justify-content-md-center">
               <Col className="label" xl={12}>
                  <Row style={{width: '100%'}}>
                     <Col xl={3} className="labelMain">Drama A</Col>
                     <Col xl={4} className="titleBox">
                        <span className="title">Title:</span>
                        <input type="text" name="name" onChange={this.handleTitle} value={this.state.Title} placeholder="Question Name"/>
                     </Col>
                     {/* <Col xl={4} className="titleBox">
                        <span className="title">Time:</span>
                        <input type="text" name="name" placeholder="Question Name"/>
                     </Col> */}
                  </Row>
               </Col>
            </Row>
            <Row className="pageRow">
               <Col xl={12}>
                  <div class="settingBox">
                     <Row className="questionBorder">
                        <Col xl={8} className="questionLabel  drama">Question</Col>
                        <Col xl={3} sm={12}>
                           <Row>
                              <Col xl={6} sm={2} xs={2} className="addQueButtonDramaInput"><input type="number" min="0" value={this.state.boxCount} onChange={this.changeCount}/></Col>
                              <Col xl={6} sm={8} xs={10} className="addQueButtonDrama" onClick={this.CreteNew}>Add</Col>
                           </Row>
                        </Col>
                        <Col xl={1} className="selectAudio">
                           <div class="deleteButton" onClick={() => this.deleteQue()}>Delete</div>
                        </Col>
                     </Row>
                     <Col xl={12} className="questionBox">
                        <Row>
                           {this.state.Words && this.state.Words.map((item, index) => 
                              <Col xl={3} className="dramaA" key={item.Num}>
                                 <Col xl={6} className="inputBox">
                                    <div class="number">{index + 1 + '.'}</div>
                                    <div class="input">
                                       <input type="text" placeholder="Example" value={item.Word} onChange={(e) => this.handleChange(e, item)}/>
                                    </div>
                                 </Col>
                              </Col>
                           )}
                        </Row>
                        <Row>
                           <Col xl={3} className="optionBox"></Col>
                        </Row>
                     </Col>
                     <Col xl={12} className="addQueButton" onClick={valid ? this.CrateNewDramaARequest : this.sendError}>Save</Col>
                  </div>
               </Col>
            </Row>
         </Container>
      )
   }
}

export default DramaA;