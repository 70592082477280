import React from 'react';
import './questions.scss'
import { Container, Col, Row } from 'react-bootstrap';
import { postFormHandling } from '../../helpers/request'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class DramaD extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         data: '',
         optionId: 2,
         QuestionId: 2,
         boxCount: 0,
         testTitle: '',
         Idioms:[
             {
                 Num:1,
                 Idiom:"",
                 Hint:""
             }
         ]
      };
    }

    sendError = () => {
      toast.warn('Please Add Idioms', {
         position: "top-right",
         autoClose: 5000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         limit: 3,
         progress: undefined,
      })
   }

   changeCount = (e) => {
      this.setState({boxCount: e.target.value})
   }

   componentDidMount () {
      if (this.props.location.state && this.props.location.state.edit) {
         this.getData(this.props.location.state.id)
      }
    }

    getData = (Qid) => {
      let data = {
         Type: "getWordMatch",
         FileID: Qid
      }
      postFormHandling(data).then((res) => {
         console.log(res)
         let que = res.Data.Words
         let arr = []
         for (let x = 0; x < que.length; x++) {
            let obj = {
               Idiom: que[x].Word1,
               Hint: que[x].Word2,
               Num: que[x].Num
            }
            arr.push(obj)
         }
         this.setState({testTitle: res.Data.Name})
         this.setState({Idioms: arr})
      })
   }

    createDramaDRequest = () => {
      let data = {}
      if (this.props.location.state && this.props.location.state.edit) {
         let newObj = []
         for (let x = 0; x < this.state.Idioms.length; x++) {
            let a = {
               Num:this.state.Idioms[x].Num,
               Word1:this.state.Idioms[x].Idiom,
               Word2:this.state.Idioms[x].Hint
            }
            newObj.push(a)
         }
         let obj = {
            Type:"updateWordMatch",
            FileID: this.props.location.state.id,
            ID: localStorage.getItem('userID').replace(/['"]+/g, ''),
            Form: {
               Title: this.state.testTitle,
               Words: newObj
            }
         }
         data = obj
      } else {
         let obj = {
            Type:"createDramaD",
            ID: localStorage.getItem('userID').replace(/['"]+/g, ''),
            LibraryID: localStorage.getItem('FList'),
            Form: {
               Title: this.state.testTitle,
               Idioms: this.state.Idioms 
            }
         }
         data = obj
      }
       postFormHandling(data).then(() => {
         if (this.props.location.state && this.props.location.state.edit) {
            toast.success('DramaD successfuly Updated', {
               position: "top-right",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               limit: 3,
               progress: undefined,
            });
         } else {  
            toast.success('DramaD successfuly created', {
               position: "top-right",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               limit: 3,
               progress: undefined,
            });
         }
         this.props.history.push("/files")
       })
    }
    creteNewQuestion = () => {
      let boxCount = this.state.boxCount
      let arr = this.state.Idioms
      let count = this.state.optionId
      for (let x = 0; x < boxCount; x++) {
         let newQueData = {
            Num: count,
            Idiom:"",
            Hint:""
         }
         count++
         arr.push(newQueData)
      }
      this.setState({optionId: count})
      this.setState({Idioms: arr})
    }

    deleteQue = (item) => {
       let newList = this.state.Idioms.filter(que => que.Num !== item.Num)
       this.setState({Idioms:newList})
    }
    
    handleChange = (e, item) => {
      if (item) {
         let array = [...this.state.Idioms]
         let index = this.state.Idioms.findIndex(que => que.Num === item.Num)
         if (e.target.name == 'Idiom') {
            array[index].Idiom = e.target.value
         } else {
            array[index].Hint = e.target.value
         }
         this.setState({Idiom: array})
      } else {
         this.setState({[e.target.name]: e.target.value})
      }
    }
   render () {
      const { Idioms } = this.state
      const valid = Idioms && Idioms.length && Idioms[0]
      return (
         <Container>
            <Row className="labelBar justify-content-md-center">
               <Col className="label" xl={12}>
                  <Row style={{width: '100%'}}>
                     <Col xl={3} className="labelMain">Drama D</Col>
                     <Col xl={4} className="titleBox">
                        <span className="title">Title:</span>
                        <input type="text" name="testTitle" onChange={this.handleChange} value={this.state.testTitle} placeholder="Question Name" maxlength="500"/>
                     </Col>
                     {/* <Col xl={4} className="titleBox">
                        <span className="title">Time:</span>
                        <input type="text" name="name" placeholder="Question Name"/>
                     </Col> */}
                  </Row>
               </Col>
            </Row>
            <Row className="pageRow">
               <Col xl={12}>
                  <div class="settingBox">
                     <Row className="questionBorder">
                        <Col xl={3} xl={12}>
                           <Row style={{justifyContent:"flex-end"}}>
                              <Col xl={2} sm={2} xs={2} className="addQueButtonDramaInput"><input type="number" value={this.state.boxCount} onChange={this.changeCount}/></Col>
                              <Col xl={3} sm={10} xs={10} className="addQueButtonDrama" onClick={this.creteNewQuestion}>Add</Col>
                           </Row>
                        </Col>
                        <Col xl={5} className="questionLabel drama">Questions</Col>
                        <Col xl={4} className="questionLabel drama">Hints</Col>
                     </Row>
                     {this.state.Idioms && this.state.Idioms.map((item, index) =>
                        <Col xl={12} className="questionBox" key={item.Num}>
                           <Row className="justify-content-md-center">
                              <Col xl={12} className="dramaDBox">
                                 <Row>
                                    <Col xl={5} className="inputBox">
                                       <div class="number">{index + 1}</div>
                                       <div class="pad-5">
                                          <input type="text" name="Idiom"  placeholder="Idiom" onChange={(e) => this.handleChange(e, item)} value={item.Idiom}/>
                                       </div>
                                    </Col>
                                    <Col xl={5} className="inputBox">
                                       <div class="pad-5">
                                          <input type="text" name="Hint" placeholder="Hint" onChange={(e) => this.handleChange(e, item)} value={item.Hint}/>
                                       </div>
                                    </Col>
                                    <Col xl={2} >
                                       <div class="deleteButton" onClick={() => this.deleteQue(item)}>Delete</div>
                                    </Col>
                                 </Row>
                              </Col>
                           </Row>
                           <Row>
                              <Col xl={3} className="optionBox"></Col>
                           </Row>
                        </Col>
                     )}
                     {/* <Col xl={12} className="addQueButton"  onClick={valid ? this.creteNewQuestion : this.sendError}>Add</Col> */}
                     <Col xl={12} className="addQueButton" onClick={this.createDramaDRequest}>Save</Col>
                  </div>
               </Col>
            </Row>
         </Container>
      )
   }
}

export default DramaD;